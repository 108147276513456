import axios from "./AxiosInterceptors";
import storageService from "./StorageService";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const LS_KEY_ACCESS_TOKEN = "access_token";

export class NodesService {

    getAll() {
        const url = `${API_BASE_URL}/api/nodes`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data.nodes);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    create(nodes) {
        const url = `${API_BASE_URL}/api/nodes`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        const newNode = nodes[nodes.length - 1]

        const nodeToSend = {
            nodeId: nodes.length,
            name: newNode.name,
            type: newNode.type,
            description: newNode.description,
            value: 0,
            x: 0,
            y: 0,
            linkTarget: ''
        }

        return new Promise((resolve, reject) => {
            axios
                .post(url, nodeToSend, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response.data.error);
                    }
                });
        });
    };

    formatNode (node) {
        return ({
            children: node.children,
            id: node.id,
            name: node.name,
            type: node.type,
            description: node.description,
            value: node.value,
            x: node.x,
            y: node.y,
            linkTarget: node.linkTarget,
        })
    }

    addChild(node) {
        const url = `${API_BASE_URL}/api/nodes/addChild`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        const nodeToSend = this.formatNode(node)

        return new Promise((resolve, reject) => {
            axios
                .post(url, nodeToSend, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response.data.error);
                    }
                });
        });
    };

    update(node) {
        const url = `${API_BASE_URL}/api/nodes/${node.id}`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        const nodeToSend = this.formatNode(node)

        return new Promise((resolve, reject) => {
            axios
                .put(url, nodeToSend, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    updateAll(nodes) {
        const url = `${API_BASE_URL}/api/nodes`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }
        const nodesToSend  = []

        nodes.forEach((element) => {
            nodesToSend.push(this.formatNode(element))
        })
        
        return new Promise((resolve, reject) => {
            axios
                .put(url, nodesToSend, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    delete(id) {
        const url = `${API_BASE_URL}/api/nodes/${id}`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        return new Promise((resolve, reject) => {
            axios
                .delete(url, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    getTypes() {
        const url = `${API_BASE_URL}/api/types/nodes`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data.types);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    }
};


const nodesService = new NodesService();
export default nodesService;