export const SET_USER_NAVIGATION = "SET_USER_NAVIGATION";
export const SET_ACTIVE_NAVIGATION_ITEM = "SET_ACTIVE_NAVIGATION_ITEM";
export const SET_ACTIVE_NAVIGATION_SUB_ITEM = "SET_ACTIVE_NAVIGATION_SUB_ITEM";

const getfilteredNavigations = (navList = [], role) => {
  return navList.reduce((array, nav) => {
    if (nav.auth) {
      if (nav.auth.includes(role)) {
        array.push(nav);
      }
    } else {
      if (nav.children) {
        nav.children = getfilteredNavigations(nav.children, role);
        array.push(nav);
      } else {
        array.push(nav);
      }
    }
    return array;
  }, []);
};

export function getNavigationByUser() {
  return (dispatch, getState) => {
    let {user, navigations: navigationsState} = getState();
    const navigations = navigationsState.navigationItems || [];
    let filteredNavigations = getfilteredNavigations(navigations, user.role);

    dispatch({
      type: SET_USER_NAVIGATION,
      payload: [...filteredNavigations],
    });
  };
}

export function setActiveNavigationItem(selectedItem) {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_NAVIGATION_ITEM,
      payload: selectedItem,
    });
  };
}

export function setActiveNavigationSubItem(selectedItem) {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_NAVIGATION_SUB_ITEM,
      payload: selectedItem,
    });
  };
}
