import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';

import { D3ChartComponent } from 'views/charts/d3';
import { OrganizationChartComponent } from 'views/charts/organization';
import { FaqComponent } from 'views/charts/faq';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        // p is the padding of the box
        <Box p={1}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class Homepage extends Component {
  render() {
    let { activeTab, prefData } = this.props;

    return (
      <Fragment>
        <TabPanel value={activeTab} index={0}>
          {prefData && <D3ChartComponent prefData={prefData} />}
        </TabPanel>
        {/*<TabPanel value={activeTab} index={1}>
          <OrganizationChartComponent />
        </TabPanel>*/}
        <TabPanel value={activeTab} index={1}>
          <FaqComponent />
        </TabPanel>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  activeTab: state.navigations.activeSubItem || 0,
});

export default withStyles(
  {},
  { withTheme: true },
)(withRouter(connect(mapStateToProps)(Homepage)));
