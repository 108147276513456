import axios from './AxiosInterceptors';
import storageService from './StorageService';

const API_BASE_URL = process.env.REACT_APP_API_URL;
const LS_KEY_ACCESS_TOKEN = 'access_token';

export class PrefService {
  getAll() {
    const url = `${API_BASE_URL}/api/preferences/fetch`;
    const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);

    const headers = {
      Authorization: `jwt ${currentToken}`,
    };

    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data.pref);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          }
        });
    });
  }

  create(pref) {
    const url = `${API_BASE_URL}/api/preferences/create`;
    const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);

    const headers = {
      Authorization: `jwt ${currentToken}`,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(url, pref, {
          headers: headers,
        })
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.error);
          }
        });
    });
  }

  update(pref) {
    const url = `${API_BASE_URL}/api/preferences/update`;
    const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);

    const headers = {
      Authorization: `jwt ${currentToken}`,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(url, pref, {
          headers: headers,
        })
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.error);
          }
        });
    });
  }
}

const prefService = new PrefService();
export default prefService;
