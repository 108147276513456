import React from "react";
import {Redirect} from "react-router-dom";
import sessionRoutes from "./views/sessions/SessionRoutes";
import d3ChartRoutes from "./views/charts/d3/D3ChartRoutes";
import homepageRoutes from "./views/homepage/HomepageRoutes";
import tableContainerRoutes from "./views/tables/TableRoutes";
import userRoutes from "./views/users/UserRoutes";
import preferenceRoutes from "./views/preferences/PreferenceRoutes";
import {authRoles} from "auth/authRoles";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/homepage" />,
    auth: authRoles.public,
  },
];

const routes = [
  ...sessionRoutes,
  ...redirectRoute,
  ...homepageRoutes,
  ...d3ChartRoutes,
  ...tableContainerRoutes,
  ...userRoutes,
  ...preferenceRoutes
];

export default routes;
