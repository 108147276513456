import React from "react";
import MaterialTable from "material-table";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  useStyles,
  getEditableComponent,
  getOptions,
  formatRoleData,
  getPeopleOptions,
} from "../../helpers/DataTable";
import {getOrderedPeopleData, getPeopleColumns} from "helpers/PeopleDataTable";
import {tableIcons} from "../../components/ReusableComponents/DatatableIconsComponent";
import {RolesTableComponent, TeamsTableComponent} from "../../components";
import { useSelector } from "react-redux";

export default function PeopleDatatableComponent(props) {
  const {
    nodes,
    data: people,
    isLoading,
    handlePeopleChange,
    handleRoleChange,
    handleTeamChange,
    handlePeopleRefresh,
  } = props;
  const classes = useStyles();
  let data = people;
  const columns = getPeopleColumns();
  const userRole = useSelector(state => state.user.role);

  const handleTeamsChange = (updatedTeams, rowData, operation) => {
    
    // const people = [...data];
    // rowData.teams = formatRoleData(updatedTeams);
    // const index = rowData.tableData.id;
    // people[index] = rowData;
    // handlePeopleChange([...people], people[index].id, 'edit');
  };

  return (
    <main className={classes.content}>
      <Container className={classes.container}>
        {/* JSON viewer conatainer*/}
        <Grid container className={classes.viewerSettings}>
          <Grid item xs={12} md={12} lg={12}>
            <div style={{maxWidth: "100%"}}>
              <MaterialTable
                isLoading={isLoading}
                icons={tableIcons}
                columns={columns}
                data={data}
                title={
                  <div className={classes.iconContainer}>
                    <SettingsIcon />
                    <h2 className={classes.headerText}>People </h2>
                  </div>
                }
                options={getPeopleOptions}
                editable={getEditableComponent(data, handlePeopleChange, userRole)}
                actions={[
                  {
                    icon: "refresh",
                    tooltip: "Refresh Data",
                    isFreeAction: true,
                    onClick: () => handlePeopleRefresh(),
                  },
                ]}
                detailPanel={(rowData) => {
                  return (
                    <div>
                      {/** Roles */}
                      <RolesTableComponent
                        roles={formatRoleData(
                          rowData.roles && rowData.roles.length
                            ? rowData.roles
                            : []
                        )}
                        nodes={nodes}
                        rowData={rowData}
                        handleRolesChange={handleRoleChange}
                      />

                      {/** Teams */}
                      <TeamsTableComponent
                        teams={formatRoleData(
                          rowData.teams && rowData.teams.length
                            ? rowData.teams
                            : []
                        )}
                        nodes={nodes}
                        rowData={rowData}
                        handleTeamsChange={handleTeamChange}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}
