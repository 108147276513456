import axios from "./AxiosInterceptors";
import storageService from "./StorageService";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const LS_KEY_ACCESS_TOKEN = "access_token";

export class LinksService {

    getAll() {
        const url = `${API_BASE_URL}/api/links`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data.links);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    create(links) {
        const url = `${API_BASE_URL}/api/links`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        const newLink = links[links.length - 1]

        const linkToSend = {
            source: newLink.source,
            target: newLink.target,
            type: newLink.type,
            x: 0,
			y: 0,
			description: newLink.description
        }

        return new Promise((resolve, reject) => {
            axios
                .post(url, linkToSend, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response.data.error);
                    }
                });
        });
    };

    formatLink (link) {
        return ({
            id: link.id,
            source: link.source,
            target: link.target,
            type: link.type,
            x: link.x,
			y: link.y,
			description: link.description,
        })
    }

    update(link) {
        const url = `${API_BASE_URL}/api/links/${link.id}`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        const linkToSend = this.formatLink(link)

        return new Promise((resolve, reject) => {
            axios
                .put(url, linkToSend, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    updateAll(links) {
        const url = `${API_BASE_URL}/api/links`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }
		const linksToSend  = []
		
        links.forEach((element) => {
            linksToSend.push(this.formatLink(element))
        })
        
        return new Promise((resolve, reject) => {
            axios
                .put(url, linksToSend, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    delete(id) {
        const url = `${API_BASE_URL}/api/links/${id}`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        return new Promise((resolve, reject) => {
            axios
                .delete(url, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    getTypes() {
        const url = `${API_BASE_URL}/api/types/links`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data.types);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };
};


const linksService = new LinksService();
export default linksService;