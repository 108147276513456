import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Card, Button} from "@material-ui/core";
import moment from "moment";
import PropTypes from "prop-types";
import {classList} from "utils";
import {logoutUser} from "../../redux/actions/UserActions";

const UserProfilePage = ({user, backgroundClass, logoutUser}) => {
  const {
    id,
    email,
    name,
    role,
    phoneNumber,
    emailVerified,
    lastLoginAt,
    createdAt
  } = user;

  var createdDateTime = moment(createdAt, "x").format("lll");
  var lastLoginDateTime = moment(lastLoginAt, "x").format("lll");

  const handleRole = (roleId) => {
    switch (roleId) {
      case 1:
          return 'Administrator'
        break;
      case 2:
          return 'Editor'
        break;
      case 3:
          return 'Viewer'
        break;
      default:
        break;
    }
  }

  return (
    <div className="mx-auto w-11/12 md:w-2/4 py-8 px-4 md:px-8">
      <div className="flex border flex-col items-center md:flex-row md:items-start border-blue-400 px-3 py-4">
        <div className="md:pl-4">
          <h4 className="text-2xl font-semibold">
            User Profile / <span className="italic text-secondary">{id}</span>
          </h4>
        </div>
      </div>
      <Card elevation={6} className="px-6 py-5 h-full">
        <h5>UID : </h5>
        <div
          className={classList({
            "card-title": true,
            "mb-5": true,
          })}>
          {id}
        </div>
        <h5>Role : </h5>
        <div
          className={classList({
            "card-title": true,
            "mb-5": true,
          })}>
          {handleRole(role)}
        </div>
        <h5>Display Name : </h5>
        <div
          className={classList({
            "card-title": true,
            "mb-5": true,
          })}>
          {name}
        </div>
        <h5>Email : </h5>
        <div
          className={classList({
            "card-title": true,
            "mb-5": true,
            "text-lowercase": true,
          })}>
          {email}
        </div>
        <h5>Email Verified : </h5>
        <div
          className={classList({
            "card-title": true,
            "mb-5": true,
          })}>
          {emailVerified || "False"}
        </div>
        {/*<h5>Phone Number : </h5>
        <div
          className={classList({
            "card-title": true,
            "mb-5": true,
          })}>
          {phoneNumber || "N/A"}
        </div>
        <h5>Created At : </h5>
        <div
          className={classList({
            "card-title": true,
            "mb-5": true,
          })}>
          {createdDateTime || "N/A"}
        </div>
        <h5>Last Login At : </h5>
        <div
          className={classList({
            "card-title": true,
            "mb-5": true,
          })}>
          {lastLoginDateTime || "N/A"}
        </div>*/}
        <Button
          className=" py-3 bg-primary mt-4 text-white"
          onClick={logoutUser}>
          Sign out
        </Button>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  logoutUser: PropTypes.func.isRequired,
});
export default withRouter(
  connect(mapStateToProps, {logoutUser})(UserProfilePage)
);
