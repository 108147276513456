import React from 'react';
import _ from 'lodash';
import {
  getConnectionsLookupNoChild,
  getNodeIdLookup,
  getConnectionsLookup,
  getNodeIdLookupV2,
} from '../helpers/NodeDataTable';
import { FieldTypeEnum } from '../helpers/Enums';
import { tableIcons } from './ReusableComponents/DatatableIconsComponent';
import { ChildTableComponent } from '.';
import {} from '../helpers/NodeDataTable';

export default function ConnectionsTableComponent(props) {
  const {
    // allNodes,
    data,
    rowData,
    handleConnectionsChange,
    // isLoading,
  } = props;

  const nodeIdLookups = getNodeIdLookupV2(data);

  // Construct connections object array
  const constructConnectionsArray = (rowData) => {
    const connections = rowData.connections || [];
    const connectionsDataArr = connections
      .map((item) => {
        // const filtered = _.find(allNodes, (nItem) => nItem.id === item);
        // if (filtered && filtered.id) {
        //   return {nodeId: rowData.id, connectionName: item};
        // } else {
        //   return null;
        // }
        return {
          id: item.id,
          nodeId: rowData.id,
          name: rowData.name,
          connectionName:
            parseInt(item.nodeConnection) ||
            parseInt(item.connectionName),
        };
      })
      .filter((ele) => ele != null && ele.connectionName !== null);
    return connectionsDataArr;
  };

  const connectionColumns = [
    {
      title: 'Node',
      field: 'nodeId',
      // hidden: true,
      editable: 'never',
      sortable: true,
      initialEditValue: rowData.id,
      validate: (rowData) =>
        rowData.nodeId === '' ? 'Node ID cannot be empty' : '',
      lookup: { [rowData.id]: rowData.name },
    },
    {
      title: 'Connection Name',
      field: 'connectionName',
      validate: (rowData) =>
        !rowData.connectionName
          ? 'Connection Name cannot be empty'
          : '',
      sortable: true,
      editPlaceholder: 'Select a value',
      lookup: nodeIdLookups,
    },
  ];

  const handleChange = (connections, nodeId, operation) => {
    let connectionsToSend;
    if (operation === 'edit') {
      connectionsToSend = connections;
    } else {
      connectionsToSend = connections.children;
    }
    handleConnectionsChange(connectionsToSend, nodeId, operation);
  };

  return (
    <ChildTableComponent
      // isLoading={isLoading}
      columns={connectionColumns}
      data={constructConnectionsArray(rowData)}
      tableHeader={`Connections for ${rowData.name}`}
      tableIcons={tableIcons}
      parentRecord={rowData}
      fieldType={FieldTypeEnum.CONNECTIONS}
      handleChange={handleChange}
    />
  );
}
