import React, {useState, useEffect, useRef} from "react";
import MaterialTable from "material-table";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import Container from "@material-ui/core/Container";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import SettingsIcon from "@material-ui/icons/Settings";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {ColorCodes} from "../helpers/Enums";
import {getEditableComponent, getEditableComponentChilds} from "../helpers/DataTable";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "24px",
    marginBottom: "24px",
  },
  title: {
    fontWeight: "bold",
  },
  headers: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    verticalAlign: "middle",
  },
  headerText: {
    marginLeft: "10px",
    fontWeight: "bold",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  loader: {
    position: "fixed",
    zIndex: 999,
    height: "2em",
    width: "2em",
    overflow: "show",
    margin: "auto",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  table: {
    width: "100%",
  },
}));

export default function ChildTableComponent(props) {
  const {
    data,
    columns,
    parentRecord,
    grandParentRecord,
    greatGrandParentRecord,
    fieldType,
    tableIcons,
    tableHeader,
    detailPanel,
    handleChange,
    isLoading
  } = props;

  const classes = useStyles();
  const userRole = useSelector(state => state.user.role);

  const handleChangeValue = (childs, id, operation) => {
    let data
    let idToSend
    
    if(operation === 'edit') {
      data = childs
      idToSend = id
    } else {
      parentRecord.children = childs
      data = parentRecord

      if(!id){
        idToSend = parentRecord.id
      } else {
        idToSend = id
      }
    }

    handleChange(data, idToSend, operation);
  };
  
  const tableRef = useRef()
  const openedPanels = useRef(new Set())
  const oldFunction = useRef()

  useEffect(() => {
    if (!oldFunction.current) {
        oldFunction.current = tableRef.current?.onToggleDetailPanel
    }

    if (oldFunction.current === tableRef.current?.onToggleDetailPanel) {
        tableRef.current.onToggleDetailPanel = (path, render) => {
          oldFunction.current(path, render);

          openedPanels.current.clear();


          return tableRef.current.props.data?.forEach((item) => {
            if (item.tableData.showDetailPanel) {
              openedPanels.current.add(item.id);
            }
          });
        }
    }

}, [tableRef])

  return (
    <Container className={classes.container}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography variant="subtitle1" gutterBottom>
            <span className={classes.iconContainer}>
              <SettingsIcon />
              <span className={classes.headerText}> {tableHeader}</span>
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/** Connections Subtable */}
          <div className={classes.table}>
            <MaterialTable
              // isLoading={isLoading}
              tableRef={tableRef}
              icons={tableIcons}
              options={{
                headerStyle: {
                  backgroundColor: ColorCodes.SECONDARY,
                  color: ColorCodes.WHITE,
                },
                paging: false,
              }}
              title=""
              columns={columns}
              data={data?.map((d) => (
                {
                  ...d,
                  tableData: {
                      showDetailPanel: openedPanels.current.has(d.id)
                          ? tableRef.current.props.detailPanel
                          : null,
                  },
              } || [] ))}
              editable={getEditableComponentChilds(
                data,
                handleChangeValue,
                userRole
              )}
              detailPanel={detailPanel}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}
