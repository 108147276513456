import { authRoles } from "auth/authRoles";
import Preferences from "./Preferences";

const preferencesRoutes = [
  {
    path: "/preferences",
    component: Preferences,
    auth: authRoles.admin,
  },
];

export default preferencesRoutes;
