import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_LOADING,
  SIGNUP_LOADING,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  SET_USER_ROLE_IDS,
} from "../actions/LoginActions";
//import LocalStorageService from "../../services/LocalStorageService";

const initialState = {
  success: false,
  loading: false,
  error: null,
  roleTypes: null,
};

const LoginReducer = function (state = initialState, action) {
  switch (action.type) {
    case FORGOT_PASSWORD_LOADING:
    case SIGNUP_LOADING:
    case LOGIN_LOADING: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        error: null,
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        error: null,
      };
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        error: null,
      };
    }
    case FORGOT_PASSWORD_ERROR:
    case SIGNUP_ERROR:
    case LOGIN_ERROR: {
      return {
        success: false,
        loading: false,
        error: {
          message: action.payload
        },
      };
    }
    case SET_USER_ROLE_IDS: {
      return {
        ...state,
        roleTypes: action.payload
      }
    }
    default: {
      return state;
    }
  }
};

export default LoginReducer;
