import _ from 'lodash';
import React from 'react';

export const getUserColumns = () => {
  const columns = [
    {
      title: 'User ID',
      field: 'userId',
      validate: (rowData) =>
        rowData.userId === '' ? 'User ID cannot be empty' : '',
      sortable: true,
    },
    {
      title: 'Name',
      field: 'name',
      validate: (rowData) =>
        rowData.name === '' ? 'Name cannot be empty' : '',
      sortable: true,
    },
    {
      title: 'Lastname',
      field: 'lastname',
      validate: (rowData) =>
        rowData.lastname === '' ? 'Lastname cannot be empty' : '',
      sortable: true,
    },
    {
      title: 'Email',
      field: 'email',
      validate: (rowData) =>
        rowData.email === '' ? 'Email cannot be empty' : '',
      sortable: true,
    },
    {
      title: 'Password',
      field: 'password',
      validate: (rowData) =>
        rowData.password === '' ? 'Password cannot be empty' : '',
      render: (rowData) => <p></p>,
      sortable: true,
    },
    {
      title: 'Role',
      field: 'userTypeId',
      validate: (rowData) =>
        rowData.userTypeId === '' ? 'Role cannot be empty' : '',
      sortable: true,
      lookup: userTypeLookups,
    },
  ];

  return columns;
};

export const formatUserData = (data) => {
  const clonedData = _.map(data, (item) => {
    if (item.index) {
      delete item['index'];
    }
    item = _.pick(item, validUserAttributes);
    return item;
  });
  return clonedData;
};

export const userTypeLookups = {
  1: 'Admin',
  2: 'Editor',
  3: 'Viewer',
};

export const validUserAttributes = [
  'id',
  'name',
  'lastname',
  'userId',
  'email',
  'password',
  'userTypeId',
];
