import React from "react";
import axios from "axios";
import ReactSelect from "react-select";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ListIcon from "@material-ui/icons/List";
import SettingsIcon from "@material-ui/icons/Settings";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveIcon from "@material-ui/icons/Save";
import ReactJson from "react-json-view";
import Swal from "sweetalert2";
import SourceJson from "../resources/data/source-data.json";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: "100vh",
  },
  viewerSettings: {
    overflow: "auto",
    height: "50vh",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
  grid: {
    marginBottom: "10px",
    marginRight: "10px",
  },
  title: {
    fontWeight: "bold",
  },
  headers: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    verticalAlign: "middle",
  },
  headerText: {
    marginLeft: "10px",
    fontWeight: "bold",
  },
  button: {
    float: "right",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.text,
      color: theme.palette.primary.main,
    },
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  topBar: {
    marginBottom: "24px",
  },
  loader: {
    position: "fixed",
    zIndex: 999,
    height: "2em",
    width: "2em",
    overflow: "show",
    margin: "auto",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
}));

export default function DataComponent() {
  const classes = useStyles();

  // Default State
  const [state, setState] = React.useState({
    theme: "monokai",
    src: SourceJson,
    collapsed: false,
    collapseStringsAfter: 15,
    onAdd: true,
    onEdit: true,
    onDelete: true,
    displayObjectSize: true,
    enableClipboard: true,
    indentWidth: 4,
    displayDataTypes: true,
    iconStyle: "triangle",
    isLoading: false,
  });

  // Fetching state variables
  const {
    theme,
    src,
    collapsed,
    collapseStringsAfter,
    onAdd,
    onEdit,
    onDelete,
    displayObjectSize,
    enableClipboard,
    indentWidth,
    displayDataTypes,
    iconStyle,
    isLoading,
  } = state;

  const style = {
    padding: "24px",
    borderRadius: "3px",
  };

  const getThemeInput = (theme) => {
    return (
      <ReactSelect
        name="theme-select"
        value={{value: theme, label: theme}}
        isDisabled={isLoading}
        options={[
          {value: "apathy", label: "apathy"},
          {value: "apathy:inverted", label: "apathy:inverted"},
          {value: "ashes", label: "ashes"},
          {value: "bespin", label: "bespin"},
          {value: "brewer", label: "brewer"},
          {value: "bright:inverted", label: "bright:inverted"},
          {value: "bright", label: "bright"},
          {value: "chalk", label: "chalk"},
          {value: "codeschool", label: "codeschool"},
          {value: "colors", label: "colors"},
          {value: "eighties", label: "eighties"},
          {value: "embers", label: "embers"},
          {value: "flat", label: "flat"},
          {value: "google", label: "google"},
          {value: "grayscale", label: "grayscale"},
          {
            value: "grayscale:inverted",
            label: "grayscale:inverted",
          },
          {value: "greenscreen", label: "greenscreen"},
          {value: "harmonic", label: "harmonic"},
          {value: "hopscotch", label: "hopscotch"},
          {value: "isotope", label: "isotope"},
          {value: "marrakesh", label: "marrakesh"},
          {value: "mocha", label: "mocha"},
          {value: "monokai", label: "monokai"},
          {value: "ocean", label: "ocean"},
          {value: "paraiso", label: "paraiso"},
          {value: "pop", label: "pop"},
          {value: "railscasts", label: "railscasts"},
          {value: "rjv-default", label: "rjv-default"},
          {value: "shapeshifter", label: "shapeshifter"},
          {
            value: "shapeshifter:inverted",
            label: "shapeshifter:inverted",
          },
          {value: "solarized", label: "solarized"},
          {value: "summerfruit", label: "summerfruit"},
          {
            value: "summerfruit:inverted",
            label: "summerfruit:inverted",
          },
          {value: "threezerotwofour", label: "threezerotwofour"},
          {value: "tomorrow", label: "tomorrow"},
          {value: "tube", label: "tube"},
          {value: "twilight", label: "twilight"},
        ]}
        onChange={(val) => {
          setState({...state, theme: val.value});
        }}
        className={classes.grid}
      />
    );
  };

  const getIconStyleInput = (iconStyle) => {
    return (
      <ReactSelect
        name="icon-style"
        value={{value: iconStyle, label: iconStyle}}
        isDisabled={isLoading}
        options={[
          {value: "circle", label: "circle"},
          {value: "square", label: "square"},
          {value: "triangle", label: "triangle"},
        ]}
        onChange={(val) => {
          setState({...state, iconStyle: val.value});
        }}
        className={classes.grid}
      />
    );
  };

  const getEditInput = (onEdit) => {
    return (
      <ReactSelect
        name="enable-edit"
        value={{value: onEdit, label: onEdit.toString()}}
        isDisabled={isLoading}
        options={[
          {value: true, label: "true"},
          {value: false, label: "false"},
        ]}
        onChange={(val) => {
          setState({...state, onEdit: val.value});
        }}
        className={classes.grid}
      />
    );
  };

  const getAddInput = (onAdd) => {
    return (
      <ReactSelect
        name="enable-add"
        value={{value: onAdd, label: onAdd.toString()}}
        isDisabled={isLoading}
        options={[
          {value: true, label: "true"},
          {value: false, label: "false"},
        ]}
        onChange={(val) => {
          setState({...state, onAdd: val.value});
        }}
        className={classes.grid}
      />
    );
  };

  const getDeleteInput = (onDelete) => {
    return (
      <ReactSelect
        name="enable-delete"
        value={{value: onDelete, label: onDelete.toString()}}
        isDisabled={isLoading}
        options={[
          {value: true, label: "true"},
          {value: false, label: "false"},
        ]}
        onChange={(val) => {
          setState({...state, onDelete: val.value});
        }}
      />
    );
  };

  const getEnableClipboardInput = (enableClipboard) => {
    return (
      <ReactSelect
        name="enable-clipboard"
        value={{
          value: enableClipboard,
          label: enableClipboard.toString(),
        }}
        isDisabled={isLoading}
        options={[
          {value: true, label: "true"},
          {value: false, label: "false"},
        ]}
        onChange={(val) => {
          setState({...state, enableClipboard: val.value});
        }}
        className={classes.grid}
      />
    );
  };

  const getObjectSizeInput = (displayObjectSize) => {
    return (
      <ReactSelect
        name="display-object-size"
        value={{
          value: displayObjectSize,
          label: displayObjectSize.toString(),
        }}
        isDisabled={isLoading}
        options={[
          {value: true, label: "true"},
          {value: false, label: "false"},
        ]}
        onChange={(val) => {
          setState({...state, displayObjectSize: val.value});
        }}
        className={classes.grid}
      />
    );
  };

  const getDataTypesInput = (displayDataTypes) => {
    return (
      <ReactSelect
        name="display-data-types"
        value={{
          value: displayDataTypes,
          label: displayDataTypes.toString(),
        }}
        isDisabled={isLoading}
        options={[
          {value: true, label: "true"},
          {value: false, label: "false"},
        ]}
        onChange={(val) => {
          setState({...state, displayDataTypes: val.value});
        }}
      />
    );
  };

  const getCollapsedStringsInput = (collapseStringsAfter) => {
    return (
      <ReactSelect
        name="collapse-strings"
        value={{value: collapseStringsAfter, label: collapseStringsAfter}}
        isDisabled={isLoading}
        options={[
          {value: false, label: "None"},
          {value: 5, label: 5},
          {value: 10, label: 10},
          {value: 15, label: 15},
          {value: 20, label: 20},
        ]}
        onChange={(val) => {
          setState({...state, collapseStringsAfter: val.value});
        }}
      />
    );
  };

  const getCollapsedInput = (collapsed) => {
    return (
      <ReactSelect
        name="collapsed"
        value={{value: collapsed, label: collapsed.toString()}}
        isDisabled={isLoading}
        options={[
          {value: true, label: "true"},
          {value: false, label: "false"},
        ]}
        onChange={(val) => {
          setState({...state, collapsed: val.value});
        }}
        className={classes.grid}
      />
    );
  };

  const getIndentWidthInput = (indentWidth) => {
    return (
      <ReactSelect
        name="indent-width"
        value={{value: indentWidth, label: indentWidth}}
        isDisabled={isLoading}
        options={[
          {value: 0, label: 0},
          {value: 1, label: 1},
          {value: 2, label: 2},
          {value: 3, label: 3},
          {value: 4, label: 4},
          {value: 5, label: 5},
          {value: 6, label: 6},
          {value: 7, label: 7},
          {value: 8, label: 8},
          {value: 9, label: 9},
          {value: 10, label: 10},
        ]}
        onChange={(val) => {
          setState({...state, indentWidth: val.value});
        }}
      />
    );
  };

  const saveJsonToFile = () => {
    if (isLoading) return;
    setState({...state, isLoading: true});

    Swal.fire({
      icon: "warning",
      title: "Do you want to save the changes?",
      text:
        "If you proceed to save the changes, the existing contents of the file will be overriden with the new changes.",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
      width: "750px",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        await axios
          .post("http://localhost:8080/api/upload-json", {
            url: "../dg-react/src/resources/data/source-data.json",
            data: src,
          })
          .then((res) => {
            setState({...state, isLoading: false});
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });

            Toast.fire({
              icon: "success",
              title: "Json saved successfully!",
            });
            // console.log("Done writing"); // Success
          })
          .catch((err) => {
            if (err) {
              setState({...state, isLoading: false});
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: JSON.stringify(err),
              });

              throw err;
            }
          });
      } else if (result.isDenied) {
        setState({...state, isLoading: false});
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: "info",
          title: "Changes are not saved!",
        });
      }
    });
  };

  // Check if the JSON content is changed or not.
  const isJsonChanged = JSON.stringify(src) !== JSON.stringify(SourceJson);

  return (
    <main className={classes.content}>
      <Container className={classes.container}>
        {/* JSON viewer header contianer */}
        <Grid container className={classes.topBar}>
          <Grid item xs={12} md={12} lg={9}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              className={classes.title + " " + classes.headers}>
              <span className={classes.iconContainer}>
                <ListIcon className={classes.headerIcon} />
                <span className={classes.headerText}>JSON Data Structure</span>
              </span>
            </Typography>
          </Grid>
          <Grid xs={12} md={12} lg={3}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={!isJsonChanged || isLoading}
              className={classes.button}
              startIcon={<SaveIcon />}
              onClick={() => saveJsonToFile()}>
              Save To File
            </Button>
          </Grid>
        </Grid>
        {/* JSON viewer conatainer*/}
        <Grid container className={classes.viewerSettings}>
          <Grid item xs={12} md={8} lg={12}>
            {isLoading ? (
              <div className={classes.loader}>
                <CircularProgress color="inherit" />
              </div>
            ) : (
              <ReactJson
                name={false}
                collapsed={collapsed}
                style={style}
                theme={theme}
                src={src}
                collapseStringsAfterLength={collapseStringsAfter}
                onEdit={
                  onEdit
                    ? (e) => {
                        console.log(e);
                        setState({...state, src: e.updated_src});
                      }
                    : false
                }
                onDelete={
                  onDelete
                    ? (e) => {
                        console.log(e);
                        setState({...state, src: e.updated_src});
                      }
                    : false
                }
                onAdd={
                  onAdd
                    ? (e) => {
                        console.log(e);
                        setState({...state, src: e.updated_src});
                      }
                    : false
                }
                displayObjectSize={displayObjectSize}
                enableClipboard={enableClipboard}
                indentWidth={indentWidth}
                displayDataTypes={displayDataTypes}
                iconStyle={iconStyle}
              />
            )}
          </Grid>
        </Grid>
        {/* Controls */}
        <Box mt={3}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            className={classes.topBar}>
            <span className={classes.iconContainer}>
              <SettingsIcon />
              <span className={classes.headerText}>Available Controls</span>
            </span>
          </Typography>
          <Grid container>
            <Grid item xs={12} md={6} lg={4}>
              <div className={classes.title}>Theme:</div>
              {getThemeInput(theme)}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <div className={classes.title}>Icon Style:</div>
              {getIconStyleInput(iconStyle)}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <div className={classes.title}>Display Data Types:</div>
              {getDataTypesInput(displayDataTypes)}
            </Grid>
          </Grid>
        </Box>

        <Box mt={3}>
          <Grid container>
            <Grid item xs={12} md={6} lg={4}>
              <div className={classes.title}>Enable Edit:</div>
              {getEditInput(onEdit)}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <div className={classes.title}>Enable Add:</div>
              {getAddInput(onEdit)}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <div className={classes.title}>Enable Delete:</div>
              {getDeleteInput(onDelete)}
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <Grid container>
            <Grid item xs={12} md={6} lg={4}>
              <div className={classes.title}>Enable Clipboard:</div>
              {getEnableClipboardInput(enableClipboard)}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <div className={classes.title}>Display Object Size:</div>
              {getObjectSizeInput(displayObjectSize)}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <div className={classes.title}>Indent Width:</div>
              {getIndentWidthInput(indentWidth)}
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <Grid container>
            <Grid item xs={12} md={6} lg={6}>
              <div className={classes.title}>Collapsed:</div>
              {getCollapsedInput(collapsed)}
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className={classes.title}>
                Collapse Strings After Length:
              </div>
              {getCollapsedStringsInput(collapseStringsAfter)}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </main>
  );
}
