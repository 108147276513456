import axios from "./AxiosInterceptors";
import storageService from "./StorageService";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const LS_KEY_ACCESS_TOKEN = "access_token";

export class FaqService {

    getAll() {
        const url = `${API_BASE_URL}/api/faq`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data.faq);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    create(faq) {
        const url = `${API_BASE_URL}/api/faq`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        const newFaq = faq[faq.length - 1]

        const faqToSend = {
            title: newFaq.title,
            description: newFaq.description,
        }

        return new Promise((resolve, reject) => {
            axios
                .post(url, faqToSend, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response.data.error);
                    }
                });
        });
    };

    formatFaq (faq) {
        return ({
            id: faq.id,
            title: faq.title,
            description: faq.description,
        })
    }

    update(faq) {
        const url = `${API_BASE_URL}/api/faq/${faq.id}`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        const faqToSend = this.formatFaq(faq)

        return new Promise((resolve, reject) => {
            axios
                .put(url, faqToSend, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    updateAll(faq) {
        const url = `${API_BASE_URL}/api/faq`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }
        const faqToSend  = []

        faq.forEach((element) => {
            faqToSend.push(this.formatFaq(element))
        })
        
        return new Promise((resolve, reject) => {
            axios
                .put(url, faqToSend, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    delete(id) {
        const url = `${API_BASE_URL}/api/faq/${id}`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        return new Promise((resolve, reject) => {
            axios
                .delete(url, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };
};

const faqService = new FaqService();
export default faqService;