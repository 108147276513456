import { authRoles } from 'auth/authRoles';
import UserProfilePage from './UserProfilePage';
import AdminPanelPage from './AdminPanelPage';
// import SuperUserAdminPage from "./SuperuserAdminPage";

const userRoutes = [
  {
    path: '/user-profile',
    component: UserProfilePage,
    auth: authRoles.guest,
  },
  {
    path: '/admin-panel',
    component: AdminPanelPage,
    auth: authRoles.admin,
  },
  // {
  //   path: "/super-user",
  //   component: SuperUserAdminPage,
  //   auth: authRoles.sa,
  // },
];

export default userRoutes;
