import axios from "./AxiosInterceptors";
import storageService from "./StorageService";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const LS_KEY_ACCESS_TOKEN = "access_token";

export class ConnectionsService {

    create(connection) {
        const url = `${API_BASE_URL}/api/connections`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        let newConnection = connection[connection.length - 1]

        return new Promise((resolve, reject) => {
            axios
                .post(url, newConnection, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status && !res.data.error) {
                        resolve(res.data);
                    } else if (res.data.error) {
                        reject(res.data.error)
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response.data.error);
                    }
                });
        });
    };

    update(id, connectionUpdated) {
        const url = `${API_BASE_URL}/api/connections/${id}`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        return new Promise((resolve, reject) => {
            axios
                .put(url, connectionUpdated, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    updateAll(connections) {
        const url = `${API_BASE_URL}/api/connections`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        return new Promise((resolve, reject) => {
            axios
                .put(url, connections, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    delete(id) {
        const url = `${API_BASE_URL}/api/connections/${id}`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        return new Promise((resolve, reject) => {
            axios
                .delete(url, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    console.log(error)
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };
};

const connectionsService = new ConnectionsService();
export default connectionsService;