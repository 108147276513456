import axios from './AxiosInterceptors';
import storageService from './StorageService';

const API_BASE_URL = process.env.REACT_APP_API_URL;
const LS_KEY_ACCESS_TOKEN = 'access_token';
const USERS_URL = '/api/users';

export class UsersService {
  getAll() {
    const url = `${API_BASE_URL}${USERS_URL}`;
    const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);

    const headers = {
      Authorization: `jwt ${currentToken}`,
    };

    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data.users);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          }
        });
    });
  }

  create(users) {
    const url = `${API_BASE_URL}${USERS_URL}`;
    const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);

    const headers = {
      Authorization: `jwt ${currentToken}`,
    };

    const newUser = users[users.length - 1];

    const userToSend = {
      name: newUser.name,
      lastname: newUser.lastname,
      email: newUser.email,
      userId: newUser.userId ? newUser.userId : null,
      password: newUser.password,
      userTypeId: newUser.userTypeId,
      companyId: newUser.companyId ? newUser.companyId : null,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(url, userToSend, {
          headers: headers,
        })
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.error);
          }
        });
    });
  }

  formatUser(user) {
    return {
      id: user.id,
      name: user.name,
      lastname: user.lastname,
      email: user.email,
      userId: user.userId,
      password: user.password,
      userTypeId: user.userTypeId,
      companyId: user.companyId,
    };
  }

  update(user) {
    const url = `${API_BASE_URL}${USERS_URL}/${user.id}`;
    const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);

    const headers = {
      Authorization: `jwt ${currentToken}`,
    };

    const userToSend = this.formatUser(user);

    return new Promise((resolve, reject) => {
      axios
        .put(url, userToSend, {
          headers: headers,
        })
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          }
        });
    });
  }

  updatePassword(user) {
    const url = `${API_BASE_URL}${USERS_URL}/${user.id}`;
    const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);

    const headers = {
      Authorization: `jwt ${currentToken}`,
    };

    const userToSend = this.formatUser(user);
    const password = userToSend.password;
    return new Promise((resolve, reject) => {
      axios
        .patch(
          url,
          { password },
          {
            headers: headers,
          },
        )
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          }
        });
    });
  }

  updateAll(users) {
    const url = `${API_BASE_URL}${USERS_URL}`;
    const usersToSend = [];
    const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);

    const headers = {
      Authorization: `jwt ${currentToken}`,
    };

    users.forEach((element) => {
      usersToSend.push(this.formatUser(element));
    });

    return new Promise((resolve, reject) => {
      axios
        .put(url, usersToSend, {
          headers: headers,
        })
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          }
        });
    });
  }

  delete(id) {
    const url = `${API_BASE_URL}${USERS_URL}/${id}`;
    const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);

    const headers = {
      Authorization: `jwt ${currentToken}`,
    };

    return new Promise((resolve, reject) => {
      axios
        .delete(url, {
          headers: headers,
        })
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          }
        });
    });
  }
}

const usersService = new UsersService();
export default usersService;
