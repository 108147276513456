import { authRoles } from "auth/authRoles";

export const navigations = [
  {
    name: "Homepage",
    path: "/homepage",
    icon: "home",
    auth: authRoles.public,
  },
  {
    name: "CMS",
    path: "/data",
    icon: "business_center",
    auth: authRoles.editor,
  },
  {
    name: "Preferences",
    path: "/preferences",
    icon: "colorize",
    auth: authRoles.admin,
  },
  {
    name: "Admin panel",
    path: "/admin-panel",
    icon: "developer_board",
    auth: authRoles.admin,
  },
];

/*
export const userNavigations = [
  {
    name: "Homepage",
    path: "/homepage",
    icon: "dashboard",
    auth: authRoles.public,
  },
  {
    name: "CMS",
    path: "/data",
    icon: "dashboard",
    auth: authRoles.editor,
  },
  {
    name: "Preferences",
    path: "/preferences",
    icon: "dashboard",
    auth: authRoles.admin,
  },
];
*/