export const authRoles = {
  sa: [99], // Only Super Admin has access
  admin: [1, 99], // Only SA & Admin has access
  editor: [1, 2, 99], // Only SA & Admin & Editor has access
  guest: [1, 2, 3, 99], // Everyone has access
  public: [1, 2, 3, 4, 99], // Everyone has access
};

// Check out app/views/dashboard/DashboardRoutes.js
// Only SA & Admin has dashboard access

// const dashboardRoutes = [
//   {
//     path: "/dashboard/analytics",
//     component: Analytics,
//     auth: authRoles.admin <----------------
//   }
// ];
