import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

function LoaderComponent() {
  return (
    <CircularProgress
      size={40}
      left={10}
      status="loading"
      color="inherit"
      style={{
        position: "relative",
        marginLeft: "50%",
        marginTop: "50%",
      }}
    />
  );
}

export default LoaderComponent;
