import React, {useEffect, useState} from "react";
import "./accordion.style.scss";
import faqService from "services/FaqService";

export default function FaqComponent(props) {
  const [state, setState] = useState({
    faq: [],
    isLoading: false,
  });

  const {faq, isLoading} = state;

  useEffect(() => {
    let mounted = true;
    let faq = [];

    // Getting faq from backend
    faqService.getAll()
      .then((items) => {
        if (mounted) {
          faq = items;
          setState({
            ...state, 
            faq: faq
          });
        }
      });
    return () => (mounted = false);
  }, []);

  const getFaqItems = () => {
    return (
      faq &&
      faq.map((item) => {
        return (
          <div className="card">
            <div className="card-header" id={`heading${item.id}`}>
              <h2 className="clearfix mb-0">
                <a
                  className="btn btn-link"
                  data-toggle="collapse"
                  data-target={`#collapse${item.id}`}
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  <i className="fa fa-chevron-circle-down"></i> {item.title}
                </a>
              </h2>
            </div>
            <div
              id={`collapse${item.id}`}
              className="collapse show"
              aria-labelledby={`heading${item.id}`}
              data-parent="#accordionExample">
              <div className="card-body">
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}></p>
              </div>
            </div>
          </div>
        );
      })
    );
  };

  return (
    <div className="wrapper">
      <div className="js-viewFAQ">
        <div className="container-lg">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="page-title">HELP</h1>
              <div className="accordion" id="accordionExample">
                {getFaqItems()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
