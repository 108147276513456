import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import {MenuItemsEnum} from "../helpers/Enums";
import {DataComponent, HomepageComponent} from ".";
import {TableContainer} from "../views/tables";

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{marginTop: "24px", marginBottom: "24px"}}>
      {"Copyright © "}
      <Link color="inherit" href="#">
        Dynamogramm
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    marginTop: "80px",
  },
  container: {
    maxWidth: "100%",
  },
  breadCrumb: {
    marginTop: "80px",
  },
}));

export default function MainComponent(props) {
  const {activeItem, handleMenuChange} = props;
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Container className={classes.container}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textSecondary">Menu</Typography>
          <Typography color="textPrimary">
            {activeItem === MenuItemsEnum.HOMEPAGE
              ? "Home Page"
              : activeItem === MenuItemsEnum.DATA
              ? "Data"
              : "Data Table"}
          </Typography>
        </Breadcrumbs>
      </Container>
      <Divider />
      {activeItem === MenuItemsEnum.HOMEPAGE ? (
        <HomepageComponent activeItem={activeItem} />
      ) : activeItem === MenuItemsEnum.DATA ? (
        <DataComponent />
      ) : (
        <TableContainer handleMenuChange={handleMenuChange} />
      )}
      <Copyright />
    </div>
  );
}
