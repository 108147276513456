import React from 'react';
import _ from 'lodash';
import { RichTextEditor } from 'matx';

export const getNodeColumns = (nodes, nodesTypes) => {
  const columns = [
    {
      title: 'Name',
      field: 'name',
      validate: (rowData) => {
        return {
          isValid: !rowData.name ? false : true,
          helperText: !rowData.name ? 'Name cannot be empty' : '',
        };
      },
      sortable: true,
      customFilterAndSearch: (value, rowData) => {
        const filteredNodes = searchNodesByKeyword(nodes, value);
        if (filteredNodes && filteredNodes.length) {
          const nodeExists = _.filter(
            filteredNodes,
            (item) => item.id === rowData.id,
          );
          if (nodeExists && nodeExists.length) {
            return true;
          } else {
            return false;
          }
        }
        return false; // customize here according your search algorithm.
      },
    },
    {
      title: 'Type',
      field: 'type',
      validate: (rowData) => {
        return {
          isValid: !rowData.type ? false : true,
          helperText: !rowData.type ? 'Type cannot be empty' : '',
        };
      },
      sortable: true,
      lookup: getNodeTypeLookup(nodesTypes),
    },
    // {
    //   title: "Value",
    //   field: "value",
    //   validate: (rowData) =>
    //     rowData.value === "" ? "Value cannot be empty" : "",
    //   sortable: true,
    // },
    // {
    //   title: "Order",
    //   field: "order",
    //   sortable: true,
    // },
    {
      title: 'Information',
      field: 'description',
      sortable: true,
      editComponent: (props) => {
        return (
          <RichTextEditor
            content={props.value || ''}
            handleContentChange={(value) => {
              props.onChange(value);
            }}
            placeholder="insert description here..."
          />
        );
      },
      render: (rowData) => (
        <div
          dangerouslySetInnerHTML={{
            __html: rowData.description,
          }}
        ></div>
      ),
    },
  ];

  return columns;
};

export const searchNodesByKeyword = (nodes, searchTerm) => {
  const filteredNodes = nodes
    .filter((item) => {
      return iterateObject(item, searchTerm);
    })
    .filter((item) => item != null);

  function iterateObject(obj, searchTerm) {
    let isMatchFound = false;
    for (let prop in obj) {
      if (prop === 'children') {
        const filteredChildNodes = obj[prop].filter((item) => {
          return iterateObject(item, searchTerm);
        });
        if (filteredChildNodes && filteredChildNodes.length) {
          return filteredChildNodes;
        }
      } else {
        if (obj[prop] && searchTerm) {
          let propValue = obj[prop].toString().toLowerCase();
          if (
            propValue &&
            propValue.includes(searchTerm.toLowerCase())
          ) {
            isMatchFound = true;
          }
        }
      }
    }
    if (isMatchFound) {
      return obj;
    } else {
      return null;
    }
  }

  return filteredNodes;
};

export const formatNodeData = (data) => {
  const clonedData = _.map(data, (item) => {
    item = _.pick(item, validNodeAttributes);
    if (item.level) {
      delete item['level'];
    }
    if (item.groupId) {
      delete item['groupId'];
    }
    if (item.index) {
      delete item['index'];
    }
    if (item.children) {
      item.children = _.map(item.children, (subItem) => {
        subItem = _.pick(subItem, validNodeAttributes);
        if (subItem.level) {
          delete subItem['level'];
        }
        if (subItem.groupId) {
          delete subItem['groupId'];
        }
        if (item.index) {
          delete item['index'];
        }
        if (subItem.children) {
          subItem.children = _.map(
            subItem.children,
            (garndSubItem) => {
              return _.pick(garndSubItem, validNodeAttributes);
            },
          );
        }
        return subItem;
      });
    }

    return item;
  });

  return clonedData;
};

export const getConnectionsLookup = (nodes, parentRecord) => {
  let lookups = {};
  let items = [];
  // Looking into parent nodes
  nodes &&
    nodes.forEach((item, index) => {
      if (
        item.id !== parentRecord.id
        // parentRecord.connections &&
        // !_.includes(parentRecord.connections, item.id)
      )
        // lookups = {
        //   ...lookups,
        //   [item.id]: item.id,
        // };
        items.push({
          id: item.id,
          name: item.name,
        });
    });

  // Looking into child nodes
  nodes &&
    nodes.forEach((item, index) => {
      item.children &&
        item.children.forEach((childItem) => {
          if (childItem.id !== parentRecord.id)
            // lookups = {
            //   ...lookups,
            //   [childItem.id]: childItem.id,
            // };
            items.push({
              id: childItem.id,
              name: childItem.name,
            });
        });
    });

  // Looking into grand child nodes
  nodes &&
    nodes.forEach((item, index) => {
      item.children &&
        item.children.forEach((childItem) => {
          childItem.children &&
            childItem.children.forEach((grandChildItem) => {
              if (grandChildItem.id !== parentRecord.id)
                // lookups = {
                //   ...lookups,
                //   [grandChildItem.id]: grandChildItem.id,
                // };
                items.push({
                  id: grandChildItem.id,
                  name: grandChildItem.name,
                });
            });
        });
    });

  items = _.orderBy(items, ['name'], ['asc']);
  items.forEach((item) => {
    lookups = {
      ...lookups,
      [item.id]: item.name,
    };
  });
  return lookups;
};

export const getConnectionsLookupNoChild = (nodes) => {
  let lookups = {};
  let items = [];
  // Looking into parent nodes
  nodes &&
    nodes.forEach((item, index) => {
      // lookups = {
      //   ...lookups,
      //   [item.id]: item.id,
      // };
      items.push({
        id: item.id,
        name: item.name,
      });
    });

  // Looking into child nodes

  items = _.orderBy(items, ['name'], ['asc']);
  items.forEach((item) => {
    lookups = {
      ...lookups,
      [item.id]: item.name,
    };
  });
  return lookups;
};

export const getNodeIdLookup = (nodes) => {
  let lookups = {};

  nodes = _.orderBy(nodes, ['name'], ['asc']);
  // Looking into parent nodes
  nodes &&
    nodes.forEach((item, index) => {
      lookups = {
        ...lookups,
        [item.id]: item.name,
      };
    });

  // // Looking into child nodes
  // nodes &&
  //   nodes.forEach((item, index) => {
  //     item.children &&
  //       item.children.forEach((childItem) => {
  //         lookups = {
  //           ...lookups,
  //           [childItem.id]: childItem.name,
  //         };
  //       });
  //   });

  // // Looking into grand child nodes
  // nodes &&
  //   nodes.forEach((item, index) => {
  //     item.children &&
  //       item.children.forEach((childItem) => {
  //         childItem.children &&
  //           childItem.children.forEach((grandChildItem) => {
  //             lookups = {
  //               ...lookups,
  //               [grandChildItem.id]: grandChildItem.name,
  //             };
  //           });
  //       });
  //   });

  return lookups;
};

export const getNodeIdLookupV2 = (nodes) => {
  let lookups = {};
  let items = [];

  // Looking into parent nodes
  nodes &&
    nodes.forEach((item, index) => {
      // lookups = {
      //   ...lookups,
      //   [item.id]: item.name,
      // };
      items.push({
        id: item.id,
        name: item.name,
      });

      if (item.children) {
        item.children.forEach((childItem) => {
          // lookups = {
          //   ...lookups,
          //   [childItem.id]: childItem.name,
          // };

          items.push({
            id: childItem.id,
            name: `${item.name.trim()}/${childItem.name.trim()}`,
          });
          if (childItem.children) {
            childItem.children.forEach((grandChildItem) => {
              // lookups = {
              //   ...lookups,
              //   [grandChildItem.id]: grandChildItem.name,
              // };

              items.push({
                id: grandChildItem.id,
                name: `${item.name.trim()}/${childItem.name.trim()}/${grandChildItem.name.trim()}`,
              });

              if (grandChildItem.children) {
                grandChildItem.children.forEach(
                  (greatGrandChildItem) => {
                    // lookups = {
                    //   ...lookups,
                    //   [grandChildItem.id]: grandChildItem.name,
                    // };

                    items.push({
                      id: greatGrandChildItem.id,
                      name: `${item.name.trim()}/${childItem.name.trim()}/${grandChildItem.name.trim()}/${greatGrandChildItem.name.trim()}`,
                    });
                  },
                );
              }
            });
          }
        });
      }
    });

  items = _.orderBy(items, ['name'], ['asc']);
  items.forEach((item) => {
    lookups = {
      ...lookups,
      [item.id]: item.name,
    };
  });
  return lookups;
};

export const getNodeTypeLookup = (nodes) => {
  let lookups = {};
  let items = [];

  // Looking into parent nodes
  nodes &&
    nodes.forEach((item, index) => {
      // lookups = {
      //   ...lookups,
      //   [item.id]: item.name,
      // };
      items.push({
        id: item.id,
        name: item.value,
      });

      if (item.children) {
        item.children.forEach((childItem) => {
          // lookups = {
          //   ...lookups,
          //   [childItem.id]: childItem.name,
          // };

          items.push({
            id: childItem.id,
            name: childItem.value,
          });
          if (childItem.children) {
            childItem.children.forEach((grandChildItem) => {
              // lookups = {
              //   ...lookups,
              //   [grandChildItem.id]: grandChildItem.name,
              // };

              items.push({
                id: grandChildItem.id,
                name: grandChildItem.value,
              });

              if (grandChildItem.children) {
                grandChildItem.children.forEach(
                  (greatGrandChildItem) => {
                    // lookups = {
                    //   ...lookups,
                    //   [grandChildItem.id]: grandChildItem.name,
                    // };

                    items.push({
                      id: greatGrandChildItem.id,
                      name: greatGrandChildItem.value,
                    });
                  },
                );
              }
            });
          }
        });
      }
    });

  items = _.orderBy(items, ['name'], ['asc']);
  items.forEach((item) => {
    lookups = {
      ...lookups,
      [item.id]: item.name,
    };
  });
  return lookups;
};

export const validNodeAttributes = [
  'id',
  'name',
  'type',
  'nodeIndex',
  'value',
  'children',
  'connections',
  'team',
  'order',
  'description',
  'x',
  'y',
];

export const orderNodes = (nodes) => {
  let orderedNodes = [];
  let i = 0;
  for (const item in nodes) {
    let nodeToInclude = nodes[item];
    if (nodeToInclude.father_id) {
      for (const father in nodes) {
        if (nodes[father].id === nodeToInclude.father_id) {
          if (nodes[father].children) {
            nodes[father].children.push(nodeToInclude);
          } else {
            nodes[father].children = [];
            nodes[father].children.push(nodeToInclude);
          }
        }
      }
    } else {
      orderedNodes.push(nodes[i]);
    }
    i++;
  }
  return orderedNodes;
};

export const updateNodeConnection = (
  nodes,
  connections,
  operation,
) => {
  if (operation === 'edit') {
    const nodeId = connections.nodeId;
    const nodesUpdated = nodes.map((node) => {
      if (node.id === nodeId && node.connections.length > 0) {
        const connectionUpdated = node.connections.map(
          (connection) => {
            if (connection.id === connections.id) {
              connection = connections;
              return connection;
            } else {
              return connection;
            }
          },
        );
        node.connections = connectionUpdated;
        return node;
      } else {
        return node;
      }
    });
    return nodesUpdated;
  } else {
    const nodesUpdated = nodes.map((node) => {
      const nodeId = connections[0].nodeId;
      if (node.id === nodeId && node.connections.length > 0) {
        node.connections = connections;
        return node;
      } else {
        return node;
      }
    });
    return nodesUpdated;
  }
};

export const addConnectionToNode = (nodes, id, newConnection) => {
  console.log({ nodes });
  const nodesUpdated = nodes.map((node) => {
    if (node.id === id) {
      node.connections.push(newConnection);
      return node;
    } else {
      return node;
    }
  });

  return nodesUpdated;
};

export const replaceNode = (nodes, id, nodeUpdated) => {
  const nodesUpdated = nodes.map((node) => {
    if (node.id === id) {
      return nodeUpdated;
    } else {
      return node;
    }
  });

  return nodesUpdated;
};

export const addChildrenToNode = (arr, id, childData) => {
  for (const key in arr) {
    if (arr[key].id) {
      if (arr[key].id === id) arr[key].children = childData;
    }
    if (arr[key].children instanceof Array) {
      addChildrenToNode(arr[key].children, id, childData);
    }
  }
  return arr;
};

export const replaceAllEditedChildNode = (arr, id, childData) => {
  for (const key in arr) {
    if (arr[key].id) {
      if (arr[key].id === id) arr[key].children = childData;
    }
    if (arr[key].children instanceof Array) {
      replaceAllEditedChildNode(arr[key].children, id, childData);
    }
  }
  return arr;
};

export const replaceEditedChild = (arr, id, childData) => {
  for (const key in arr) {
    if (arr[key].id) {
      if (arr[key].id === id) arr[key] = childData;
    }
    if (arr[key].children instanceof Array) {
      replaceEditedChild(arr[key].children, id, childData);
    }
  }
  return arr;
};

// export const deleteChildNode = (arr, id) => {
//   for (const key in arr) {
//     if (arr[key].id) {
//       if (arr[key].id === id) arr.splice(key, 1);
//     }
//     if (arr[key].children instanceof Array) {
//       replaceEditedChild(arr[key].children, id);
//     }
//   }
//   return arr;
// };

export const deleteChildNode = (arr, id) => {
  return arr
    .filter((el) => el.id !== id)
    .map((el) => {
      if (!el.children || !el.children.length) return el;
      el.children = deleteChildNode(el.children, id);
      return el;
    });
};

export const findTypes = (nodeArr, nodeId) => {
  let type;
  nodeArr.forEach((node) => {
    if (node.id === nodeId) {
      type = node.types.name;
    }
  });
  return type;
};

export const checkParent = (node, idToFind) => {
  let isTheSameParent = false;
  if (node.parent) {
    isTheSameParent = checkParent(node.parent, idToFind);
  } else {
    if (node.data?.id === idToFind || node.id === idToFind) {
      isTheSameParent = true;
    }
  }
  return isTheSameParent;
};
