import { authRoles } from "auth/authRoles";
import TableContainer from "./TableContainer";

const tableContainerRoutes = [
  {
    path: "/data",
    component: TableContainer,
    auth: authRoles.editor,
  },
];

export default tableContainerRoutes;
