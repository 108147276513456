import axios from 'axios';
import storageService from './StorageService';

const API_BASE_URL = process.env.REACT_APP_API_URL;
const LS_KEY_ACCESS_TOKEN = 'access_token';
const LS_KEY_USER = 'user';

export class AuthService {
  signIn(email, password) {
    console.log(email, password);
    const url = `${API_BASE_URL}/api/login`;

    const data = {
      email: email,
      password: password,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then((res) => {
          if (200 === res.status && !res.data.error) {
            storageService.set(
              LS_KEY_ACCESS_TOKEN,
              res.data.user.token,
            );
            storageService.set(
              LS_KEY_USER,
              JSON.stringify(res.data.user),
            );
            resolve(res.data);
          } else {
            throw new Error('Wrong credentials');
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  signUp(data) {
    const url = `${API_BASE_URL}/api/users`;

    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  getUserRoleTypes() {
    const url = `${API_BASE_URL}/api/users/types`;
    const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);

    const headers = {
      Authorization: `jwt ${currentToken}`,
    };

    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  refreshToken() {
    const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
    const url = `${API_BASE_URL}/api/refreshToken/${currentToken}`;

    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getNewToken(user) {
    const url = `${API_BASE_URL}/api/generateToken`;

    return new Promise((resolve, reject) => {
      axios
        .post(url, user)
        .then((res) => {
          if (200 === res.status) {
            storageService.set(LS_KEY_ACCESS_TOKEN, res.data);
            resolve(res.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  forgotPassword(email) {
    const url = `${API_BASE_URL}/api/forgot-password`;

    const data = {
      email: email,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  resetPassword(password, token) {
    const url = `${API_BASE_URL}/api/reset-password`;

    const data = {
      password: password,
    };

    const headers = {
      Authorization: `jwt ${token}`,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(url, data, {
          headers: headers,
        })
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
}

const authService = new AuthService();
export default authService;
