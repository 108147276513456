import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import SettingsIcon from '@material-ui/icons/Settings';
import Swal from 'sweetalert2';
import _ from 'lodash';
import usersService from 'services/UsersService';
import {
  formatUserData,
  getUserColumns,
  userTypeLookups,
} from '../../helpers/UsersDataTable';
import { ChildTableComponent } from '../../components';
import { tableIcons } from '../../components/ReusableComponents/DatatableIconsComponent';
import {
  useStyles,
  getEditableComponent,
  getOptions,
} from '../../helpers/DataTable';

const AdminPanelPage = (props) => {
  const [state, setState] = React.useState({
    data: [],
    isLoading: true,
    isUserChanged: false,
    rowsToBeDeleted: [],
    searchTerm: null,
  });
  const userRole = useSelector((state) => state.user.role);

  const {
    data,
    isLoading,
    isUserChanged,
    rowsToBeDeleted,
    searchTerm,
  } = state;

  useEffect(() => {
    let mounted = true;
    let users = [];
    // Getting Users from backend
    usersService.getAll().then((items) => {
      if (mounted) {
        users = items;
        _.forEach(users, function (user) {
          user.password = '';
        });
        setState({
          ...state,
          isLoading: false,
          data: users,
        });
      }
    });

    return () => (mounted = false);
  }, []);

  const handleUserRefresh = () => {
    setState({ ...state, isLoading: true });
    setTimeout(() => {
      setState({
        ...state,
        isLoading: false,
        isUserChanged: false,
      });
    }, 1000);
  };

  const handleUserChange = (value, id, operation) => {
    if (isLoading) return;
    let isUserChanged = true;
    setState({ ...state, isLoading: true });
    switch (operation) {
      case 'create':
        const user = formatUserData(value);
        usersService
          .create(user)
          .then((res) => {
            user.pop();
            user.push(res);
            isUserChanged = false;
            setState({
              ...state,
              isLoading: false,
              isUserChanged: isUserChanged,
              data: user,
            });
            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });

            Toast.fire({
              icon: 'success',
              title: 'User data has been saved!',
            });
          })
          .catch((err) => {
            if (err) {
              setState({ ...state, isLoading: false });
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err,
              });
            }
          });
        break;
      case 'delete':
        usersService
          .delete(id)
          .then((res) => {
            _.remove(value, (item) => item.id === id);
            setState({
              ...state,
              data: value,
              isLoading: false,
            });
            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });

            Toast.fire({
              icon: 'success',
              title: 'User data has been deleted!',
            });
          })
          .catch((err) => {
            setState({ ...state, isLoading: false });
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'edit':
        const userEdited = value.filter((user) => user.id === id);
        usersService
          .update(...userEdited)
          .then((res) => {
            if (userEdited[0].password) {
              usersService
                .updatePassword(...userEdited)
                .then((res) => {
                  setState({
                    ...state,
                    data: value,
                    isLoading: false,
                  });
                  const Toast = Swal.mixin({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                  });

                  Toast.fire({
                    icon: 'success',
                    title: 'User data has been updated!',
                  });
                })
                .catch((err) => {
                  setState({ ...state, isLoading: false });
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err,
                  });
                });
            } else {
              setState({
                ...state,
                data: value,
                isLoading: false,
              });

              const Toast = Swal.mixin({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
              });

              Toast.fire({
                icon: 'success',
                title: 'User data has been updated!',
              });
            }
          })
          .catch((err) => {
            setState({ ...state, isLoading: false });
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'edit_all':
        usersService
          .updateAll(value)
          .then((res) => {
            setState({
              ...state,
              data: value,
              isLoading: false,
            });
            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });

            Toast.fire({
              icon: 'success',
              title: 'Users has been updated!',
            });
          })
          .catch((err) => {
            setState({ ...state, isLoading: false });
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      default:
        break;
    }
  };

  const classes = useStyles();
  const columns = getUserColumns();

  return (
    <main className={classes.content}>
      <Container className={classes.container}>
        {/* JSON viewer conatainer*/}
        <Grid container className={classes.viewerSettings}>
          <Grid item xs={12} md={12} lg={12}>
            <div style={{ maxWidth: '100%' }}>
              <MaterialTable
                isLoading={isLoading}
                icons={tableIcons}
                columns={[
                  {
                    title: 'User ID',
                    field: 'userId',
                    validate: (rowData) =>
                      rowData.userId === ''
                        ? 'User ID cannot be empty'
                        : '',
                    sortable: true,
                  },
                  {
                    title: 'Name',
                    field: 'name',
                    validate: (rowData) =>
                      rowData.name === ''
                        ? 'Name cannot be empty'
                        : '',
                    sortable: true,
                  },
                  {
                    title: 'Lastname',
                    field: 'lastname',
                    validate: (rowData) =>
                      rowData.lastname === ''
                        ? 'Lastname cannot be empty'
                        : '',
                    sortable: true,
                  },
                  {
                    title: 'Email',
                    field: 'email',
                    validate: (rowData) =>
                      rowData.email === ''
                        ? 'Email cannot be empty'
                        : '',
                    sortable: true,
                  },
                  {
                    title: 'Password',
                    field: 'password',
                    render: (rowData) => <p></p>,
                    sortable: true,
                  },
                  {
                    title: 'Role',
                    field: 'userTypeId',
                    validate: (rowData) =>
                      rowData.userTypeId === ''
                        ? 'Role cannot be empty'
                        : '',
                    sortable: true,
                    lookup: userTypeLookups,
                  },
                ]}
                data={data}
                title={
                  <div className={classes.iconContainer}>
                    <SettingsIcon />
                    <h2 className={classes.headerText}>Users </h2>
                  </div>
                }
                options={getOptions}
                editable={getEditableComponent(
                  data,
                  handleUserChange,
                  userRole,
                )}
                actions={[
                  {
                    icon: 'refresh',
                    tooltip: 'Refresh Data',
                    isFreeAction: true,
                    onClick: () => handleUserRefresh(),
                  },
                ]}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});
export default withRouter(connect(mapStateToProps)(AdminPanelPage));
