import axios from "./AxiosInterceptors";
import storageService from "./StorageService";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const LS_KEY_ACCESS_TOKEN = "access_token";

export class PeopleService {

    getAll() {
        const url = `${API_BASE_URL}/api/people`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data.people);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    create(people) {
        const url = `${API_BASE_URL}/api/people`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        const newPerson = people[people.length - 1]

        const personToSend = {
            peopleId: people.length,
            bio: newPerson.bio,
            email: newPerson.email,
            location: newPerson.location,
            name: newPerson.name,
            phone: newPerson.phone,
            type: newPerson.type,
        }

        return new Promise((resolve, reject) => {
            axios
                .post(url, personToSend, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response.data.error);
                    }
                });
        });
    };

    formatPerson (person) {
        return ({
            id: person.id,
            bio: person.bio,
            email: person.email,
            location: person.location,
            name: person.name,
            phone: person.phone,
            type: person.type
        })
    }

    update(person) {
        const url = `${API_BASE_URL}/api/people/${person.id}`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        const personToSend = this.formatPerson(person)

        return new Promise((resolve, reject) => {
            axios
                .put(url, personToSend, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    updateAll(people) {
        const url = `${API_BASE_URL}/api/people`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }
        const peopleToSend  = []

        people.forEach((element) => {
            peopleToSend.push(this.formatPerson(element))
        })
        
        return new Promise((resolve, reject) => {
            axios
                .put(url, peopleToSend, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    delete(id) {
        const url = `${API_BASE_URL}/api/people/${id}`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        return new Promise((resolve, reject) => {
            axios
                .delete(url, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };
};

const peopleService = new PeopleService();
export default peopleService;