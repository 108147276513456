import "./fake-db";
import "./styles/_app.scss";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import MatxTheme from "./components/MatxLayout/MatxTheme/MatxTheme";
import AppContext from "./appContext";
import history from "history.js";

import routes from "./RootRoutes";
import { Store } from "./redux/Store";
//import Auth from "./auth/Auth";
import MatxLayout from "./components/MatxLayout/MatxLayoutSFC";
import AuthGuard from "./auth/AuthGuard";
import Swal from "sweetalert2";
import Auth from "auth/Auth";



const App = () => {

  const isIE = /*@cc_on!@*/false || !!document.documentMode;

  if(isIE){
    return null
  } else {
    return (
      <AppContext.Provider value={{ routes }}>
        <Provider store={Store}>
          <MatxTheme>
            <Router history={history}>
              <Auth>
                <AuthGuard>
                  <MatxLayout />
                </AuthGuard>
              </Auth>
            </Router>
          </MatxTheme>
        </Provider>
      </AppContext.Provider>
    );
  }
 
};

export default App;
