import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { refreshToken } from 'redux/actions/LoginActions';
import { logoutUser } from 'redux/actions/UserActions';
import { signInUser } from '../redux/actions/LoginActions';
//import { refreshToken } from "../Redux/Auth/actions";

const Auth = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loadingLocal, setLoadingLocal] = useState(false);
  const isAuthenticated = useSelector((state) => state.user.userId);
  const userRole = useSelector((state) => state.user.role);

  useEffect(() => {
    if (
      location.pathname.includes('reset-password') ||
      location.pathname.includes('forgot-password')
    ) {
      setLoadingLocal(false);
    } else {
      if (location.pathname === '/demo') {
        dispatch(
          signInUser({
            email: 'demo@zwick.com',
            password: '',
          }),
        );
      } else if (location.pathname === '/demo-en') {
        dispatch(
          signInUser({
            email: 'demo-website-en@companycompanions.com',
            password: '',
          }),
        );
      } else {
        if (!isAuthenticated || isAuthenticated === undefined) {
          dispatch(refreshToken())
            .then((res) => {
              setLoadingLocal(false);
            })
            .catch((err) => {
              setLoadingLocal(false);
              dispatch(logoutUser());
            });
        }
      }
    }
  }, [isAuthenticated]);

  if (loadingLocal) {
    return null;
  } else {
    return <Fragment>{children}</Fragment>;
  }
};

export default Auth;
