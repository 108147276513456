import React from "react";
import {RichTextEditor} from "matx";
import _ from "lodash";

export const formatFaqData = (data) => {
  const clonedData = _.map(data, (item) => {
    item = _.pick(item, validFaqAttributes);
    return item;
  });
  return clonedData;
};

export const validFaqAttributes = ["id", "title", "description"];

export const getFaqColumns = () => {
  return [
    {
      title: "Title",
      field: "title",
      validate: (rowData) =>
        rowData.title === "" ? "Title cannot be empty" : "",
      sortable: true,
      width: 250,
    },
    {
      title: "Description",
      field: "description",
      validate: (rowData) =>
        rowData.description === "" ? "Description cannot be empty" : "",
      sortable: false,
      editComponent: (props) => {
        return (
          <RichTextEditor
            content={props.value || ""}
            handleContentChange={(value) => {
              props.onChange(value);
            }}
            placeholder="insert description here..."
          />
        );
      },
      render: (rowData) => (
        <div
          dangerouslySetInnerHTML={{
            __html: rowData.description,
          }}></div>
      ),
    },
  ];
};
