import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import prefService from 'services/PrefService';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { ChromePicker } from 'react-color';

import { setLoading } from '../../redux/actions/LoginActions';

const styles = (theme) => ({
  wrapper: {
    position: 'relative',
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

const Preferences = ({ getData, prefData }) => {
  // console.log(props)

  console.log(prefData);

  const classes = useStyles();

  const [newLogoPreview, setNewLogoPreview] = useState(null);
  const [colorSchemeSet, setColorSchemeSet] = useState({
    color_scheme_eb: prefData?.color_scheme_eb
      ? prefData?.color_scheme_eb
      : '#00FF00',
    color_scheme_coc: prefData?.color_scheme_coc
      ? prefData?.color_scheme_coc
      : '#00FF00',
    color_scheme_ct: prefData?.color_scheme_ct
      ? prefData?.color_scheme_ct
      : '#00FF00',
    color_scheme_et: prefData?.color_scheme_et
      ? prefData?.color_scheme_et
      : '#00FF00',
    color_scheme_eg: prefData?.color_scheme_eg
      ? prefData?.color_scheme_eg
      : '#00FF00',
    color_scheme_cct: prefData?.color_scheme_cct
      ? prefData?.color_scheme_cct
      : '#00FF00',
  });

  const [companyEmail, setCompanyEmail] = useState(
    prefData && prefData.email
      ? prefData.email === 'null'
        ? ''
        : prefData.email
      : '',
  );

  console.log(prefData.email);

  const [currentColorCat, setCurrentColorCat] = useState(null);

  const [tagline, setTagline] = useState(
    prefData && prefData.tagline
      ? prefData.tagline === 'null'
        ? ''
        : prefData.tagline
      : '',
  );

  const updatePrefs = () => {
    let form = new FormData();

    form.append('logo', newLogoPreview ? newLogoPreview : null);
    form.append('tagline', tagline ? tagline : '');
    form.append('color_scheme_eb', colorSchemeSet.color_scheme_eb);
    form.append('color_scheme_coc', colorSchemeSet.color_scheme_coc);
    form.append('color_scheme_ct', colorSchemeSet.color_scheme_ct);
    form.append('color_scheme_et', colorSchemeSet.color_scheme_et);
    form.append('color_scheme_eg', colorSchemeSet.color_scheme_eg);
    form.append('color_scheme_cct', colorSchemeSet.color_scheme_cct);
    form.append('email', companyEmail ? companyEmail : '');

    prefService.update(form).then((response) => {
      response && getData();
    });
  };

  const onSubmit = (event) => {
    if (event && event.preventDefault) event.preventDefault();

    updatePrefs();
  };

  return (
    <>
      <Container component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <form
            onSubmit={onSubmit}
            className={classes.form}
            noValidate
          >
            <Box
              width={1}
              display="flex"
              alignItems={'center'}
              flexDirection={'column'}
            >
              <div className="mt-10 sm:mt-0 w-full">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1 mt-5">
                    <div className="px-4 sm:px-0">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Colors choice
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">
                        Click the labels to select primary colors for
                        your Dynamogram. You can either input your own
                        hex code or use the color picker
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <div className="shadow overflow-hidden sm:rounded-md">
                      <div className="flex px-4 py-5 bg-white space-y-6 sm:p-6 justify-around">
                        <fieldset>
                          <div className="mt-4 space-y-4">
                            <div className="flex items-start">
                              <div className="flex flex-col width  justify-center">
                                <div className="flex items-center p-2">
                                  <div
                                    onClick={() =>
                                      setCurrentColorCat('eb')
                                    }
                                    className={`w-10 h-10 rounded-full bg-[${
                                      colorSchemeSet.color_scheme_eb
                                    }] ${
                                      currentColorCat === 'eb' &&
                                      'border-2 border-black'
                                    }`}
                                  ></div>
                                  <div className="px-2">
                                    Executive Board
                                  </div>
                                </div>
                                <div className="flex items-center p-2">
                                  <div
                                    onClick={() =>
                                      setCurrentColorCat('coc')
                                    }
                                    className={`w-10 h-10 rounded-full bg-[${
                                      colorSchemeSet.color_scheme_coc
                                    }] ${
                                      currentColorCat === 'coc' &&
                                      'border-2 border-black'
                                    }`}
                                  ></div>
                                  <div className="px-2">
                                    Center of Competence
                                  </div>
                                </div>
                                <div className="flex items-center p-2">
                                  <div
                                    onClick={() =>
                                      setCurrentColorCat('ct')
                                    }
                                    className={`w-10 h-10 rounded-full bg-[${
                                      colorSchemeSet.color_scheme_ct
                                    }] ${
                                      currentColorCat === 'ct' &&
                                      'border-2 border-black'
                                    }`}
                                  ></div>
                                  <div className="px-2">
                                    Competence Team
                                  </div>
                                </div>
                                <div className="flex items-center p-2">
                                  <div
                                    onClick={() =>
                                      setCurrentColorCat('et')
                                    }
                                    className={`w-10 h-10 rounded-full bg-[${
                                      colorSchemeSet.color_scheme_et
                                    }] ${
                                      currentColorCat === 'et' &&
                                      'border-2 border-black'
                                    }`}
                                  ></div>
                                  <div className="px-2">
                                    Expert Team
                                  </div>
                                </div>
                                <div className="flex items-center p-2">
                                  <div
                                    onClick={() =>
                                      setCurrentColorCat('eg')
                                    }
                                    className={`w-10 h-10 rounded-full bg-[${
                                      colorSchemeSet.color_scheme_eg
                                    }] ${
                                      currentColorCat === 'eg' &&
                                      'border-2 border-black'
                                    }`}
                                  ></div>
                                  <div className="px-2">
                                    Expert / Expert Group
                                  </div>
                                </div>
                                <div className="flex items-center p-2">
                                  <div
                                    onClick={() =>
                                      setCurrentColorCat('cct')
                                    }
                                    className={`w-10 h-10 rounded-full bg-[${
                                      colorSchemeSet.color_scheme_cct
                                    }] ${
                                      currentColorCat === 'cct' &&
                                      'border-2 border-black'
                                    }`}
                                  ></div>
                                  <div className="px-2">
                                    Cross-Competence Team
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset className="mt-0">
                          <div className="space-y-4">
                            <Typography
                              variant="h6"
                              className={classes.title}
                            >
                              Choose Your Colors
                            </Typography>

                            <ChromePicker
                              className={`${
                                currentColorCat === null &&
                                'cursor-not-allowed pointer-events-none opacity-25'
                              }`}
                              disableAlpha
                              color={
                                currentColorCat &&
                                colorSchemeSet[
                                  `color_scheme_${currentColorCat}`
                                ]
                                  ? colorSchemeSet[
                                      `color_scheme_${currentColorCat}`
                                    ]
                                  : '#ffffff'
                              }
                              onChange={(color) =>
                                setColorSchemeSet((prior) => ({
                                  ...prior,
                                  [`color_scheme_${currentColorCat}`]:
                                    color.hex,
                                }))
                              }
                              // width={300}
                            />
                          </div>
                        </fieldset>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <Button
                          type="submit"
                          width={'200px'}
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          Update Colors
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-10 sm:mt-0 w-full">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1 mt-5">
                    <div className="px-4 sm:px-0">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Global settings
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">
                        Here you can select the base logo for the
                        organization and update the dynamic tagline.
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <div className="shadow overflow-hidden sm:rounded-md">
                      <div className="flex px-4 py-5 bg-white space-y-6 sm:p-6 justify-around">
                        <fieldset className="mt-0 w-full">
                          <div className="text-gray-700 py-4 text-center">
                            <div
                              style={{
                                border: '2px dashed #80808069',
                                borderRadius: '30px',
                              }}
                              className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md outline-none focus:outline-none"
                            >
                              <div className="space-y-1 text-center">
                                {newLogoPreview ? (
                                  <img
                                    style={{ paddingBottom: '5px' }}
                                    src={URL.createObjectURL(
                                      newLogoPreview,
                                    )}
                                    alt="company logo"
                                  />
                                ) : (
                                  <svg
                                    className="mx-auto h-12 w-12 text-gray-400"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 48 48"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                                <div className="flex justify-center text-gray-600">
                                  <label
                                    htmlFor="file-upload"
                                    className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                  >
                                    <span>
                                      Click to Upload an image
                                    </span>
                                    <input
                                      onChange={(event) => {
                                        event.target.files[0] &&
                                          setNewLogoPreview(
                                            event.target.files[0],
                                          );
                                        // formErrorHandling &&
                                        //   setFormErrorHandling((prevState) => ({
                                        //     errors: {
                                        //       ...prevState.errors,
                                        //       file: null,
                                        //     },
                                        //   }));
                                      }}
                                      id="file-upload"
                                      name="file-upload"
                                      type="file"
                                      className="sr-only"
                                      accept=".gif,.jpg,.jpeg,.png,.bmp,.svg"
                                    />
                                  </label>

                                  {/* <p className="pl-1">or drag and drop</p> */}
                                </div>
                                <p className="text-gray-500">
                                  (PNG, JPG, GIF, BMP, SVG)
                                </p>
                              </div>
                            </div>
                            {/* {formErrorHandling && formErrorHandling.errors.file && (
                              <span className="text-red-700" id="filehelp">
                                {formErrorHandling.errors.file[0]}
                              </span>
                            )} */}
                          </div>
                          <div className="py-4">
                            <Box
                              width={1}
                              display="flex"
                              alignItems={'center'}
                              flexDirection={'column'}
                            >
                              <TextField
                                fullWidth
                                onChange={(e) =>
                                  setTagline(e.target.value)
                                }
                                value={tagline}
                                id="tagline"
                                label={
                                  tagline
                                    ? 'Update Your tagline'
                                    : 'Enter a tagline'
                                }
                              />
                            </Box>
                          </div>
                        </fieldset>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <Button
                          type="submit"
                          width={'200px'}
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          Update Logo and Tagline
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-10 sm:mt-0 w-full">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1 mt-5">
                    <div className="px-4 sm:px-0">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Email template choice
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">
                        This email will be used to inform you of
                        change requests to the Dynamogram
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 md:mt-0 md:col-span-2">
                    {/* <form action="#" method="POST"> */}
                    <div className="shadow overflow-hidden sm:rounded-md">
                      <div className="flex px-4 py-5 bg-white space-y-6 sm:p-6 justify-around">
                        <fieldset className="mt-0 w-full">
                          <div>
                            <div className="mt-1">
                              <TextField
                                fullWidth
                                type="email"
                                name="email"
                                id="email"
                                scribedby="email-description"
                                onChange={(e) =>
                                  setCompanyEmail(e.target.value)
                                }
                                value={companyEmail}
                                label={
                                  companyEmail
                                    ? 'Update Your email'
                                    : 'Enter a email'
                                }
                              />
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <Button
                          type="submit"
                          width={'200px'}
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          Update Email template
                        </Button>
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </Box>
          </form>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  setLoading: PropTypes.func.isRequired,
});
export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, { setLoading })(Preferences)),
);
