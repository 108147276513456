import {authRoles} from "auth/authRoles";
import D3ChartComponent from "./D3ChartComponent";

const d3ChartRoutes = [
  {
    path: "/d3-chart",
    component: D3ChartComponent,
    auth: authRoles.admin,
  },
];

export default d3ChartRoutes;
