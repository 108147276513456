export const MenuItemsEnum = {
  HOMEPAGE: "homepage",
  DATA: "data",
  DATA_TABLE: "data-table",
};

export const ColorCodes = {
  PRIMARY: "#228B22",
  DARK: "#000000",
  WHITE: "#FFF",
  SECONDARY: "#222A45",
};

export const FieldTypeEnum = {
  CONNECTIONS: "connections",
  CHILDREN: "children",
  TEAM: "team",
};
