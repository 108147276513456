import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import MaterialTable from 'material-table';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  adInterimLookups,
  getEditableComponent,
  getOptions,
  roleLookups,
  useStyles,
} from '../../helpers/DataTable';
import { getNodeColumns } from '../../helpers/NodeDataTable';
import {
  getPeopleColumnsForNodeTable,
  getTeamColumnsForNodeTable,
  getPeopleRoleForNode,
  getPeopleTeamForNode,
  getPeopleIdLookup,
} from '../../helpers/PeopleDataTable';
import { tableIcons } from '../../components/ReusableComponents/DatatableIconsComponent';
import {
  ChildTableComponent,
  ConnectionsTableComponent,
} from '../../components';
import { LinksDatatableComponent } from '.';
import { replaceEditedChild } from '../../helpers/NodeDataTable';
import Select from 'react-select';
import { useSelector } from 'react-redux';

export default function NodesDatatableComponent(props) {
  const {
    data,
    allNodes,
    people,
    nodeTypes,
    isLoading,
    handleNodesChange,
    handleNodeRefresh,
    handleRoleChange,
    handleTeamChangeForNodes,
    handleNodeSearch,
    handleConnectionsChange,
    triggerUpdate,
    handleLinksChange,
    handleLinkRefresh,
    links,
  } = props;

  const classes = useStyles();
  const peopleIdLookups = getPeopleIdLookup(people);
  const columns = getNodeColumns(data, nodeTypes);
  const userRole = useSelector((state) => state.user.role);

  const handleChildChange = (childData, id, operation) => {
    let nodes = [...data];

    if (operation === 'edit') {
      nodes = replaceEditedChild(nodes, id, childData);
    }

    handleNodesChange(nodes, id, operation, childData);
  };

  const formatPeople = (people) => {
    const options = [];
    for (const key in people) {
      const newOption = {
        value: key,
        label: people[key],
      };
      options.push(newOption);
    }
    return options;
  };

  const tableRef = useRef();
  const openedPanels = useRef(new Set());
  const oldFunction = useRef();

  useEffect(() => {
    if (!oldFunction.current) {
      oldFunction.current = tableRef.current?.onToggleDetailPanel;
    }

    if (
      oldFunction.current === tableRef.current?.onToggleDetailPanel
    ) {
      tableRef.current.onToggleDetailPanel = (path, render) => {
        oldFunction.current(path, render);

        openedPanels.current.clear();

        return tableRef.current.props.data?.forEach((item) => {
          if (item.tableData.showDetailPanel) {
            openedPanels.current.add(item.id);
          }
        });
      };
    }
  }, [tableRef]);

  return (
    <main className={classes.content}>
      <Container className={classes.container}>
        {/* JSON viewer conatainer*/}
        <Grid container className={classes.viewerSettings}>
          <Grid item xs={12} md={12} lg={12}>
            <div style={{ maxWidth: '100%' }}>
              <MaterialTable
                tableRef={tableRef}
                icons={tableIcons}
                isLoading={isLoading}
                detailPanel={(rowData) => {
                  // console.log(rowData);
                  const childrenTableData = rowData.children || [];
                  const peopleData = getPeopleRoleForNode(
                    people,
                    rowData.id,
                  );
                  const teamData = getPeopleTeamForNode(
                    people,
                    rowData.id,
                  );

                  return (
                    <div>
                      {/** People */}
                      <ChildTableComponent
                        columns={[
                          {
                            title: 'Person',
                            field: 'person_id',
                            validate: (rowData) => {
                              return {
                                isValid: !rowData.person_id
                                  ? false
                                  : true,
                                helperText: !rowData.person_id
                                  ? 'Person cannot be empty'
                                  : '',
                              };
                            },
                            sortable: true,
                            editable: 'onAdd',
                            lookup: peopleIdLookups,
                            editComponent: (props) => (
                              <Select
                                options={formatPeople(
                                  peopleIdLookups,
                                )}
                                value={props.value}
                                placeholder="Select a person"
                                onChange={(value) =>
                                  props.onChange(value)
                                }
                                menuPortalTarget={document.body}
                                menuPosition={'absolute'}
                                menuShouldBlockScroll={true}
                              />
                            ),
                          },
                          {
                            title: 'Capacity',
                            field: 'capacity',
                            // validate: (rowData) => {
                            //   return {
                            //     isValid: !rowData.capacity ? false : true,
                            //     helperText: !rowData.capacity ? "Capacity cannot be empty" : ""
                            //   }
                            // },
                            sortable: true,
                            // hidden: true
                          },
                          {
                            title: 'Ad Interim',
                            field: 'ad_interim',
                            validate: (rowData) => {
                              return {
                                isValid:
                                  rowData.ad_interim == 0 ||
                                  rowData.ad_interim == 1
                                    ? true
                                    : false,
                                helperText:
                                  rowData.ad_interim === ''
                                    ? 'Ad Interim cannot be empty'
                                    : '',
                              };
                            },
                            sortable: true,
                            lookup: adInterimLookups,
                            initialEditValue: 'false',
                          },
                          {
                            title: 'Role',
                            field: 'role',
                            sortable: true,
                            validate: (rowData) => {
                              return {
                                isValid: !rowData.role ? false : true,
                                helperText: !rowData.role
                                  ? 'Role cannot be empty'
                                  : '',
                              };
                            },
                            lookup: roleLookups,
                          },
                        ]}
                        data={peopleData}
                        parentRecord={rowData}
                        tableHeader={`Head of ${rowData.name}`}
                        tableIcons={tableIcons}
                        handleChange={handleRoleChange}
                      />

                      {/** Teams */}
                      <ChildTableComponent
                        columns={[
                          {
                            title: 'Person',
                            field: 'person_id',
                            validate: (rowData) => {
                              return {
                                isValid: !rowData.person_id
                                  ? false
                                  : true,
                                helperText: !rowData.person_id
                                  ? 'Person cannot be empty'
                                  : '',
                              };
                            },
                            sortable: true,
                            editable: 'onAdd',
                            lookup: peopleIdLookups,
                            editComponent: (props) => (
                              <Select
                                options={formatPeople(
                                  peopleIdLookups,
                                )}
                                value={props.value}
                                placeholder="Select a person"
                                onChange={(value) =>
                                  props.onChange(value)
                                }
                                menuPortalTarget={document.body}
                                menuPosition={'absolute'}
                                menuShouldBlockScroll={true}
                              />
                            ),
                          },
                          {
                            title: 'Capacity',
                            field: 'capacity',
                            // validate: (rowData) => {
                            //   return {
                            //     isValid: !rowData.capacity ? false : true,
                            //     helperText: !rowData.capacity ? "Capacity cannot be empty" : ""
                            //   }
                            // },
                            sortable: true,
                            // hidden: true
                          },
                          {
                            title: 'Ad Interim',
                            field: 'ad_interim',
                            validate: (rowData) => {
                              return {
                                isValid:
                                  rowData.ad_interim == 0 ||
                                  rowData.ad_interim == 1
                                    ? true
                                    : false,
                                helperText:
                                  rowData.ad_interim === ''
                                    ? 'Ad Interim cannot be empty'
                                    : '',
                              };
                            },
                            sortable: true,
                            lookup: adInterimLookups,
                            initialEditValue: 'false',
                          },
                          {
                            title: 'Role',
                            field: 'role',
                            sortable: true,
                            validate: (rowData) => {
                              return {
                                isValid: !rowData.role ? false : true,
                                helperText: !rowData.role
                                  ? 'Role cannot be empty'
                                  : '',
                              };
                            },
                          },
                        ]}
                        data={teamData}
                        parentRecord={rowData}
                        tableHeader={`People in ${rowData.name}`}
                        tableIcons={tableIcons}
                        handleChange={handleTeamChangeForNodes}
                      />

                      {/** Connections*/}
                      {(rowData.type == 3 ||
                        rowData.type == 1 ||
                        rowData.type == 4) && (
                        <>
                          <ConnectionsTableComponent
                            // nodes={data}
                            data={data}
                            // allNodes={allNodes}
                            rowData={rowData}
                            handleConnectionsChange={
                              handleConnectionsChange
                            }

                            //to config later to merge entire LinksDataTable into node for dynamic id
                          />
                          {/* <LinksDatatableComponent
                            links={links}
                            nodes={data}
                            isLoading={isLoading}
                            handleLinksChange={handleLinksChange}
                            handleLinkRefresh={handleLinkRefresh}
                          /> */}
                        </>
                      )}

                      {/** Children */}
                      {rowData.type != 3 && (
                        <ChildTableComponent
                          // isLoading={isLoading}
                          columns={getNodeColumns(
                            rowData.children || [],
                            nodeTypes,
                          )}
                          data={childrenTableData}
                          parentRecord={rowData}
                          tableHeader={`Teams for ${rowData.name}`}
                          tableIcons={tableIcons}
                          detailPanel={(childRowData) => {
                            {
                              /** Grand Children */
                            }
                            const grandChildrenTableData =
                              childRowData.children || [];

                            const peopleData = getPeopleRoleForNode(
                              people,
                              childRowData.id,
                            );

                            const teamData = getPeopleTeamForNode(
                              people,
                              childRowData.id,
                            );
                            return (
                              <div>
                                {/** People */}
                                <ChildTableComponent
                                  // isLoading={isLoading}
                                  columns={[
                                    {
                                      title: 'Person',
                                      field: 'person_id',
                                      validate: (rowData) => {
                                        return {
                                          isValid: !rowData.person_id
                                            ? false
                                            : true,
                                          helperText:
                                            !rowData.person_id
                                              ? 'Person cannot be empty'
                                              : '',
                                        };
                                      },
                                      sortable: true,
                                      editable: 'onAdd',
                                      lookup: peopleIdLookups,
                                      editComponent: (props) => (
                                        <Select
                                          options={formatPeople(
                                            peopleIdLookups,
                                          )}
                                          value={props.value}
                                          placeholder="Select a person"
                                          onChange={(value) =>
                                            props.onChange(value)
                                          }
                                          menuPortalTarget={
                                            document.body
                                          }
                                          menuPosition={'absolute'}
                                          menuShouldBlockScroll={true}
                                        />
                                      ),
                                    },
                                    {
                                      title: 'Capacity',
                                      field: 'capacity',
                                      // validate: (rowData) => {
                                      //   return {
                                      //     isValid: !rowData.capacity ? false : true,
                                      //     helperText: !rowData.capacity ? "Capacity cannot be empty" : ""
                                      //   }
                                      // },
                                      sortable: true,
                                      // hidden: true
                                    },
                                    {
                                      title: 'Ad Interim',
                                      field: 'ad_interim',
                                      validate: (rowData) => {
                                        return {
                                          isValid:
                                            rowData.ad_interim == 0 ||
                                            rowData.ad_interim == 1
                                              ? true
                                              : false,
                                          helperText:
                                            rowData.ad_interim === ''
                                              ? 'Ad Interim cannot be empty'
                                              : '',
                                        };
                                      },
                                      sortable: true,
                                      lookup: adInterimLookups,
                                      initialEditValue: 'false',
                                    },
                                    {
                                      title: 'Role',
                                      field: 'role',
                                      sortable: true,
                                      validate: (rowData) => {
                                        return {
                                          isValid: !rowData.role
                                            ? false
                                            : true,
                                          helperText: !rowData.role
                                            ? 'Role cannot be empty'
                                            : '',
                                        };
                                      },
                                      lookup: roleLookups,
                                    },
                                  ]}
                                  data={peopleData}
                                  parentRecord={childRowData}
                                  tableHeader={`Head of ${childRowData.name}`}
                                  tableIcons={tableIcons}
                                  handleChange={handleRoleChange}
                                />
                                {/** Teams */}
                                <ChildTableComponent
                                  // isLoading={isLoading}
                                  columns={[
                                    {
                                      title: 'Person',
                                      field: 'person_id',
                                      validate: (rowData) => {
                                        return {
                                          isValid: !rowData.person_id
                                            ? false
                                            : true,
                                          helperText:
                                            !rowData.person_id
                                              ? 'Person cannot be empty'
                                              : '',
                                        };
                                      },
                                      sortable: true,
                                      editable: 'onAdd',
                                      lookup: peopleIdLookups,
                                      editComponent: (props) => (
                                        <Select
                                          options={formatPeople(
                                            peopleIdLookups,
                                          )}
                                          value={props.value}
                                          placeholder="Select a person"
                                          onChange={(value) =>
                                            props.onChange(value)
                                          }
                                          menuPortalTarget={
                                            document.body
                                          }
                                          menuPosition={'absolute'}
                                          menuShouldBlockScroll={true}
                                        />
                                      ),
                                    },
                                    {
                                      title: 'Capacity',
                                      field: 'capacity',
                                      // validate: (rowData) => {
                                      //   return {
                                      //     isValid: !rowData.capacity ? false : true,
                                      //     helperText: !rowData.capacity ? "Capacity cannot be empty" : ""
                                      //   }
                                      // },
                                      sortable: true,
                                      // hidden: true
                                    },
                                    {
                                      title: 'Ad Interim',
                                      field: 'ad_interim',
                                      validate: (rowData) => {
                                        return {
                                          isValid:
                                            rowData.ad_interim == 0 ||
                                            rowData.ad_interim == 1
                                              ? true
                                              : false,
                                          helperText:
                                            rowData.ad_interim === ''
                                              ? 'Ad Interim cannot be empty'
                                              : '',
                                        };
                                      },
                                      sortable: true,
                                      lookup: adInterimLookups,
                                      initialEditValue: 'false',
                                    },
                                    {
                                      title: 'Role',
                                      field: 'role',
                                      sortable: true,
                                      validate: (rowData) => {
                                        return {
                                          isValid: !rowData.role
                                            ? false
                                            : true,
                                          helperText: !rowData.role
                                            ? 'Role cannot be empty'
                                            : '',
                                        };
                                      },
                                    },
                                  ]}
                                  data={teamData}
                                  parentRecord={childRowData}
                                  tableHeader={`People in ${childRowData.name}`}
                                  tableIcons={tableIcons}
                                  handleChange={
                                    handleTeamChangeForNodes
                                  }
                                />
                                <ChildTableComponent
                                  // isLoading={isLoading}
                                  columns={getNodeColumns(
                                    childRowData.children || [],
                                    nodeTypes,
                                  )}
                                  data={grandChildrenTableData}
                                  parentRecord={childRowData}
                                  grandParentRecord={rowData}
                                  tableHeader={`Teams for ${childRowData.name}`}
                                  tableIcons={tableIcons}
                                  detailPanel={(
                                    grandChildRowData,
                                  ) => {
                                    {
                                      /** Great Grand Children */
                                    }

                                    const peopleData =
                                      getPeopleRoleForNode(
                                        people,
                                        grandChildRowData.id,
                                      );

                                    const teamData =
                                      getPeopleTeamForNode(
                                        people,
                                        grandChildRowData.id,
                                      );
                                    return (
                                      <div>
                                        {/** People */}
                                        <ChildTableComponent
                                          columns={[
                                            {
                                              title: 'Person',
                                              field: 'person_id',
                                              validate: (rowData) => {
                                                return {
                                                  isValid:
                                                    !rowData.person_id
                                                      ? false
                                                      : true,
                                                  helperText:
                                                    !rowData.person_id
                                                      ? 'Person cannot be empty'
                                                      : '',
                                                };
                                              },
                                              sortable: true,
                                              editable: 'onAdd',
                                              lookup: peopleIdLookups,
                                              editComponent: (
                                                props,
                                              ) => (
                                                <Select
                                                  options={formatPeople(
                                                    peopleIdLookups,
                                                  )}
                                                  value={props.value}
                                                  placeholder="Select a person"
                                                  onChange={(value) =>
                                                    props.onChange(
                                                      value,
                                                    )
                                                  }
                                                  menuPortalTarget={
                                                    document.body
                                                  }
                                                  menuPosition={
                                                    'absolute'
                                                  }
                                                  menuShouldBlockScroll={
                                                    true
                                                  }
                                                />
                                              ),
                                            },
                                            {
                                              title: 'Capacity',
                                              field: 'capacity',
                                              // validate: (rowData) => {
                                              //   return {
                                              //     isValid: !rowData.capacity ? false : true,
                                              //     helperText: !rowData.capacity ? "Capacity cannot be empty" : ""
                                              //   }
                                              // },
                                              // sortable: true,
                                              // hidden: true
                                            },
                                            {
                                              title: 'Ad Interim',
                                              field: 'ad_interim',
                                              validate: (rowData) => {
                                                return {
                                                  isValid:
                                                    rowData.ad_interim ==
                                                      0 ||
                                                    rowData.ad_interim ==
                                                      1
                                                      ? true
                                                      : false,
                                                  helperText:
                                                    rowData.ad_interim ===
                                                    ''
                                                      ? 'Ad Interim cannot be empty'
                                                      : '',
                                                };
                                              },
                                              sortable: true,
                                              lookup:
                                                adInterimLookups,
                                              initialEditValue:
                                                'false',
                                            },
                                            {
                                              title: 'Role',
                                              field: 'role',
                                              sortable: true,
                                              validate: (rowData) => {
                                                return {
                                                  isValid:
                                                    !rowData.role
                                                      ? false
                                                      : true,
                                                  helperText:
                                                    !rowData.role
                                                      ? 'Role cannot be empty'
                                                      : '',
                                                };
                                              },
                                              lookup: roleLookups,
                                            },
                                          ]}
                                          data={peopleData}
                                          parentRecord={
                                            grandChildRowData
                                          }
                                          tableHeader={`Head of ${grandChildRowData.name}`}
                                          tableIcons={tableIcons}
                                          handleChange={
                                            handleRoleChange
                                          }
                                        />

                                        {/** Teams */}
                                        <ChildTableComponent
                                          columns={[
                                            {
                                              title: 'Person',
                                              field: 'person_id',
                                              validate: (rowData) => {
                                                return {
                                                  isValid:
                                                    !rowData.person_id
                                                      ? false
                                                      : true,
                                                  helperText:
                                                    !rowData.person_id
                                                      ? 'Person cannot be empty'
                                                      : '',
                                                };
                                              },
                                              sortable: true,
                                              editable: 'onAdd',
                                              lookup: peopleIdLookups,
                                              editComponent: (
                                                props,
                                              ) => (
                                                <Select
                                                  options={formatPeople(
                                                    peopleIdLookups,
                                                  )}
                                                  value={props.value}
                                                  placeholder="Select a person"
                                                  onChange={(value) =>
                                                    props.onChange(
                                                      value,
                                                    )
                                                  }
                                                  menuPortalTarget={
                                                    document.body
                                                  }
                                                  menuPosition={
                                                    'absolute'
                                                  }
                                                  menuShouldBlockScroll={
                                                    true
                                                  }
                                                />
                                              ),
                                            },
                                            {
                                              title: 'Capacity',
                                              field: 'capacity',
                                              // validate: (rowData) => {
                                              //   return {
                                              //     isValid: !rowData.capacity ? false : true,
                                              //     helperText: !rowData.capacity ? "Capacity cannot be empty" : ""
                                              //   }
                                              // },
                                              // sortable: true,
                                              // hidden: true
                                            },
                                            {
                                              title: 'Ad Interim',
                                              field: 'ad_interim',
                                              validate: (rowData) => {
                                                return {
                                                  isValid:
                                                    rowData.ad_interim ==
                                                      0 ||
                                                    rowData.ad_interim ==
                                                      1
                                                      ? true
                                                      : false,
                                                  helperText:
                                                    rowData.ad_interim ===
                                                    ''
                                                      ? 'Ad Interim cannot be empty'
                                                      : '',
                                                };
                                              },
                                              sortable: true,
                                              lookup:
                                                adInterimLookups,
                                              initialEditValue:
                                                'false',
                                            },
                                            {
                                              title: 'Role',
                                              field: 'role',
                                              sortable: true,
                                              validate: (rowData) => {
                                                return {
                                                  isValid:
                                                    !rowData.role
                                                      ? false
                                                      : true,
                                                  helperText:
                                                    !rowData.role
                                                      ? 'Role cannot be empty'
                                                      : '',
                                                };
                                              },
                                            },
                                          ]}
                                          data={teamData}
                                          parentRecord={
                                            grandChildRowData
                                          }
                                          tableHeader={`People in ${grandChildRowData.name}`}
                                          tableIcons={tableIcons}
                                          handleChange={
                                            handleTeamChangeForNodes
                                          }
                                        />
                                        {/** Sub-circles */}
                                        <ChildTableComponent
                                          columns={getNodeColumns(
                                            grandChildRowData.children ||
                                              [],
                                            nodeTypes,
                                          )}
                                          data={
                                            grandChildRowData.children ||
                                            []
                                          }
                                          parentRecord={
                                            grandChildRowData
                                          }
                                          grandParentRecord={
                                            childRowData
                                          }
                                          greatGrandParentRecord={
                                            rowData
                                          }
                                          tableHeader={`Teams for ${grandChildRowData.name}`}
                                          tableIcons={tableIcons}
                                          detailPanel={(
                                            greatGrandChildRowData,
                                          ) => {
                                            {
                                              /** Great Great Grand Children */
                                            }

                                            const peopleData =
                                              getPeopleRoleForNode(
                                                people,
                                                greatGrandChildRowData.id,
                                              );

                                            const teamData =
                                              getPeopleTeamForNode(
                                                people,
                                                greatGrandChildRowData.id,
                                              );
                                            return (
                                              <div>
                                                {/** People */}
                                                <ChildTableComponent
                                                  columns={[
                                                    {
                                                      title: 'Person',
                                                      field:
                                                        'person_id',
                                                      validate: (
                                                        rowData,
                                                      ) => {
                                                        return {
                                                          isValid:
                                                            !rowData.person_id
                                                              ? false
                                                              : true,
                                                          helperText:
                                                            !rowData.person_id
                                                              ? 'Person cannot be empty'
                                                              : '',
                                                        };
                                                      },
                                                      sortable: true,
                                                      editable:
                                                        'onAdd',
                                                      lookup:
                                                        peopleIdLookups,
                                                      editComponent: (
                                                        props,
                                                      ) => (
                                                        <Select
                                                          options={formatPeople(
                                                            peopleIdLookups,
                                                          )}
                                                          value={
                                                            props.value
                                                          }
                                                          placeholder="Select a person"
                                                          onChange={(
                                                            value,
                                                          ) =>
                                                            props.onChange(
                                                              value,
                                                            )
                                                          }
                                                          menuPortalTarget={
                                                            document.body
                                                          }
                                                          menuPosition={
                                                            'absolute'
                                                          }
                                                          menuShouldBlockScroll={
                                                            true
                                                          }
                                                        />
                                                      ),
                                                    },
                                                    {
                                                      title:
                                                        'Capacity',
                                                      field:
                                                        'capacity',
                                                      // validate: (rowData) => {
                                                      //   return {
                                                      //     isValid: !rowData.capacity ? false : true,
                                                      //     helperText: !rowData.capacity ? "Capacity cannot be empty" : ""
                                                      //   }
                                                      // },
                                                      // sortable: true,
                                                      // hidden: true
                                                    },
                                                    {
                                                      title:
                                                        'Ad Interim',
                                                      field:
                                                        'ad_interim',
                                                      validate: (
                                                        rowData,
                                                      ) => {
                                                        return {
                                                          isValid:
                                                            rowData.ad_interim ==
                                                              0 ||
                                                            rowData.ad_interim ==
                                                              1
                                                              ? true
                                                              : false,
                                                          helperText:
                                                            rowData.ad_interim ===
                                                            ''
                                                              ? 'Ad Interim cannot be empty'
                                                              : '',
                                                        };
                                                      },
                                                      sortable: true,
                                                      lookup:
                                                        adInterimLookups,
                                                      initialEditValue:
                                                        'false',
                                                    },
                                                    {
                                                      title: 'Role',
                                                      field: 'role',
                                                      sortable: true,
                                                      validate: (
                                                        rowData,
                                                      ) => {
                                                        return {
                                                          isValid:
                                                            !rowData.role
                                                              ? false
                                                              : true,
                                                          helperText:
                                                            !rowData.role
                                                              ? 'Role cannot be empty'
                                                              : '',
                                                        };
                                                      },
                                                      lookup:
                                                        roleLookups,
                                                    },
                                                  ]}
                                                  data={peopleData}
                                                  parentRecord={
                                                    greatGrandChildRowData
                                                  }
                                                  tableHeader={`Head of ${greatGrandChildRowData.name}`}
                                                  tableIcons={
                                                    tableIcons
                                                  }
                                                  handleChange={
                                                    handleRoleChange
                                                  }
                                                />

                                                {/** Teams */}
                                                <ChildTableComponent
                                                  columns={[
                                                    {
                                                      title: 'Person',
                                                      field:
                                                        'person_id',
                                                      validate: (
                                                        rowData,
                                                      ) => {
                                                        return {
                                                          isValid:
                                                            !rowData.person_id
                                                              ? false
                                                              : true,
                                                          helperText:
                                                            !rowData.person_id
                                                              ? 'Person cannot be empty'
                                                              : '',
                                                        };
                                                      },
                                                      sortable: true,
                                                      editable:
                                                        'onAdd',
                                                      lookup:
                                                        peopleIdLookups,
                                                      editComponent: (
                                                        props,
                                                      ) => (
                                                        <Select
                                                          options={formatPeople(
                                                            peopleIdLookups,
                                                          )}
                                                          value={
                                                            props.value
                                                          }
                                                          placeholder="Select a person"
                                                          onChange={(
                                                            value,
                                                          ) =>
                                                            props.onChange(
                                                              value,
                                                            )
                                                          }
                                                          menuPortalTarget={
                                                            document.body
                                                          }
                                                          menuPosition={
                                                            'absolute'
                                                          }
                                                          menuShouldBlockScroll={
                                                            true
                                                          }
                                                        />
                                                      ),
                                                    },
                                                    {
                                                      title:
                                                        'Capacity',
                                                      field:
                                                        'capacity',
                                                      // validate: (rowData) => {
                                                      //   return {
                                                      //     isValid: !rowData.capacity ? false : true,
                                                      //     helperText: !rowData.capacity ? "Capacity cannot be empty" : ""
                                                      //   }
                                                      // },
                                                      // sortable: true,
                                                      // hidden: true
                                                    },
                                                    {
                                                      title:
                                                        'Ad Interim',
                                                      field:
                                                        'ad_interim',
                                                      validate: (
                                                        rowData,
                                                      ) => {
                                                        return {
                                                          isValid:
                                                            rowData.ad_interim ==
                                                              0 ||
                                                            rowData.ad_interim ==
                                                              1
                                                              ? true
                                                              : false,
                                                          helperText:
                                                            rowData.ad_interim ===
                                                            ''
                                                              ? 'Ad Interim cannot be empty'
                                                              : '',
                                                        };
                                                      },
                                                      sortable: true,
                                                      lookup:
                                                        adInterimLookups,
                                                      initialEditValue:
                                                        'false',
                                                    },
                                                    {
                                                      title: 'Role',
                                                      field: 'role',
                                                      sortable: true,
                                                      validate: (
                                                        rowData,
                                                      ) => {
                                                        return {
                                                          isValid:
                                                            !rowData.role
                                                              ? false
                                                              : true,
                                                          helperText:
                                                            !rowData.role
                                                              ? 'Role cannot be empty'
                                                              : '',
                                                        };
                                                      },
                                                    },
                                                  ]}
                                                  data={teamData}
                                                  parentRecord={
                                                    greatGrandChildRowData
                                                  }
                                                  tableHeader={`People in ${greatGrandChildRowData.name}`}
                                                  tableIcons={
                                                    tableIcons
                                                  }
                                                  handleChange={
                                                    handleTeamChangeForNodes
                                                  }
                                                />
                                              </div>
                                            );
                                          }}
                                          handleChange={
                                            handleChildChange
                                          }
                                        />
                                      </div>
                                    );
                                  }}
                                  handleChange={handleChildChange}
                                />
                              </div>
                            );
                          }}
                          handleChange={handleChildChange}
                        />
                      )}
                    </div>
                  );
                }}
                columns={columns}
                data={
                  data?.map((d) => ({
                    ...d,
                    tableData: {
                      showDetailPanel: openedPanels.current.has(d.id)
                        ? tableRef.current.props.detailPanel
                        : null,
                    },
                  })) || []
                }
                title={
                  <div className={classes.iconContainer}>
                    <SettingsIcon />
                    <h2 className={classes.headerText}>
                      Center of Competence{' '}
                    </h2>
                  </div>
                }
                options={{ ...getOptions, search: true }}
                onRowClick={(event, rowData, togglePanel) =>
                  togglePanel()
                }
                editable={getEditableComponent(
                  data,
                  handleNodesChange,
                  userRole,
                )}
                actions={[
                  {
                    icon: 'refresh',
                    tooltip: 'Refresh Data',
                    isFreeAction: true,
                    onClick: () => handleNodeRefresh(),
                  },
                ]}
                onSearchChange={(searchTerm) => {
                  handleNodeSearch(searchTerm);
                  // console.log(tableRef.current.DataManager.filteredData.length);
                }}
                localization={{
                  toolbar: {
                    searchPlaceholder: 'Search nodes',
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}
