import axios from 'axios';
import authService from './AuthService';
import storageService from './StorageService';
const LS_KEY_USER = "user";

let originalRequests = []
let isRefreshRequesting = false

axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    const { config } = error
    if(error.response.status === 403){
        if(!isRefreshRequesting){
            isRefreshRequesting = true
            const user = JSON.parse(storageService.get(LS_KEY_USER))
            if(user ){
                authService.getNewToken(user)
                    .then((res) => {
                        originalRequests.forEach((request) => {
                            request(res)
                        })
                    })
                    .catch((err) => {
                        originalRequests.forEach((request) => {
                            request(null)}) 
                    })
                    .finally(() => {
                        isRefreshRequesting = false
                        originalRequests = []
                    })
            }
        }

        return new Promise((resolve, reject) => {
            originalRequests.push((token) => {
              if (token) {
                config.headers.Authorization = 'jwt ' + token
                resolve(axios(config))
              }
              reject(error)
            })
          })
    }
    
    return Promise.reject(error);
  });

export default axios