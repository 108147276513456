import _ from "lodash";
import { adInterimLookups } from "./DataTable";

export const validPeopleAttributes = [
  "id",
  "bio",
  "email",
  "location",
  "name",
  "phone",
  "roles",
  "type",
  "teams"
];

export const getPeopleColumns = () => {
  const columns = [
    {
      title: "Name",
      field: "name",
      validate: (rowData) =>
        rowData.source === "" ? "Name cannot be empty" : "",
      sortable: true,
    },
    {
      title: "Role",
      field: "bio",
      sortable: true,
    },
    {
      title: "Email",
      field: "email",
      validate: (rowData) =>
        rowData.target === "" ? "Email cannot be empty" : "",
      sortable: true,
      hidden: true,
    },
    {
      title: "Country",
      field: "location",
      validate: (rowData) =>
        rowData.target === "" ? "Country cannot be empty" : "",
      sortable: true,
    },
    // {
    //   title: "Type",
    //   field: "type",
    //   validate: (rowData) =>
    //     rowData.target === "" ? "Type cannot be empty" : "",
    //   sortable: true,
    // },
    {
      title: "Phone",
      field: "phone",
      sortable: true,
      hidden: true,
    },
  ];

  return columns;
};

export const getPeopleColumnsForNodeTable = (people) => {
  const peopleIdLookups = getPeopleIdLookup(people);
  const columns = [
    {
      title: "Person",
      field: "person_id",
      validate: (rowData) =>
        rowData.source === "" ? "Person cannot be empty" : "",
      sortable: true,
      editable: "onAdd",
      lookup: peopleIdLookups,
    },
    {
      title: "Capacity",
      field: "capacity",
      validate: (rowData) =>
        rowData.type === "" ? "Capacity cannot be empty" : "",
      sortable: true,
      hidden: true
    },
    {
      title: "Ad Interim",
      field: "ad_interim",
      validate: (rowData) =>
        rowData.target === "" ? "Ad Interim cannot be empty" : "",
      sortable: true,
      lookup: adInterimLookups,
    },
    {
      title: "Role",
      field: "role",
      sortable: true,
    },
  ];
  return columns;
};

export const getTeamColumnsForNodeTable = (people, nodeId) => {
  let lookups = {};
  const clonedPeople =
    people &&
    people.forEach((item) => {
      if (item && item.roles && item.roles.length) {
        const leadRole = item.roles.find(
          (r) =>
            r.role &&
            (r.role.toString().toLowerCase() === "lead" || r.role.toString().toLowerCase() === "head" || r.role.toString().toLowerCase() === "speaker") &&
            r.node_id !== nodeId
        );
        if (leadRole && leadRole.node_id) {
        } else {
          lookups = {
            ...lookups,
            [item.id]: item.name,
          };
        }
      } else {
        lookups = {
          ...lookups,
          [item.id]: item.name,
        };
      }
    });
  const peopleIdLookups = lookups;
  const columns = [
    {
      title: "Person",
      field: "person_id",
      validate: (rowData) =>
        rowData.source === "" ? "Person cannot be empty" : "",
      sortable: true,
      editable: "onAdd",
      lookup: peopleIdLookups,
    },
    {
      title: "Capacity",
      field: "capacity",
      validate: (rowData) =>
        rowData.type === "" ? "Capacity cannot be empty" : "",
      sortable: true,
      hidden: true,
    },
    {
      title: "Ad Interim",
      field: "ad_interim",
      validate: (rowData) =>
        rowData.target === "" ? "Ad Interim cannot be empty" : "",
      sortable: true,
      lookup: adInterimLookups,
      hidden: true
    },
    {
      title: "Role",
      field: "role",
      sortable: true,
    },
  ];
  return columns;
};

export const getPeopleForNode = (people, nodeId) => {
  const peopleList = _.filter(people, (item) => {
    const filteredRoles =
      item &&
      item.roles &&
      item.roles
        .filter((r) => r.node_id === nodeId)
        .filter((item) => item !== null && item !== undefined);
    if (filteredRoles && filteredRoles.length) {
      item.capacity = filteredRoles[0].capacity;
      item.role = filteredRoles[0].role;
      return item;
    }
  }).filter((item) => item !== null && item !== undefined);
  return peopleList;
};

export const getPeopleRoleForNode = (people, nodeId) => {
  let peopleRoleList = [];
  _.forEach(people, (item) => {
    if (item && item.roles) {
      let filteredItems = item.roles
        .filter((r) => r.nodeId === nodeId)
        .filter((item) => item !== null && item !== undefined);
      if (filteredItems && filteredItems.length) {
        _.forEach(filteredItems, (fItem) => {
          fItem.person_id = item.id;
          fItem.people_name = item.name;
          fItem.location = item.location || "";
          peopleRoleList.push(fItem);
        });
        // filteredItems[0].people_name = item.name;
        // filteredItems[0].person_id = item.id;
        // peopleRoleList.push(...filteredItems);
      }
    }
  });

  peopleRoleList = peopleRoleList.filter(
    (item) => item !== null && item !== undefined
  );
  return peopleRoleList;
};

export const getPeopleRoleForNodeV2 = (people, nodeId) => {
  let peopleRoleList = [];
  _.forEach(people, (item) => {
    if (item && item.roles) {
      let filteredItems = item.roles
        .filter((r) => r.node_id === nodeId && r.role !== "speaker")
        .filter((item) => item !== null && item !== undefined);
      if (filteredItems && filteredItems.length) {
        _.forEach(filteredItems, (fItem) => {
          fItem.person_id = item.id;
          fItem.people_name = item.name;
          fItem.location = item.location || "";
          peopleRoleList.push(fItem);
        });
        // filteredItems[0].people_name = item.name;
        // filteredItems[0].person_id = item.id;
        // peopleRoleList.push(...filteredItems);
      }
    }
  });

  peopleRoleList = peopleRoleList.filter(
    (item) => item !== null && item !== undefined
  );
  return peopleRoleList;
};

export const getPeopleTeamForNode = (people, nodeId) => {
  let peopleTeamList = [];
  _.forEach(people, (item) => {
    if (item && item.teams) {
      let filteredItems = item.teams
        .filter((r) => r.nodeId === nodeId)
        .filter((item) => item !== null && item !== undefined);
      if (filteredItems && filteredItems.length) {
        _.forEach(filteredItems, (fItem) => {
          fItem.person_id = item.id;
          fItem.people_name = item.name;
          fItem.location = item.location || "";
          peopleTeamList.push(fItem);
        });
      }
    }
  });

  peopleTeamList = peopleTeamList.filter(
    (item) => item !== null && item !== undefined
  );
  return peopleTeamList;
};

export const formatPeopleData = (data) => {
  let clonedData = _.map(data, (item) => {
    item = _.pick(item, validPeopleAttributes);
    return item;
  });

  // ordering the data by id column
  // if (clonedData && clonedData.length) {
  //   clonedData = getOrderedPeopleData(clonedData);
  // }
  return clonedData;
};

export const getOrderedPeopleData = (data) => {
  data = data.sort(function (a, b) {
    function getParts(o) {
      var array = o.id.split("-");
      return {
        prefix: { people: 1 }[array[0]],
        value: array[1],
      };
    }

    var aa = getParts(a),
      bb = getParts(b);

    return aa.prefix - bb.prefix || aa.value - bb.value;
  });
  return data;
};

export const getPeopleIdLookup = (people) => {
  let lookups = {};

  // Looking into parent nodes
  people &&
    people.forEach((item, index) => {
      lookups = {
        ...lookups,
        [item.id]: item.name,
      };
    });
  return lookups;
};

  
export const replaceEditedPerson = (arr, id, editedPerson) => {
  try {
    editedPerson.forEach(element => {
      for (const key in arr) {
         if(arr[key].id === element.id) {
            if(!element.teams){
              element.teams = arr[key].teams
            }
            if(!element.roles){
              element.roles = arr[key].roles
            }
            arr[key] = element
         }
      }
    });
  } catch (err) {
      for (const key in arr) {
        if(arr[key].id === editedPerson.id) {
          if(!editedPerson.teams){
            editedPerson.teams = arr[key].teams
          }
          if(!editedPerson.roles){
            editedPerson.roles = arr[key].roles
          }
          arr[key] = editedPerson
        }
      };
  };
  
  return arr;
}

export const formatRoleToSendInPeopleTable = (object) => {
  for (const key in object.children) {
    object.children[key].people_name = object.name
    object.children[key].location = object.location
    object.children[key].person_id = object.id
    object.children[key].peopleId = object.id
  }

  const formatObject = {
    id : parseInt(object.children[object.children.length - 1].nodeId),
    children : [object.children[object.children.length -1]]
  }

  return formatObject
}

export const formatTeamToSendInPeopleTable = (object) => {

  for (const key in object.children) {
    object.children[key].person_id = object.id
    object.children[key].peopleId = object.id
  }

  const formatObject = {
    id : parseInt(object.children[object.children.length - 1].nodeId),
    children : [object.children[object.children.length -1]]
  }

  return formatObject
}