import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as d3 from 'd3';
import * as d3Collection from 'd3-collection';
import _, { each } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import nodesService from 'services/NodesService';
import linksService from 'services/LinksService';
import peopleService from 'services/PeopleService';
import {
  getPeopleRoleForNodeV2,
  getPeopleTeamForNode,
} from '../../../helpers/PeopleDataTable';
import autocomplete from './autocomplete';
import '../d3/index.style.scss';
import './custom-layout.scss';
import './autocomplete.style.scss';
import MaterialTable from 'material-table';
import { getInfoboxTableOptions } from 'helpers/DataTable';
import {
  orderNodes,
  findTypes,
  checkParent,
} from '../../../helpers/NodeDataTable';
import { useSelector } from 'react-redux';
import connectionsIcon from '../../../resources/images/connections.png';
import Values from 'values.js';
import chroma from 'chroma-js';
import tinycolor from 'tinycolor2';
import { Icon } from '@material-ui/core';
import fontColorContrast from 'font-color-contrast';
import { fi } from 'date-fns/locale';

let json_data = [];
let data_flat = [];

let svg;
let nodes = [];
let allNodes = [];
let links = [];
let peoples = [];
let data = {
  nodes: [],
  links: [],
  people: [],
};
let prevId;
let prevName;
let clicked = false;
let currentLevel = 0;
let legendClicked = false;
let width = 1000;
let containerWidth = '100%';
let height = 600;
let stickyTimeVar;
let infoBoxTimeVar;
let stickyCctTimeVar;
let stickyBoxFlag = false;
let stickyBoxCctFlag = false;
let isFirstTooltip = false;
let isFirstInfobox = false;
let currentSelected = null;

const strategyType = 'strategy';
const circleType = 'circle';
const innerCircleType = 'inner-circle';
const ovalType = 'oval';
const homeType = 'home';
const sustainabilityType = 'sustainability';
const fixedCircleType = 'fixed-circle';

const linkInnerType = 'inner';
const linkOuterType = 'outer';
const linkOuterOvalType = 'outer-oval';
const linkCctCocType = 'cct-coc';
const linkCctCtType = 'cct-ct';
const linkCocTeamType = 'coc-highlight';

export default function D3ChartComponent({ prefData }) {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [showCCT, setShowCCT] = useState(false);
  const [peopletableColumns, setPeopleTableColumns] = useState([]);
  const [peopleTableData, setPeopleTableData] = useState([]);
  const [teamstableColumns, setTeamsTableColumns] = useState([]);
  const [teamsTableData, setTeamsTableData] = useState([]);
  const [connectionsTableColumns, setConnectionsTableColumns] =
    useState([]);
  const [connectionsTableData, setConnectionsTableData] = useState(
    [],
  );
  const [dynamicColorPalette, setDynamicColorPalette] = useState([]);
  const userRole = useSelector((state) => state.user.role);

  const email = prefData.email === null ? '' : prefData.email;

  useEffect(() => {
    const getPalate = (color) => {
      return [color, tinycolor(color).darken(7).toString()];
    };

    if (prefData) {
      setDynamicColorPalette([
        getPalate(prefData.color_scheme_coc),
        getPalate(prefData.color_scheme_ct),
        getPalate(prefData.color_scheme_et),
        getPalate(prefData.color_scheme_eg),
        getPalate(prefData.color_scheme_eb),
        getPalate(prefData.color_scheme_cct),
      ]);
    }
  }, [prefData]);

  const determineColorContrast = (prefData, d, depth) => {
    // console.log(d);

    // console.log(depth);
    // console.log(prefData);

    //   if (d.types.name === homeType)
    //   return `url(#radial-gradient4)`;
    // if (d.types.name === sustainabilityType)
    //   return `url(#radial-gradient3)`;
    // if (d.types.name === strategyType)
    //   return `url(#radial-gradient2)`;
    // if (d.types.name !== innerCircleType)
    //   return `url(#radial-gradient0)`;
    // else {
    //   return `url(#radial-gradient1)`;
    // }

    // if (d.data.types.name === sustainabilityType) {
    //   return `url(#radial-gradient3)`;
    // } else if (d.data.types.name === strategyType) {
    //   return `url(#radial-gradient2)`;
    // } else {
    //   return `url(#radial-gradient${d.depth})`;
    // }

    return fontColorContrast(
      d.types.name !== innerCircleType &&
        d.types.name !== ovalType &&
        d.types.name !== homeType &&
        d.types.name !== sustainabilityType &&
        d.types.name !== strategyType
        ? prefData.color_scheme_coc
        : d.types.name === homeType
        ? prefData.color_scheme_eb
        : d.types.name === ovalType
        ? prefData.color_scheme_cct
        : d.types.name === sustainabilityType
        ? prefData.color_scheme_eg
        : d.types.name === strategyType
        ? prefData.color_scheme_et
        : depth > 1
        ? dynamicColorPalette[depth]
        : prefData.color_scheme_ct,
    );
  };

  // const [isFirstTooltip, setIsFirstTooltip] = useState(false);
  const useStyles = makeStyles((theme) => ({
    legend: {
      padding: '0px',
    },
    listItem: {
      float: 'left',
      marginRight: '10px',
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      [theme.breakpoints.up('sm')]: {
        marginLeft: '35%',
        marginTop: '0px',
      },
    },
    listText: {
      border: '1px solid #ccc',
      float: 'left',
      width: '12px',
      height: '12px',
      background: 'black',
      margin: '2px',
    },
    circle: {
      width: '36px',
      height: '36px',
      background: 'black',
      border: '1px solid #ccc',
      float: 'left',
      borderRadius: '50%',
      marginRight: '10px',
    },
    circle1: {
      width: '36px',
      height: '36px',
      background: 'springgreen',
      border: '1px solid #ccc',
      float: 'left',
      borderRadius: '50%',
      marginRight: '10px',
    },
    circle2: {
      width: '36px',
      height: '36px',
      background: 'MediumSeaGreen',
      border: '1px solid #ccc',
      float: 'left',
      borderRadius: '50%',
      marginRight: '10px',
    },
    circle3: {
      width: '36px',
      height: '36px',
      background: 'Gainsboro',
      border: '1px solid #ccc',
      float: 'left',
      borderRadius: '50%',
      marginRight: '10px',
    },
    oval: {
      width: '36px',
      height: '18px',
      background: '#D0F0C0',
      border: `3px solid black`,
      float: 'left',
      borderRadius: '18px / 9px',
      marginRight: '10px',
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    headerText: {
      marginLeft: '10px',
      fontWeight: 'bold',
    },
    legendHeader: {
      marginTop: '24px',
      [theme.breakpoints.up('sm')]: {
        marginLeft: '35%',
        marginTop: '0px',
      },
    },
    root: {
      padding: '2px 4px',
      marginTop: '16px',
      marginBottom: '24px',
      display: 'flex',
      alignItems: 'center',
      border: `3px solid black`,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    button: {
      backgroundColor: '#228B22',
      '&:hover': {
        backgroundColor: '#228B22',
        color: '#ffffff',
      },
    },
    chartContainer: {
      display: 'inline-block',
      margin: '0 auto',
      width: '100%',
    },
  }));

  useLayoutEffect(() => {
    let mounted = true;

    if (dynamicColorPalette) {
      const getData = async () => {
        await nodesService.getAll().then((items) => {
          if (items && items.length > 0 && mounted) {
            items = _.orderBy(items, ['order'], ['asc']);
            nodes = orderNodes(items);
            allNodes = items.map((item) => {
              item.nodeId = item.id;
              return item;
            });
            data = {
              ...data,
              nodes: nodes,
              allNodes: allNodes,
            };
          }
        });

        // Getting links from backend
        await linksService.getAll().then((items) => {
          if (items && items.length > 0 && mounted) {
            links = items;
            data = {
              ...data,
              links: links,
            };
            // width = +d3.select(".chart").style("width").slice(0, -2);
            // height = window.innerHeight;
            // drawChart(height, width);
          }
        });

        // Getting people from backend
        await peopleService.getAll().then((items) => {
          if (items && items.length > 0 && mounted) {
            peoples = items;
            data = {
              ...data,
              people: peoples,
            };
            // width = +d3.select(".chart").style("width").slice(0, -2);
            // height = window.innerHeight;
            drawChart(
              height,
              width,
              containerWidth,
              dynamicColorPalette,
            );
          }
        });
      };

      getData();
    }

    return () => (mounted = false);
  }, [dynamicColorPalette]);

  const handleNodeDragDrop = (node) => {
    // Getting nodes from backend
    nodesService
      .update(node)
      .then((items) => {
        // console.log('nodedragdrop');
      })
      .catch((error) => {
        console.log(
          'An error occurred while updating position for the node =>',
          error,
        );
      });
  };

  //function for CCT Radio box list
  function toggleCCT(target) {
    let elementsWithCCTClasses = '';

    if (target.id === 'cct_all') {
      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='oval_']",
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = 'block';
      });

      elementsWithCCTClasses = document.querySelectorAll(
        '.exec-source.oval-target',
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = 'block';
      });

      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='zoom-outer-oval']",
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = 'block';
      });
    } else if (target.id === 'cct_plt_sop') {
      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='oval_']",
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = 'none';
      });

      //Show the 2 eclipses related to plt
      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='oval-plt']",
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = 'block';
      });

      //Show the a eclipse related to S&OP
      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='oval-sop']",
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = 'block';
      });

      // show the lines between exec and plt eclipses
      elementsWithCCTClasses = document.querySelectorAll(
        '.exec-source.oval-target',
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = 'block';
      });

      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='zoom-outer-oval']",
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = 'none';
      });

      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='zoom-outer-oval-plt']",
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = 'block';
      });
    } else if (target.id === 'cct_none') {
      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='oval_']",
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = 'none';
      });

      elementsWithCCTClasses = document.querySelectorAll(
        '.exec-source.oval-target',
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = 'none';
      });

      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='zoom-outer-oval']",
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = 'none';
      });
    } else {
      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='oval_']",
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = 'none';
      });

      elementsWithCCTClasses = document.querySelectorAll(
        '.exec-source.oval-target',
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = 'none';
      });

      elementsWithCCTClasses = document.querySelectorAll(
        "*[class*='zoom-outer-oval']",
      );
      Array.from(elementsWithCCTClasses).forEach((item) => {
        item.style.display = 'none';
      });

      //Show the eclipse related to selected CCT Id
      const selectedCCTElement = document.querySelector(
        '#oval_' + target.id,
      );
      const selectedCCTElementText = document.querySelector(
        'text#oval_' + target.id,
      );

      selectedCCTElement.style.display = 'block';
      selectedCCTElementText.style.display = 'block';
    }
  }

  function addEntity(origJSON, data, category) {
    let name_id = data.name.replace(/[^a-zA-Z ]/g, '');
    let type = category === 'CCT' ? 'oval' : 'circle';
    origJSON.nodes.push({
      id: `${category}-${name_id}`,
      name: data.name,
      type: type,
    });
    return origJSON;
  }

  function removeEntity(obj, id) {
    const relevantNodes = Object.entries(obj.nodes) // converts each entry to [key, value]
      .filter(([k, v]) => v.id !== id) // define the criteria to include/exclude items
      .reduce((acc, [k, v]) => {
        acc[k] = v;
        return acc; // this function can be improved, it converts the [[k, v]] back to {k: v, k: v, ...}
      }, {});

    const relevantLinks = Object.entries(obj.links) // converts each entry to [key, value]
      .filter(([k, v]) => v.start_id !== id && v.end_id !== id) // define the criteria to include/exclude items
      .reduce((acc, [k, v]) => {
        acc[k] = v;
        return acc; // this function can be improved, it converts the [[k, v]] back to {k: v, k: v, ...}
      }, {});

    return {
      nodes: Object.values(relevantNodes),
      links: Object.values(relevantLinks),
    };
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const drawChart = (
    height,
    width,
    containerWidth,
    dynamicpalette,
  ) => {
    // const colorRanges = [
    //   ["#18bc57", "#009A3D", "#067736"],
    //   ["#95C11C", "#93c219", "#5cb229"],
    //   ["#77be90", "#3db18f", "#20aa8e"],
    //   ["#b1c2c4", "#b1b2b4", "#4B4A4B"],
    //   ["#005371", "#005472", "#003a57"], //exec
    // ];

    const color = d3.scaleOrdinal().domain([0, 1, 2, 3, 4, 5]);
    //.range(["ForestGreen", "springgreen", "MediumSeaGreen", "Gainsboro"]);
    // .range(["#009A3D", "#93c219", "#78BE90", "#b1b2b4"]);

    function addEntity(origJSON, data, category) {
      let name_id = data.name.replace(/[^a-zA-Z ]/g, '');
      let type = category === 'CCT' ? 'oval' : 'circle';
      origJSON.nodes.push({
        id: `${category}-${name_id}`,
        name: data.name,
        type: type,
      });
      return origJSON;
    }

    function removeEntity(obj, id) {
      const relevantNodes = Object.entries(obj.nodes) // converts each entry to [key, value]
        .filter(([k, v]) => v.id !== id) // define the criteria to include/exclude items
        .reduce((acc, [k, v]) => {
          acc[k] = v;
          return acc; // this function can be improved, it converts the [[k, v]] back to {k: v, k: v, ...}
        }, {});

      const relevantLinks = Object.entries(obj.links) // converts each entry to [key, value]
        .filter(([k, v]) => v.start_id !== id && v.end_id !== id) // define the criteria to include/exclude items
        .reduce((acc, [k, v]) => {
          acc[k] = v;
          return acc; // this function can be improved, it converts the [[k, v]] back to {k: v, k: v, ...}
        }, {});

      return {
        nodes: Object.values(relevantNodes),
        links: Object.values(relevantLinks),
      };
    }

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    const drag = (simulation, link) => {
      // function dragstarted(event, d) {

      //   if (!event.active) simulation.alphaTarget(0.03).restart();
      //   d.fx = d.x;
      //   d.fy = d.y;
      // }

      // function dragged(event, d) {
      //   d.fx = event.x;
      //   d.fy = event.y;
      // }

      // function dragended(event, d) {
      //   if (!event.active) simulation.alphaTarget(0.03);
      //   d.fx = null;
      //   d.fy = null;
      // }

      function dragstarted(d) {
        if (userRole === 1) {
          d3.select(this).raise().classed('active', true);
        }
      }

      function dragged(event, d) {
        if (userRole === 1) {
          d3.select(this).attr(
            'transform',
            `translate(${(d.x = event.x)}, ${(d.y = event.y)})`,
          );
          link
            .attr('x1', (d) => {
              return d.source.x;
            })
            .attr('y1', (d) => d.source.y)
            .attr('x2', (d) => d.target.x)
            .attr('y2', (d) => d.target.y);
        }
      }

      function dragended(d) {
        if (userRole === 1) {
          d3.select(this).classed('active', false);
          handleNodeDragDrop(d && d.subject);
          simulation.stop();
          // console.log('drag ended', d);
        }
      }

      return d3
        .drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended);
    };

    // Handles the long of the nodes name
    function getTruncated(d) {
      if (d.length > 15) {
        return d.includes('&') && d.split('&')[0].length < 5
          ? d.split('&')[0]
          : d.slice(0, 5);
      } else {
        return d;
      }
    }

    // function to get and cut off the string including 15+ length
    // cut text
    const getLabel = (d) => {
      if (d.r < 15) {
        return d.data.name.length < 15
          ? d.data.name
          : d.data.name.substring(0, 12) + '...';
      } else if (d.r < 20) {
        return d.data.name.length < 20
          ? d.data.name
          : d.data.name.substring(0, 17) + '...';
      } else if (d.r < 25) {
        return d.data.name.length < 25
          ? d.data.name
          : d.data.name.substring(0, 23) + '...';
      } else if (d.r < 30) {
        return d.data.name.length < 30
          ? d.data.name
          : d.data.name.substring(0, 27) + '...';
      } else {
        if (d.data.name.length > 10) {
          return d.data.name.length < 18
            ? d.data.name
            : d.data.name.substring(0, 10) + '...';
        } else {
          return d.data.name;
        }
      }
    };

    //////////////////////////////////////// DRAW CONTAINER ELEMENTS ////////////////////////////////////////
    svg = d3
      .select('.chart')
      .append('svg')
      .attr('class', 'chart-svg')
      // .style('background', 'white')
      .style('overflow', 'hidden')
      .attr('preserveAspectRatio', 'slice')
      .attr('viewBox', '0 0 800 750')
      .attr('width', containerWidth)
      .attr('height', window.innerHeight - 150);
    /* .call(
        d3
          .zoom()
          .scaleExtent([1, 5])
          .on('zoom', function (event) {
            var t = event.transform;

            t.x = d3.min([t.x, 0]);
            t.y = d3.min([t.y, 0]);
            t.x = d3.max([t.x, (1 - t.k) * 1000]);
            t.y = d3.max([t.y, (1 - t.k) * 1000]);

            svg.attr('transform', t);
          }),
      );

    var zoom = d3
      .zoom()
      .scaleExtent([1, 8])
      .on('zoom', function (event) {
        var t = event.transform;

        t.x = d3.min([t.x, 0]);
        t.y = d3.min([t.y, 0]);
        t.x = d3.max([t.x, (1 - t.k) * 1000]);
        t.y = d3.max([t.y, (1 - t.k) * 1000]);
        g.selectAll(':scope>g').attr('transform', t);
        g.selectAll('polygon').attr('transform', t);
        g.selectAll('line').attr('transform', t);
        g.selectAll('path').attr('transform', t);
        g.selectAll(':scope>ellipse').attr('transform', t);
      });
    svg.call(zoom) */

    dynamicpalette.map((colorRange, index) => {
      var node = svg
        .append('defs')
        .append('radialGradient')
        .attr('id', 'radial-gradient' + index);

      var color = d3
        .scaleLinear()
        .range(colorRange)
        .domain([-1, 0, 1]);

      node
        .append('stop')
        .attr('offset', '0%')
        .attr('stop-color', color(-1));

      node
        .append('stop')
        .attr('offset', '50%')
        .attr('stop-color', color(0));

      node
        .append('stop')
        .attr('offset', '100%')
        .attr('stop-color', color(1));

      // svg.append("circle")
      // .attr("cx", width * 0.5)
      // .attr("cy", height * 0.2 * index)
      // .attr("r", height * 0.2)
      // .style("opacity", 1.0)
      // .style("fill", `url(#radial-gradient${index})`);
    });

    d3.select('.chart').on('click', function () {
      removePeopleTable();
      removeTeamsTable();
      d3.select('#table table').remove();
      stickyBoxFlag = false;
      stickyBoxCctFlag = false;
      currentSelected = null;
      d3.select('.panel').style('visibility', 'hidden');
      d3.select('.panel-box-CCT').style('visibility', 'hidden');
      d3.select('.panel-search').style('visibility', 'hidden');
      d3.select('.child-pointer').style('visibility', 'hidden');
      d3.selectAll("*[class*='border-circle_']").style(
        'visibility',
        'hidden',
      );
    });

    // start postion of nodes
    const g = svg
      .append('g')
      .attr('transform', 'translate(' + width / 7 + ',' + 110 + ')');

    //An arc that surrender the home node and where the other nodes are going to be located.
    var outer_dim = width / 2;
    var outer_arc = g
      .append('path')
      .attr(
        'd',
        'M 0, ' +
          (outer_dim / 2 + 0) +
          ' a ' +
          outer_dim / 2 +
          ',' +
          outer_dim / 2 +
          ' 0 1,0 ' +
          outer_dim +
          ',0 a ' +
          outer_dim / 2 +
          ',' +
          outer_dim / 2 +
          ' 0 1,0 ' +
          outer_dim * -1 +
          ',0',
      )
      .style('fill', 'transparent');

    var dim = width / 6;
    var inner_arc = g
      .append('path')
      .attr(
        'd',
        'M' +
          (outer_dim - dim) / 2 +
          ', ' +
          (dim / 2 + (outer_dim - dim) / 2) +
          ' a ' +
          dim / 2 +
          ',' +
          dim / 2 +
          ' 0 1,0 ' +
          dim +
          ',0 a ' +
          dim / 2 +
          ',' +
          dim / 2 +
          ' 0 1,0 ' +
          dim * -1 +
          ',0',
      )
      .style('fill', 'transparent');

    // evenly spaces nodes along arc
    const circleCoord = function (circle, node, index, num_nodes) {
      var circumference = circle.node().getTotalLength();
      var pointAtLength = function (l) {
        return circle.node().getPointAtLength(l);
      };
      var sectionLength = circumference / num_nodes;
      var position = sectionLength * index + sectionLength;
      return pointAtLength(circumference - position);
    };

    //////////////////////////////////////// DRAW COMMUNITY ARC LABELS ////////////////////////////////////////
    //Turn the pie chart 90 degrees counter clockwise, so it starts at the left
    const pie = d3
      .pie()
      .startAngle((-90 * Math.PI) / 180)
      .endAngle((-90 * Math.PI) / 180 + 2 * Math.PI)
      .value(function (d) {
        return d.value;
      })
      .padAngle(0.01)
      .sort(null);

    const arc = d3
      .arc()
      .outerRadius(outer_dim / 2 + 50)
      .innerRadius(outer_dim / 2);

    var errorbtn = d3
      .select('.error-btn')
      .append('a')
      .attr('class', 'errorbtn')
      .style('visibility', 'hidden')
      .style('font-family', 'Source Sans Pro, sans-serif')
      .attr('font-size', '20px')
      //.style('z-index', '1')
      .text('');

    var tooltip = d3
      .select('.chart')
      .append('div')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('font-family', 'Source Sans Pro, sans-serif')
      //.style('width', '200px')
      .style('max-width', '300px')
      .style('background-color', 'white')
      .style(
        'box-shadow',
        '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      )
      .style('padding-top', '3px')
      .style('padding-bottom', '3px')
      .style('padding-left', '10px')
      .style('padding-right', '10px')
      .style('text-align', 'center')
      .style('z-index', '3')
      //.style('border-radius', '15px')
      .text('');
    // const donut = g.append("g")
    //   .attr('transform', `translate(${outer_dim/2}, ${outer_dim/2})`)

    // //Create the donut slices and also the invisible arcs for the text
    // donut.selectAll(".donutArcSlices")
    //     .data(pie(donutData))
    //     .enter().append("path")
    //     .attr("class", "donutArcSlices")
    //     .attr("d", arc)
    //     .style("fill", 'none')
    //     .each(function(d,i) {
    //         //A regular expression that captures all in between the start of a string
    //         //(denoted by ^) and the first capital letter L
    //         var firstArcSection = /(^.+?)L/;

    //         //The [1] gives back the expression between the () (thus not the L as well)
    //         //which is exactly the arc statement
    //         var newArc = firstArcSection.exec( d3.select(this).attr("d") )[1];
    //         //Replace all the comma's so that IE can handle it -_-
    //         //The g after the / is a modifier that "find all matches rather than
    //         //stopping after the first match"
    //         newArc = newArc.replace(/,/g , " ");

    //         //Create a new invisible arc that the text can flow along
    //         svg.append("path")
    //             .attr("class", "hiddenDonutArcs")
    //             .attr("id", "donutArc"+i)
    //             .attr("d", newArc)
    //             .style("fill", "none");
    //     });

    // //Append the label names on the outside
    // donut.selectAll(".donutText")
    //     .data(donutData)
    //    .enter().append("text")
    //     .attr("class", "donutText")
    //     .attr('font-size', '20px')
    //     .attr("dy", -13)
    //    .append("textPath")
    //     .attr("startOffset","50%")
    //     .style("text-anchor","middle")
    //     .attr("xlink:href",function(d,i){return "#donutArc"+i;})
    //     .text(function(d){return d.name;});

    //////////////////// DRAW LEGEND ////////////////////
    // First create the legend and then append the circles and ellipses
    const legend = d3
      .select('.legend')
      .append('svg')
      .attr('class', 'legend-svg')
      .style('background', 'transparent')
      .attr('width', 1000)
      .attr('height', 30)
      .style('z-index', 1)
      .append('g')
      .attr('transform', 'translate(15, -135)');

    legend
      .append('circle')
      .attr('transform', 'translate(50,150)')
      .attr('r', 15)
      .attr('fill', `url(#radial-gradient0)`);

    legend
      .append('text')
      .style('font-family', 'Source Sans Pro, sans-serif')
      .attr('font-size', '14px')
      // .style('color', '#4B4A4B')
      .attr('transform', 'translate(70,155)')
      .text('Center of Competence');

    legend
      .append('circle')
      .attr('transform', 'translate(225,150)')
      .attr('r', 15)
      .attr('fill', `url(#radial-gradient1)`);

    legend
      .append('text')
      .style('font-family', 'Source Sans Pro, sans-serif')
      .attr('font-size', '14px')
      .style('color', '#4B4A4B')
      .attr('transform', 'translate(240,155)')
      .text('Competence Team');

    legend
      .append('circle')
      .attr('transform', 'translate(400,150)')
      .attr('r', 15)
      .attr('fill', `url(#radial-gradient2)`);

    legend
      .append('text')
      .style('font-family', 'Source Sans Pro, sans-serif')
      .attr('font-size', '14px')
      .style('color', '#4B4A4B')
      .attr('transform', 'translate(425,155)')
      .text('Expert Team');

    legend
      .append('circle')
      .attr('transform', 'translate(525,150)')
      .attr('r', 15)
      .attr('fill', `url(#radial-gradient3)`);

    legend
      .append('text')
      .style('font-family', 'Source Sans Pro, sans-serif')
      .attr('font-size', '14px')
      .style('color', '#4B4A4B')
      .attr('transform', 'translate(550,155)')
      .text('Expert');

    legend
      .append('ellipse')
      .attr('transform', 'translate(618,150)')
      .attr('rx', 15)
      .attr('ry', 10)
      // .attr('fill', '#FFF')
      .attr('fill', `url(#radial-gradient5)`);
    // .attr('stroke', '#009a3c')
    // .attr('stroke-width', '2px');

    legend
      .append('text')
      .style('font-family', 'Source Sans Pro, sans-serif')
      .attr('font-size', '14px')
      .style('color', '#4B4A4B')
      .attr('transform', 'translate(645,155)')
      .text('Cross-Competence Team');

    legend
      .append('circle')
      .attr('transform', 'translate(820,150)')
      .attr('r', 14)
      .style('fill', 'rgba(129, 198, 153, .2)')
      .style('stroke', '#81C69D')
      .style('stroke-width', '1px');

    legend
      .append('image')
      .attr('transform', 'translate(813,142)')
      .attr('xlink:href', connectionsIcon)
      .attr('width', 15)
      .attr('height', 15)
      .attr('font-weight', 'bold');

    legend
      .append('text')
      .style('font-family', 'Source Sans Pro, sans-serif')
      .attr('font-size', '14px')
      .style('color', '#4B4A4B')
      .attr('transform', 'translate(840,155)')
      .text('Functional connections');

    updateGraph(JSON.parse(JSON.stringify(data)));

    function updateGraph(data) {
      // Sort data to avoid nodes reorder
      data.nodes.sort((a, b) => {
        if (a.id > b.id) {
          return 1;
        }
        if (a.id < b.id) {
          return -1;
        }
        return 0;
      });
      json_data = data;

      const innerCircle = data.nodes.filter(
        (d) =>
          d.types.name === innerCircleType ||
          d.types.name === sustainabilityType ||
          d.types.name === strategyType,
      );
      const outerCircle = data.nodes.filter(
        (d) => d.types.name === circleType,
      );
      let innerCircleList = innerCircle.map((d) => d.name);
      let outerCircleList = outerCircle.map((d) => d.name);
      const innerCircleNum = innerCircle.length;
      const outerCircleNum = outerCircle.length;
      const ovalsNum = data.nodes.filter(
        (d) => d.types.name === ovalType,
      ).length;

      const MAX_RADIUS =
        innerCircleNum === 0
          ? outer_dim / 7.2
          : ovalsNum > 15
          ? outer_dim / 11
          : outerCircleNum > 6
          ? outer_dim / 11
          : outer_dim / 9.2;
      const innerNodeRadiusScale = d3
        .scaleSqrt()
        .domain([0, 5])
        .range([(MAX_RADIUS * 0.5) / 5, MAX_RADIUS * 0.5]);
      const outerNodeRadiusScale = d3
        .scaleSqrt()
        .domain([0, 5])
        .range([MAX_RADIUS / 5, MAX_RADIUS]);
      const radiusAccessor = (d) => {
        // return d.id === 'exec' ? innerNodeRadiusScale(d.value) : outerNodeRadiusScale(d.value)
        return d.types?.name === homeType ||
          d.data?.types.name === homeType
          ? 70
          : d.types?.name === circleType ||
            d.data?.types.name === circleType
          ? 115
          : (d.types?.name
              ? d.types?.name === innerCircleType
              : d.data?.types.name === innerCircleType) ||
            (d.types?.name
              ? d.types?.name === sustainabilityType
              : d.data?.types.name === sustainabilityType) ||
            (d.types?.name
              ? d.types?.name === strategyType
              : d.data?.types.name === strategyType)
          ? 60
          : outerNodeRadiusScale(d.value);
      };

      function getFixedCoordinates(d, i) {
        if (
          d.types?.name === homeType ||
          d.data?.types.name === homeType
        ) {
          return { x: outer_dim / 2, y: outer_dim / 2 };
        } else if (
          d.types?.name === innerCircleType ||
          d.data?.types.name === innerCircleType ||
          d.types?.name === sustainabilityType ||
          d.data?.types.name === sustainabilityType ||
          d.types?.name === strategyType ||
          d.data?.types.name === strategyType
        ) {
          let p1 = circleCoord(outer_arc, d, i - 1, outerCircleNum); // find coordinates of outer ring to slot the inner bubbles between
          let p2 = circleCoord(outer_arc, d, i, outerCircleNum); // find coordinates of outer ring to slot the inner bubbles between
          let midX = p1.x + (p2.x - p1.x) * 0.5;
          let midY = p1.y + (p2.y - p1.y) * 0.5;
          return { x: midX, y: midY };
        } else if (
          d.types?.name === circleType ||
          d.data?.types.name === circleType
        ) {
          let outer_coord = circleCoord(
            outer_arc,
            d,
            i,
            outerCircleNum,
          ); // CHANGE
          return { x: outer_coord.x, y: outer_coord.y };
        } else {
          return { x: null, y: null };
        }
      }

      // check if any CoC bubble share links and place both side by side
      let linkGrps = d3Collection
        .nest()
        .key((d) => d.start_id)
        .entries(data.links);

      let CoC_pairs = [];
      let CT_pairs = [];

      linkGrps.forEach((d, i) => {
        let targets = d.values.map((d) => d.target);
        let CoC_names = [];
        let CT_names = [];
        targets.forEach((t) => {
          if (t) {
            const foundItem = data['nodes'].find((el) => el.id === t);
            if (
              t.types?.name === circleType ||
              d.data?.types.name === circleType
            ) {
              if (foundItem) {
                CoC_names.push(foundItem.name);
              }
            } else {
              if (foundItem) {
                CT_names.push(foundItem.name);
              }
            }
          }
        });
        CoC_names = CoC_names.sort((a, b) =>
          d3.ascending(b.toLowerCase(), a.toLowerCase()),
        );
        CT_names = CT_names.sort((a, b) =>
          d3.ascending(b.toLowerCase(), a.toLowerCase()),
        );
        let CoC_includes = CoC_pairs.some((a) =>
          CoC_names.every((v, i) => v === a[i]),
        );
        let CT_includes = CT_pairs.some((a) =>
          CT_names.every((v, i) => v === a[i]),
        );
        if (CoC_names.length === 2 && !CoC_includes) {
          CoC_pairs.push(CoC_names);
        }
        if (CT_names.length === 2 && !CT_includes) {
          CT_pairs.push(CT_names);
        }
      });

      CoC_pairs = CoC_pairs.flat().filter(onlyUnique);
      CT_pairs = CT_pairs.flat().filter(onlyUnique);

      innerCircleList = innerCircleList.sort((a, b) =>
        d3.ascending(b.toLowerCase(), a.toLowerCase()),
      );
      innerCircleList = innerCircleList.sort(
        (a, b) => CT_pairs.indexOf(b) - CT_pairs.indexOf(a),
      );
      // outerCircleList = outerCircleList.sort((a, b) => d3.ascending(b.toLowerCase(), a.toLowerCase())) //hakuna
      // outerCircleList = outerCircleList.sort((a, b) => CoC_pairs.indexOf(b) - CoC_pairs.indexOf(a))
      data.nodes = data.nodes.sort(
        (a, b) =>
          outerCircleList.indexOf(b.name) -
          outerCircleList.indexOf(a.name),
      );
      data.nodes = data.nodes.sort(
        (a, b) =>
          innerCircleList.indexOf(b.name) -
          innerCircleList.indexOf(a.name),
      );

      data.nodes.forEach((d, i) => {
        if (innerCircleList.indexOf(d.name) !== -1) {
          d.index = innerCircleList.indexOf(d.name);
        } else if (outerCircleList.indexOf(d.name) !== -1) {
          d.index = outerCircleList.indexOf(d.name);
        } else {
          d.index = 0;
        }
      });

      let outer_poly = [];
      let inner_poly = [];
      data['nodes'].forEach((d, i) => {
        let PARENT = `${d.types.name}_${d.id}`;
        let index = d.index;
        let coords = getFixedCoordinates(d, index);
        d.groupId = PARENT;
        d.level = 1;
        d.fx = coords.x;
        d.fy = coords.y;
        if (
          d.types.name === innerCircleType ||
          d.types.name === sustainabilityType ||
          d.types.name === strategyType
        ) {
          inner_poly.push({ x: coords.x, y: coords.y });
        }
        if (d.types?.name === circleType) {
          outer_poly.push({ x: coords.x, y: coords.y });
        }
        let sum = d3.hierarchy(d).sum((el) => el.value).value;
        d.value =
          d.types.name === ovalType
            ? 1
            : d.types.name === innerCircleType ||
              d.types.name === sustainabilityType ||
              d.types.name === strategyType
            ? sum / 1.5
            : sum <= 4
            ? (sum + 1) * 2
            : sum + 1;
        data_flat.push(d);
        d.truncated_name = getTruncated(d.name);
        d.children &&
          d.children.forEach((el) => {
            el.truncated_name = getTruncated(el.name);
            el.groupId = PARENT;
            el.level = 2;
            el.value = el.children ? el.children.length : 5;
            data_flat.push(el);
            el.children &&
              el.children.forEach((el1) => {
                el1.truncated_name = getTruncated(el1.name);
                el1.groupId = PARENT;
                el1.level = 3;
                el1.value = el1.children ? el1.children.length : 1;
                data_flat.push(el1);
                el1.children &&
                  el1.children.forEach((el2) => {
                    el2.truncated_name = getTruncated(el2.name);
                    el2.groupId = PARENT;
                    el2.level = 4;
                    el2.value = 1;
                    el2.value = el2.children
                      ? el2.children.length
                      : 1;
                    data_flat.push(el2);
                  });
              });
          });
      });

      const links = data.links.map((d) => d);
      const nodes = data.nodes.map((d) => d);

      const findNode = (node, sourceId, targetId) => {
        let sourceNode;
        let targetNode;

        if (node.id === sourceId) {
          sourceNode = node;
          if (node.children && node.children.length > 0) {
            node.children.forEach((childrenNode) => {
              const result = findNode(
                childrenNode,
                sourceId,
                targetId,
              );
              if (result.sourceNode) {
                sourceNode = result.sourceNode;
              }

              if (result.targetNode) {
                targetNode = result.targetNode;
              }
            });
          }
        } else if (node.id === targetId) {
          if (node.father_id) {
            const parentCoord = findFatherCoords(node.father_id);
            if (parentCoord) {
              node.x = parentCoord.x;
              node.y = parentCoord.y;
            }
          }
          targetNode = node;
        } else if (node.children && node.children.length) {
          node.children.forEach((childrenNode) => {
            const result = findNode(childrenNode, sourceId, targetId);
            if (result.sourceNode) {
              sourceNode = result.sourceNode;
            }

            if (result.targetNode) {
              targetNode = result.targetNode;
            }
          });
        }

        return {
          sourceNode: sourceNode,
          targetNode: targetNode,
        };
      };

      const findFatherCoords = (fatherId) => {
        let x;
        let y;

        allNodes.forEach((node) => {
          if (node.id === fatherId && !node.father_id) {
            nodes.forEach((el) => {
              if (el.id === node.id) {
                x = el.fx;
                y = el.fy;
              }
            });
          } else if (node.id === fatherId && node.father_id) {
            const result = findFatherCoords(node.father_id);
            if (result.x && result.y) {
              x = result.x;
              y = result.y;
            }
          }
        });

        return {
          x: x,
          y: y,
        };
      };

      let linksLst = [];
      let linksNotFound = [];
      // Processing links
      if (links && links.length) {
        links.forEach((item) => {
          const sourceNode =
            nodes &&
            nodes.find((s) => s.id === parseInt(item.source));
          const targetNode =
            nodes &&
            nodes.find((s) => s.id === parseInt(item.target));
          if (
            sourceNode &&
            sourceNode.id &&
            targetNode &&
            targetNode.id
          ) {
            item.source = parseInt(item.source);
            item.target = parseInt(item.target);
            linksLst.push(item);
          } else {
            linksNotFound.push(item);
          }
        });
      }

      if (linksNotFound && linksNotFound.length) {
        linksNotFound.forEach((linkNotFound) => {
          let sourceNode;
          let targetNode;

          nodes.forEach((node) => {
            const result = findNode(
              node,
              parseInt(linkNotFound.source),
              linkNotFound.target,
            );

            if (result.sourceNode) {
              sourceNode = result.sourceNode;
            }

            if (result.targetNode) {
              targetNode = result.targetNode;
            }
          });

          if (sourceNode && targetNode) {
            const itemToSave = {
              ...linkNotFound,
              source: sourceNode,
              target: targetNode,
            };

            linksLst.push(itemToSave);
          }
        });
      }

      if (nodes && nodes.length) {
        nodes.forEach((item) => {
          // if (item.types.name === ovalType) {
          if (item.connections && item.connections.length) {
            item.connections.forEach((el) => {
              let sourceNode;
              let targetNode;
              nodes.forEach((node) => {
                const result = findNode(
                  node,
                  el.nodeId,
                  el.nodeConnection,
                );

                if (result.sourceNode) {
                  sourceNode = result.sourceNode;
                }

                if (result.targetNode) {
                  targetNode = result.targetNode;
                }
              });
              if (sourceNode && targetNode) {
                const itemToSave = {
                  source: sourceNode,
                  target: targetNode,
                  type: 6,
                  types: {
                    id: 6,
                    link: true,
                    name: 'inner',
                    value: '',
                  },
                };
                linksLst.push(itemToSave);
              }
            });
          }
          // }
        });
      }

      const simulation = d3
        .forceSimulation(nodes)
        .force(
          'link',
          d3
            .forceLink(linksLst)
            .id((d) => d.id)
            .strength(0)
            .distance(0),
          // .distance(function (d, i) {
          //   if (d.type === "outer") {
          //     return 140;
          //   } else if (d.type === "outer-oval") {
          //     return 240;
          //   } else if ((d.type === "cct-coc") | (d.type === "cct-ct")) {
          //     return 60;
          //   } else {
          //     return 120;
          //   }
          // })
        )
        .force('charge', d3.forceManyBody().strength(0));
      // .force(
      //   "collide",
      //   d3
      //     .forceCollide()
      //     .strength(0.5)
      //     .radius(function (d) {
      //       if (d.type === "oval") {
      //         return radiusAccessor(d) * 1.2;
      //       } else if (d.type === "inner-circle") {
      //         return radiusAccessor(d) * 1.2;
      //       } else {
      //         return radiusAccessor(d) * 1.1;
      //       }
      //     })
      // );
      //.force("center", d3.forceCenter(width / 2, height / 2));

      //////////////////////////////////////// DRAW GRAPH ELEMENTS ////////////////////////////////////////
      // draw lines for all edge types
      // but only make visible edges between 'Center-of-Competence' and 'Competence Team' circles

      g.selectAll('polygon.outer-poly')
        .data([outer_poly])
        .join('polygon')
        .transition()
        .duration(300)
        .attr('class', 'outer-poly')
        .attr('points', function (d) {
          return d
            .map(function (d) {
              return [d.x, d.y].join(',');
            })
            .join(' ');
        })
        .attr('fill', 'transparent')
        .attr('stroke', '#005472')
        .attr('stroke-dasharray', 4)
        .attr('stroke-width', 2); // hakuna outer ploy's border width changed from 2 to zero to display none.

      g.selectAll('polygon.inner-poly')
        .data([inner_poly])
        .join('polygon')
        .transition()
        .duration(300)
        .attr('class', 'inner-poly')
        .attr('points', function (d) {
          return d
            .map(function (d) {
              return [d.x, d.y].join(',');
            })
            .join(' ');
        })
        .attr('fill', 'transparent')
        .attr('stroke', 'transparent');
      //.attr("stroke-dasharray",4)
      //.attr("stroke-width",2);

      // draw group element (acts as a wrapper to nest more elements inside) for all node types
      const nodeG = g
        .append('g')
        .attr('class', 'nodesWrapper')
        .selectAll('g')
        .data(nodes)
        .join('g')
        .attr('class', (d) => `${d.types.name}_${d.id}`);

      //d3.selectAll("g.oval")
      //.attr('transform', d => `translate(${d.x}, ${d.y})`)
      // .call(d3.drag()
      //        .on("start", dragstarted)
      //        .on("drag", dragged)
      //        .on("end", dragended)
      //  )

      // draw main outer and inner circles like Home and Group Governance
      let namedata;

      nodeG
        .filter((d) => d.types.name !== ovalType)
        .sort((a, b) => b.value - a.value)
        .append('circle')
        .attr('class', (d) => {
          return `border-circle_${d.types.name}_${d.id}`;
        })
        .attr('r', (d) => radiusAccessor(d) + 5)
        .attr('stroke', (d) => {
          if (d.types.name === homeType) return 'navy';
          if (d.types.name === circleType) return color(0);
          if (d.types.name === innerCircleType) return color(1);
          if (d.types.name === sustainabilityType) return color(3);
          if (d.types.name === strategyType) return color(2);
          else {
            return color(1);
          }
        })
        .attr('stroke-width', '2px')
        .attr('fill', 'transparent')
        .style('visibility', 'hidden');

      nodeG
        .filter((d) => d.types.name !== ovalType)
        .sort((a, b) => b.value - a.value)
        .attr('id', (d) => {
          if (d.types.name === circleType)
            return `${d.types.name}_${d.id}`;
        })
        .append('circle')
        .attr('id', (d) => `${d.types.name}_${d.id}`)
        .attr('class', (d) => d.groupId)
        .attr('r', (d) => radiusAccessor(d))
        .attr('cursor', 'pointer')
        .attr('fill', (d) => {
          if (d.types.name === homeType)
            return `url(#radial-gradient4)`;
          if (d.types.name === sustainabilityType)
            return `url(#radial-gradient3)`;
          if (d.types.name === strategyType)
            return `url(#radial-gradient2)`;
          if (d.types.name !== innerCircleType)
            return `url(#radial-gradient0)`;
          else {
            return `url(#radial-gradient1)`;
          }
        })
        .on('click', async function (event, d) {
          event.stopPropagation();
          currentLevel = d.data ? d.data.level : d.level; //hakuna
          if (currentLevel === 1) {
            d3.select(`.border-circle_${d.types.name}_${d.id}`).style(
              'visibility',
              'visible',
            );
          }
          currentSelected = `${d.types.name}_${d.id}`;

          if (!isFirstInfobox) {
            d3.select('.panel-box-CCT').style('visibility', 'hidden');
            clickCCT(event, d);
            isFirstInfobox = true;
          } else {
            // tooltip.text('');
            infoBoxTimeVar = await setTimeout(
              function () {
                //Start the timer
                d3.select('.panel-box-CCT').style(
                  'visibility',
                  'hidden',
                );
                clickCCT(event, d);
              }.bind(this),
              200,
            );
          }

          return zoom(d, d3.select(this));
        })
        .on('mouseover', async function (event, d) {
          if (currentLevel > 0 && d.level === 1) {
            return;
          }

          userRole !== 4 &&
            errorbtn
              //.style('visibility', prefData.email === '' ? 'hidden' : 'visible')
              .style('visibility', 'visible')
              .text('Seen something wrong? Request a correction')
              .on('click', function (e, d) {
                window.open(
                  `mailto:${email}?subject=I found an error website&body=Hi, I am _____________, I found an error in the node _____________, the error is _________________________.`,
                );
              });
          tooltip
            .style('visibility', 'visible')
            .text(d.data ? d.data.name : d.name);
          if (!isFirstInfobox) {
            d3.select('.panel-box-CCT').style('visibility', 'hidden');
            clickCCT(event, d);
            isFirstInfobox = true;
          } else {
            // tooltip.text('');
            infoBoxTimeVar = await setTimeout(
              function () {
                //Start the timer
                d3.select('.panel-box-CCT').style(
                  'visibility',
                  'hidden',
                );
                clickCCT(event, d);
              }.bind(this),
              200,
            );
          }
          svg
            .selectAll(`text.small-horizontal-labels`)
            .filter(
              (el) =>
                el.types?.name === fixedCircleType ||
                el.data?.types.name === fixedCircleType,
            )
            .style('visibility', 'hidden');

          svg.selectAll('polygon').attr('opacity', 0.1);

          svg
            .selectAll("*[class*='connectionTop_circle_image']")
            .style('opacity', 0);

          svg
            .selectAll("*[class*='connectionTop_circle_']")
            .style('opacity', 0);

          if (d.level === 1) {
            showLines(d);
            showCirclesConnected(d, linkListUpdated);
          }
        })
        .on('mouseenter', function (d) {
          stickyTimeVar = setTimeout(function () {
            stickyBoxFlag = true;
          }, 300 /* miliseconds */);
        })
        .on('mouseout', async function (d) {
          tooltip.style('visibility', 'hidden');
          tooltip.text('');
          clearTimeout(infoBoxTimeVar);
          // d3.select(this).attr("stroke", null);
          // d3.select(".border-circle_"+d.id).style("visibility","hidden")
          if (!stickyBoxFlag) {
            setTimeout(function () {
              d3.select('.panel').style('visibility', 'hidden');
              d3.selectAll('.child-pointer').style(
                'visibility',
                'hidden',
              );
            }, 300 /* miliseconds */);
          }
          clearTimeout(stickyTimeVar);

          if (currentLevel === 0) {
            svg
              .selectAll("*[class*='connectionTop_circle_image']")
              .style('opacity', 1);

            svg
              .selectAll("*[class*='connectionTop_circle_']")
              .style('opacity', 1);
          }
          //svg.selectAll("line").attr("stroke-opacity", 0);
          mouseleave(d);
          //d3.select('.panel').style('visibility', 'hidden')
        })
        .on('mousemove', function (event, d) {
          return tooltip
            .style('top', event.offsetY - 50 + 'px')
            .style('left', event.offsetX + 'px');
        });

      // for 'Center of Competence' nodes only, execute circle packing
      nodeG
        .append('g')
        .filter(
          (d) =>
            (d.types.name === circleType) |
            (d.types.name === innerCircleType) |
            (d.types.name === homeType),
        )
        .each(function (d) {
          drawHexagons(d3.select(this), d, {
            width: radiusAccessor(d) * 2,
            height: radiusAccessor(d) * 2,
          });
        });

      const checkNodeParent = (node) => {
        let nodeX = node.x;
        let nodeY = node.y;

        if (node.parent) {
          nodeX = nodeX - node.parent.x;
          nodeY = nodeY - node.parent.y;

          if (node.parent.parent) {
            nodeX = nodeX + node.parent.x - node.parent.parent.r;
            nodeY = nodeY + node.parent.y - node.parent.parent.y;
          }
        }

        return {
          nodeX,
          nodeY,
        };
      };

      const linkListUpdated = linksLst.map((link) => {
        if (
          link.target.types.name === innerCircleType &&
          !link.target.updated
        ) {
          const selection = svg
            .selectAll('circle')
            .filter(`#${link.target.types.name}_${link.target.id}`);
          try {
            const node = selection.data()[0];
            if (node.parent) {
              const coords = checkNodeParent(node);
              link.target.x = link.target.x + coords.nodeX;
              link.target.y = link.target.y + coords.nodeY;
            } else {
              link.target.x = link.target.x;
              link.target.y = link.target.y;
            }
          } catch (err) {}
        }
        link.target.updated = true;
        return link;
      });

      // Draw lines
      const link = g
        .selectAll('line')
        .data(linkListUpdated)
        .join('line')
        .attr('class', (d) => {
          if (d.source.types.name === homeType) {
            if (d.target.types.name === ovalType) {
              return 'exec-source oval-target';
            } else {
              return 'exec-source';
            }
          } else {
            return `${d.source.types.name}-${d.source.id}_|${d.target.name}`;
          }
        })
        .attr('stroke', (d) => {
          if (d.types.name === linkCocTeamType) {
            return 'transparent';
          } else {
            return 'black';
          }
        })
        .attr('stroke-width', '2px')
        .attr('stroke-opacity', 0);

      let sources = [];

      linkListUpdated.forEach((el) => {
        if (
          el.types.name === linkCocTeamType &&
          el.source.level === 1
        ) {
          if (sources.includes(el.source.id)) {
            return;
          }
          sources.push(el.source.id);

          const circle = nodeG
            .select(`#${el.source.types.name}_${el.source.id}`)
            .nodes();
          const rad = circle[0].r.baseVal.value;
          nodeG
            .filter(
              (d) =>
                d.id ===
                (el.source.id ? el.source.id : el.source.data.id),
            )
            .append('image')
            .attr(
              'id',
              `connectionTop_circle_image_${
                el.source.id || el.source.data?.id
              }`,
            )
            .attr(
              'class',
              `connectionTop_circle_image_${
                el.source.id || el.source.data?.id
              }`,
            )
            .attr('xlink:href', connectionsIcon)
            .attr('x', (rad > 60 ? rad / 1.2 : rad / 1.2) - 15 / 2)
            .attr('y', (rad > 60 ? -rad / 1.5 : -rad / 1.5) - 15 / 2)
            .attr('width', 15)
            .attr('height', 15)
            .attr('font-weight', 'bold');

          nodeG
            .filter(
              (d) =>
                d.id ===
                (el.source.id ? el.source.id : el.source.data.id),
            )
            .append('circle')
            .attr(
              'id',
              `connectionTop_circle_${
                el.source.id || el.source.data?.id
              }`,
            )
            .attr(
              'class',
              `connectionTop_circle_${
                el.source.id || el.source.data?.id
              }`,
            )
            .attr('r', 15)
            .attr('cx', () => (rad > 60 ? rad / 1.2 : rad / 1.2))
            .attr('cy', () => (rad > 60 ? -rad / 1.5 : -rad / 1.5))
            .style('cursor', 'pointer')
            .style('fill', 'rgba(129, 198, 153, .2)')
            .style('stroke', '#81C69D')
            .style('stroke-width', '1px')
            .on('mouseover', function (e) {
              d3.select(this)
                .transition()
                .duration(300)
                .style('fill', 'rgba(129, 198, 153, .5)');
            })
            .on('mouseout', function (e) {
              d3.select(this)
                .transition()
                .duration(300)
                .style('fill', 'rgba(129, 198, 153, .2)');
            })
            .on('click', (e, d) => {
              e.stopPropagation();
              e.preventDefault();
              if (currentLevel !== 0) {
                return;
              }

              let linkedNodes = [d];
              let linkedDescriptions = [];

              linkListUpdated.forEach((link) => {
                if (d.id && d.id === link.source.id) {
                  linkedNodes.push(link.target);
                  linkedDescriptions.push(link.description);
                }
              });

              showConnectionsBox(linkedNodes, linkedDescriptions);

              // Append the backdrop to handle the second click and restore the view
              const leftBackdrop = document.createElement('div');
              leftBackdrop.style.width = 'calc(98vw - 400px)';
              leftBackdrop.style.height = '100vh';
              leftBackdrop.style.display = 'block';
              leftBackdrop.style.position = 'absolute';
              leftBackdrop.style.top = 0;
              leftBackdrop.style.right = '400px';
              leftBackdrop.style.backgroundColor = 'transparent';
              leftBackdrop.style.zIndex = 1000;
              leftBackdrop.id = 'connections-left-backdrop';

              const topBackdrop = document.createElement('div');
              topBackdrop.style.width = 'calc(98vw)';
              topBackdrop.style.height = '120px';
              topBackdrop.style.display = 'block';
              topBackdrop.style.position = 'absolute';
              topBackdrop.style.top = 0;
              topBackdrop.style.right = 0;
              topBackdrop.style.backgroundColor = 'transparent';
              topBackdrop.style.zIndex = 1000;
              topBackdrop.id = 'connections-top-backdrop';

              const bottomBackdrop = document.createElement('div');
              bottomBackdrop.style.width = 'calc(98vw)';
              bottomBackdrop.style.height = '245px';
              bottomBackdrop.style.display = 'block';
              bottomBackdrop.style.position = 'absolute';
              bottomBackdrop.style.top = '820px';
              bottomBackdrop.style.right = 0;
              bottomBackdrop.style.backgroundColor = 'transparent';
              bottomBackdrop.style.zIndex = 1000;
              bottomBackdrop.id = 'connections-bottom-backdrop';

              const sidebar = document.querySelector('.side-right');
              sidebar.appendChild(leftBackdrop);
              sidebar.appendChild(topBackdrop);
              sidebar.appendChild(bottomBackdrop);

              const handleSecondClick = (e) => {
                if (
                  e.target.id === 'connections-left-backdrop' ||
                  e.target.id === 'connections-top-backdrop' ||
                  e.target.id === 'connections-bottom-backdrop'
                ) {
                  e.preventDefault();
                  e.stopPropagation();
                  removeConnectionsTable();
                  d3.select('.panel-connections').style(
                    'visibility',
                    'hidden',
                  );
                  svg
                    .selectAll('text')
                    .filter((d) => {
                      return d.id !== el.source.id;
                    })
                    .attr('opacity', 1);
                  svg
                    .selectAll(
                      "*[class*='connectionTop_circle_image']",
                    )
                    .style('opacity', 1);

                  svg
                    .selectAll("*[class*='connectionTop_circle_']")
                    .style('opacity', 1);
                  mouseleave(d);
                  const backdropTopRemove = sidebar.querySelector(
                    '#connections-top-backdrop',
                  );
                  const backdropLeftRemove = sidebar.querySelector(
                    '#connections-left-backdrop',
                  );
                  const backdropBottomRemove = sidebar.querySelector(
                    '#connections-bottom-backdrop',
                  );
                  sidebar.removeChild(backdropTopRemove);
                  sidebar.removeChild(backdropLeftRemove);
                  sidebar.removeChild(backdropBottomRemove);
                  document.removeEventListener(
                    'click',
                    handleSecondClick,
                  );
                }
              };

              document.addEventListener('click', handleSecondClick);

              const CCTInfoBoxWrapper =
                document.querySelector('.panel-box-CCT');
              CCTInfoBoxWrapper.style.visibility = 'hidden';
              d3.select('.panel').style('visibility', 'hidden');
              d3.select('.child-pointer').style(
                'visibility',
                'hidden',
              );

              g.transition()
                .duration(750)
                .attr('transform', function () {
                  return `translate(${width / 7},110)scale(1)`;
                });

              svg.selectAll('ellipse').attr('opacity', 0.1);

              svg
                .selectAll("*[class*='connectionTop_circle_image']")
                .style('opacity', (d) =>
                  d.id === el.source.id ? 1 : 0.1,
                );

              svg
                .selectAll("*[class*='connectionTop_circle_']")
                .style('opacity', (d) =>
                  d.id === el.source.id ? 1 : 0.1,
                );

              svg
                .selectAll('text.zoom-outer-oval')
                .style('visibility', 'hidden');

              svg
                .selectAll('text')
                .filter((d) => {
                  return d.id !== el.source.id;
                })
                .attr('opacity', 0);

              svg
                .selectAll('text.outer-labels')
                .filter((d) => {
                  return d.id !== el.source.id;
                })
                .attr('opacity', 0.1);

              svg
                .selectAll('circle')
                .filter((d) => {
                  return d.id !== el.source.id;
                })
                .attr('opacity', 0.1);

              svg.selectAll('polygon').attr('opacity', 0.1);

              showCirclesConnected(el.source, linkListUpdated, true);
            });
        }
      });

      // draw ovals for 'Cross Competence Team' nodes
      nodeG
        .filter((d) => d.types.name === ovalType)
        .call(drag(simulation, link))
        .append('ellipse')
        .attr('id', (d) => `${d.types.name}_${d.id}`) // tag each entity by its unique ID
        .attr('class', (d) => d.groupId)
        .attr('rx', (d) => radiusAccessor(d) * 1.75)
        .attr('ry', (d) => radiusAccessor(d) * 1.25)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('fill', `url(#radial-gradient5)`);
      // .attr('fill', '#FFF')
      // .attr('stroke', '#009a3c')
      // .attr('stroke-width', '2px');

      // for 'exec', 'Cross Competence Team' and 'Competence Team' nodes, append label
      // Note: this does not append labels for nested circles
      nodeG
        .append('text')
        .filter(
          (d) =>
            (d.types.name === innerCircleType && !d.children) ||
            d.types.name === ovalType ||
            (d.types.name === circleType && !d.children) ||
            (d.types.name === homeType && !d.children) ||
            (d.types.name === sustainabilityType && !d.children) ||
            (d.types.name === strategyType && !d.children),
        )
        .attr('class', (d) => `outer-labels ${d.types.name}_${d.id}`)
        .attr('id', (d) => `${d.types.name}_${d.id}`)
        .style(
          'fill',
          (d) => determineColorContrast(prefData, d),

          // d.types.name === homeType || d.types.name === circleType
          //   ? 'white'
          //   : d.types.name === ovalType
          //   ? '#14142B'
          //   : '#000',
        )
        .attr('font-family', 'Source Sans Pro')
        .attr('font-size', (d) =>
          d.types.name === ovalType ? '0.8em' : '1.4em',
        )
        .attr('font-weight', 'normal')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('pointer-events', 'none') // ensure text labels are not blocking circles from being hovered upon
        //.attr("dy", d => -radiusAccessor(d)-8) // labels are always placed centralized within circle use 'dy' attribute to adjust it
        .attr('y', 0)
        .attr('dy', (d) => {
          if (d.types.name !== ovalType) {
            if (d.types.name === homeType) {
              return 0;
            } else if (
              d.types.name === strategyType &&
              d.name.length < 11
            ) {
              return 0.5;
            } else if (
              d.types.name === strategyType &&
              d.name.length >= 11
            ) {
              return -0.2;
            } else {
              if (d.name.length < 11) {
                return 0.5;
              } else if (d.name.length < 13) {
                return 0;
              } else {
                return -0.6;
              }
            }
          } else {
            if (d.name.length > 30) {
              return -0.6;
            } else if (d.name.length > 22) {
              return -0.5;
            } else if (d.name.length > 18) {
              return -0.7;
            } else if (d.name.length > 8) {
              return 0;
            } else {
              return 0.5;
            }
          }
        })
        // .attr('opacity', 1)
        .style('visibility', 'visible')
        .text((d) =>
          d.name.length > 25 ||
          (d.types.name === homeType && d.name.length > 18)
            ? d.truncated_name + '...'
            : d.name,
        )
        .call(wrap, 80);

      // display centered horizontal header line for outer main circle
      nodeG
        .append('text')
        .filter(
          (d) =>
            (d.types.name === circleType && d.children) ||
            (d.types.name === innerCircleType && d.children),
        )
        .attr('class', 'header-labels')
        .attr('id', (d) => `${d.types.name}_${d.id}`)
        .style(
          'fill',
          (d) => determineColorContrast(prefData, d),
          // if (d.types.name === circleType) {
          //   return 'white';
          // } else {
          //   return 'black';
          // }
        )
        .attr('font-family', 'Source Sans Pro')
        .attr('font-size', '1.4em')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('pointer-events', 'none') // ensure text labels are not blocking circles from being hovered upon
        //.attr("dy", d => -radiusAccessor(d)-8) // labels are always placed centralized within circle use 'dy' attribute to adjust it
        .attr('dx', (d) => {
          const fixedNode = findFixedNode(d);
          if (fixedNode) {
            const parentSelection = nodeG
              .selectAll(`circle[class*='${d.types.name}_${d.id}']`)
              .nodes();
            const selection = nodeG
              .selectAll(
                `circle[class*='${fixedNode.types.name}_${fixedNode.id}']`,
              )
              .nodes();
            const parentX = parentSelection[0].__data__.x;
            const parentRad = parentSelection[0].r.baseVal.value;
            const x = selection[0].__data__.x;
            const y = selection[0].__data__.y;
            const rad = selection[0].r.baseVal.value;
            if (x - parentRad < 0) {
              return -(x - parentRad + 10);
            } else if (x - parentRad >= 5 && x - parentRad <= 5) {
              return -(x - parentRad);
            } else {
              return -(x - parentRad - 10);
            }
          } else {
            return 0;
          }
        })
        .attr('y', (d) => {
          const fixedNode = findFixedNode(d);
          if (fixedNode) {
            const parentSelection = nodeG
              .selectAll(`.${d.types.name}_${d.id}`)
              .nodes();
            const selection = nodeG
              .selectAll(
                `circle[class*='${fixedNode.types.name}_${fixedNode.id}']`,
              )
              .nodes();
            const parentRad = parentSelection[0].r.baseVal.value;
            const x = selection[0].__data__.x;
            const y = selection[0].__data__.y;
            const rad = selection[0].r.baseVal.value;
            if (y - parentRad < 0) {
              return (
                parentRad / 2 +
                (parentSelection[0].__data__.children.length === 1
                  ? 10
                  : 0)
              );
            } else {
              return (
                -parentRad / 2 -
                (parentSelection[0].__data__.children.length === 1
                  ? 10
                  : 0)
              );
            }
          } else {
            return 0;
          }
        })
        // .attr('opacity', 0)
        .style('visibility', 'visible')
        .text((d) =>
          d.name.length > 25 ? d.truncated_name + '...' : d.name,
        )
        .call(wrap, 120);

      // however, on zoom, we want to show the full text label of 'Competence Team' nodes instead of abbreviations
      nodeG
        .append('text')
        .filter((d) => !d.children || d.children.length == 0)

        .style('fill', (d) => determineColorContrast(prefData, d))

        .attr('font-family', 'Source Sans Pro')
        .attr('font-size', '0.3em')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('pointer-events', 'none')
        // .attr("opacity", 0) // do not make the label visible initially
        .style('visibility', 'hidden')
        .text((d) =>
          d.name.length > 30 ? d.truncated_name + '...' : d.name,
        );

      // .call(wrap, 120)

      d3.selectAll('g').raise();
      //.attr("stroke-opacity", d => (d.type === 'cct-coc' | d.type === 'cct-ct') ? 0 : 1)

      //////////////////////////////////////// UPDATE ELEMENT POSITIONS ////////////////////////////////

      simulation.on('tick', () => {
        link
          //.attr("x1", d => d.source.id === "exec" ? width/2 : d.source.x) // fix the 'exec' team node in center of container
          //.attr("y1", d => d.source.id === "exec" ? height/2: d.source.y)
          .attr('x1', (d) => {
            return d.source.x;
          })
          .attr('y1', (d) => d.source.y)
          .attr('x2', (d) => d.target.x)
          .attr('y2', (d) => d.target.y);

        //nodeG.attr("transform", d => `translate(${d.id === "exec" ? width/2 : d.x}, ${d.id === "exec" ? height/2 : d.y})`)
        nodeG
          //.filter(d => d.type !== 'oval')
          .attr('transform', (d) => {
            return `translate(${d.x}, ${d.y})`;
          });
      });

      //////////////////////////////////////// INTERACTIVITY ////////////////////////////////////////
      // all mouseover events
      svg
        .selectAll('g[class*=oval]')
        .attr('cursor', 'pointer')
        .on('mouseover', (event, d) => {
          showLines(d);

          svg
            .selectAll("*[class*='connectionTop_circle_image']")
            .style('opacity', (el) => (el.id === d.id ? 1 : 0.1));

          svg
            .selectAll("*[class*='connectionTop_circle_']")
            .style('opacity', (el) => (el.id === d.id ? 1 : 0.1));

          mouseover(event, d);
        })
        .on('mouseenter', (d) => mouseenter(d))
        .on('mouseout', (d) => {
          svg
            .selectAll("*[class*='connectionTop_circle_image']")
            .style('opacity', 1);

          svg
            .selectAll("*[class*='connectionTop_circle_']")
            .style('opacity', 1);

          mouseleave(d);
        })
        .on('mousemove', function (event, d) {
          return tooltip
            .style('top', event.offsetY - 50 + 'px')
            .style('left', event.offsetX + 'px');
        });

      svg
        .selectAll('circle')
        // .filter(d => d.data && d.data.level < 4)
        .filter((d) => d.data && d.data.level < 5)
        .attr('cursor', 'pointer')
        .on('click', function (event, d) {
          event.stopPropagation();
          currentLevel = d.data ? d.data.level : d.level; //hakuna

          currentSelected = `${d.data.types.name}_${d.data.id}`;

          d3.select(
            `.border-circle_${d.data.types.name}_${d.data.id}`,
          ).style('visibility', 'visible');
          return zoom(d, d3.select(this));
        })
        .on('mouseover', async function (event, d) {
          // d3.select(this).attr("stroke", "#000");
          // d3.select(".border-circle_"+d.id).style("visibility","visible")
          if (d.depth !== currentLevel) {
            return;
          }

          tooltip.text('');
          tooltip
            .style('visibility', d.data ? 'visible' : 'hidden')
            .text(d.data ? d.data.name : d.name);
          // cut text in tooltip

          if (!isFirstInfobox) {
            d3.select('.panel-box-CCT').style('visibility', 'hidden');
            clickCCT(event, d);
            isFirstInfobox = true;
          } else {
            infoBoxTimeVar = await setTimeout(
              function () {
                //Start the timer
                d3.select('.panel-box-CCT').style(
                  'visibility',
                  'hidden',
                );
                clickCCT(event, d);
              }.bind(this),
              200,
            );
          }

          // if(!sticky_box_flag){
          //   setTimeout(function () {
          //   d3.select('.panel').style('visibility', 'hidden')
          // }, 3000 /* miliseconds */);
          // }
        })
        .on('mouseenter', function (d) {
          stickyTimeVar = setTimeout(function () {
            stickyBoxFlag = true;
          }, 300 /* miliseconds */);
        })
        .on('mouseout', async function (d) {
          clearTimeout(infoBoxTimeVar);
          tooltip
            .style('visibility', d.data ? 'visible' : 'hidden')
            .text('');
          // d3.select(this).attr("stroke", null);
          // d3.select(".border-circle_"+d.id).style("visibility","hidden")
          if (!stickyBoxFlag) {
            setTimeout(function () {
              d3.select('.panel').style('visibility', 'hidden');
              d3.selectAll('.child-pointer').style(
                'visibility',
                'hidden',
              );
            }, 300 /* miliseconds */);
          }
          clearTimeout(stickyTimeVar);
          //d3.select('.panel').style('visibility', 'hidden')
        })
        .on('mousemove', function (event, d) {
          return tooltip
            .style('top', event.offsetY - 50 + 'px')
            .style('left', event.offsetX + 'px');
        });

      svg
        .selectAll('circle')
        .filter(
          (d) =>
            d.types?.name === homeType ||
            d.data?.types.name === homeType,
        )
        .on('click', function (event, d) {
          currentSelected = `${
            d.data?.types?.name ? d.data.types.name : d.types.name
          }_${d.data?.id ? d.data.id : d.id}`;

          // event.stopPropagation();
          // d3.selectAll("*[class*='border-circle_']").style("visibility", "hidden");
          // d3.select(".border-circle_exec" ).style("visibility", "visible")
        })
        .on('mouseover', async function (event, d) {
          removeTeamsTable();
          removePeopleTable();
          tooltip
            .style('visibility', 'visible')
            .text(d.data ? d.data.name : d.name);
          if (!isFirstInfobox) {
            d3.select('.panel-box-CCT').style('visibility', 'hidden');
            clickCCT(event, d);
            isFirstInfobox = true;
          } else {
            // tooltip.text('');
            infoBoxTimeVar = await setTimeout(
              function () {
                //Start the timer
                d3.select('.panel-box-CCT').style(
                  'visibility',
                  'hidden',
                );
                clickCCT(event, d);
              }.bind(this),
              200,
            );
          }
          mouseover1(d);
        })
        .on('mouseout', (event, d) => {
          mouseleave(d);
        });

      // search box
      const input = document.querySelector('.search-form');

      input.addEventListener('keyup', function (event) {
        if (event.keyCode === 13) {
          event.preventDefault();
          showSearchResult(event);
        }
      });

      const listContainer = document.querySelector(
        '.search-list-container',
      );

      listContainer.addEventListener('click', function (e) {
        showSearchResult();
      });

      function showSearchResult() {
        let entity = data_flat.find((d) => d.name === input.value); //input: entity name
        let person = findPersonByName(input.value);

        if (person && person.roles) {
          entity = person.roles.find(
            (role) =>
              role.role &&
              (role.role.toLowerCase() === 'lead' ||
                role.role.toLowerCase() === 'speaker' ||
                role.role.toLowerCase() === 'executive board'),
          );

          if (!entity) {
            entity = person.roles[0] || person.teams[0];
          }

          let selection;

          if (entity) {
            if (entity.node.types.name !== ovalType) {
              selection = svg
                .selectAll('circle')
                .filter(
                  '#' + `${entity.node.types.name}_${entity.node.id}`,
                );
            } else {
              selection = svg
                .selectAll('ellipse')
                .filter(
                  '#' + `${entity.node.types.name}_${entity.node.id}`,
                );
              setShowCCT(true);
              toggleCCT({ id: 'cct_all' });
            }
          }

          if (selection) {
            let selection_data = selection.data()[0];
            searchInfoBox(person, input.value, 'person');
            return zoom(selection_data, selection, true);
          } else {
            searchInfoBox(person, input.value, 'person');
          }
        } else if (entity) {
          let selection;

          if (entity.types.name !== ovalType) {
            selection = svg
              .selectAll('circle')
              .filter('#' + `${entity.types.name}_${entity.id}`);
            let selection_data = selection.data()[0];
            searchInfoBox(selection_data, input.value, 'node');
            return zoom(selection_data, selection, true);
          } else {
            selection = svg
              .selectAll('ellipse')
              .filter('#' + `${entity.types.name}_${entity.id}`);
            let selection_data = selection.data()[0];
            setShowCCT(true);
            toggleCCT({ id: 'cct_all' });
            d3.select('.panel-search').style('visibility', 'hidden');
            createCctInfobox(selection_data);
            return zoom(selection_data, selection, true);
          }
        }
      }

      // button to add/remove entities
      // let newData = dataOrig
      // let clickAdd = 0
      // let newBubbles = ['New Bubble', 'New Bubble 2']
      // document.querySelector(".add-entity").addEventListener('click',function (){
      //   //let dataOrig1 = JSON.parse(JSON.stringify(dataOrig))
      //   newData = addEntity(newData, {name: newBubbles[clickAdd]}, "CoC")
      //   d3.select('.nodesWrapper').remove()
      //   updateGraph(JSON.parse(JSON.stringify(newData)))
      //   clickAdd += 1
      // })

      // let clickRemove = 0
      // let deleteBubbles = ["CoC-home", "corporate_purchasing"]
      // document.querySelector(".remove-entity").addEventListener('click',function (){
      //   //let dataOrig1 = JSON.parse(JSON.stringify(dataOrig))
      //   newData = removeEntity(newData, deleteBubbles[clickRemove])
      //   d3.select('.nodesWrapper').remove()
      //   updateGraph(JSON.parse(JSON.stringify(newData)))
      //   clickRemove += 1
      // })

      function zoom(focus, nodeElement, searchMode = false) {
        // Remove all the plus circle
        svg.selectAll("*[class*='connection_circle_']").remove();

        svg
          .selectAll("*[class*='connectionTop_circle_image']")
          .style('opacity', 0);

        svg
          .selectAll("*[class*='connectionTop_circle_']")
          .style('opacity', 0);

        // search box
        const input = document.querySelector('.search-form');
        let SearchMode = searchMode; //input.value !== '' ? true : false;

        // input.addEventListener("keyup", function (event) {
        //   if (event.keyCode === 13) {
        //     event.preventDefault()
        //     let entity = data_flat.find(d => d.name === input.value) //input: entity name

        //     if (entity) {
        //       let selection = svg.selectAll('circle').filter("#" + entity.id)
        //       let selection_data = selection.data()[0]
        //       return zoom(selection_data, selection)
        //     }
        //   }
        // })

        svg.on('click', function (event, d) {
          return zoom(d, d3.select(this));
        });

        d3.selectAll('.breadcrumb').selectAll('li').remove();

        let levels = ['Overview'];

        svg
          .selectAll('text')
          // .attr('opacity', 0)
          .style('visibility', 'hidden');

        svg
          .selectAll('circle')
          // .attr('opacity', 0)
          .style('visibility', 'hidden');
        if (focus) {
          if (SearchMode) {
            currentLevel = focus.data ? focus.depth : 0;
          } else {
          }

          svg
            .selectAll('circle')
            .filter((d) => {
              return (
                (d.data && d.data.level <= currentLevel + 1) ||
                d.types?.name === homeType ||
                d.data?.types.name === homeType ||
                d.level
              );
            })
            // .attr("opacity", 1);
            .style('visibility', 'visible');
          svg
            .selectAll("*[class*='border-circle_']")
            .style('visibility', 'hidden');
          if (!focus.data)
            d3.select(
              `.border-circle_${focus.types.name}_${focus.id}`,
            ).style('visibility', 'visible');
          else
            d3.select(
              `.border-circle_${focus.data.types.name}_${focus.data.id}`,
            ).style('visibility', 'visible');

          let parentNode = '';

          if (focus.parent) {
            parentNode = focus.parent;
            if (focus.parent.parent) {
              parentNode = focus.parent.parent;
              if (focus.parent.parent.parent) {
                parentNode = focus.parent.parent.parent;
                levels.push(focus.parent.parent.parent.data.id);
              }
              levels.push(focus.parent.parent.data.id);
            }
            levels.push(focus.parent.data.id);
          }

          levels.push(focus.data ? focus.data.id : focus.id);

          if (
            focus.children &&
            focus.children.length &&
            !searchMode
          ) {
            //levels.push(focus.children[0].data ? focus.children[0].data.id : focus.children[0].id)

            focus.children.forEach((element) => {
              if (!parentNode) {
                parentNode = focus;
              }

              let hasLinks = false;
              let linkedNodes = [
                element.data ? element.data : element,
              ];
              let linkedDescriptions = [];

              linkListUpdated.forEach((link) => {
                if (
                  (element.id && element.id === link.source.id) ||
                  (element.data && element.data.id === link.source.id)
                ) {
                  hasLinks = true;
                  svg
                    .selectAll(
                      `circle[class*='${link.target.types.name}_${link.target.id}']`,
                    )
                    .style('zIndex', 2000);
                  linkedNodes.push(link.target);
                  linkedDescriptions.push(link.description);
                }
              });

              // Add the functionality to see the connection on those circles that has one
              if (hasLinks) {
                const containerSelection = svg.selectAll(
                  `g[class*='${
                    parentNode.types
                      ? parentNode.types.name
                      : parentNode.data.types.name
                  }_${
                    parentNode.id ? parentNode.id : parentNode.data.id
                  }']`,
                );
                const parent = svg
                  .select(
                    `circle[id*='${
                      parentNode.types
                        ? parentNode.types.name
                        : parentNode.data.types.name
                    }_${
                      parentNode.id
                        ? parentNode.id
                        : parentNode.data.id
                    }']`,
                  )
                  .nodes();
                const child = svg
                  .select(
                    `circle[id*='${
                      element.types
                        ? element.types.name
                        : element.data.types.name
                    }_${element.id ? element.id : element.data.id}']`,
                  )
                  .nodes();

                const rad = parent[0].r.baseVal.value;
                const childRad = child[0].r.baseVal.value;
                const childX = child[0].__data__.x;
                const childY = child[0].__data__.y;

                containerSelection
                  .append('image')
                  .attr(
                    'id',
                    `connection_circle_image_${
                      element.id || element.data?.id
                    }`,
                  )
                  .attr(
                    'class',
                    `connection_circle_image_${
                      element.id || element.data?.id
                    }`,
                  )
                  .attr('xlink:href', connectionsIcon)
                  .attr(
                    'x',
                    childX -
                      rad +
                      childRad -
                      (childRad < 20 ? 2 : 6) -
                      (childRad < 20 ? 5 / 2 : 7 / 2),
                  )
                  .attr(
                    'y',
                    childY -
                      rad -
                      childRad / 2 -
                      (childRad < 20 ? 5 / 2 : 7 / 2),
                  )
                  .attr('width', childRad < 20 ? 5 : 7)
                  .attr('height', childRad < 20 ? 5 : 7);

                containerSelection
                  .append('circle')
                  .attr(
                    'id',
                    `connection_circle_${
                      element.id || element.data?.id
                    }`,
                  )
                  .attr(
                    'class',
                    `connection_circle_${
                      element.id || element.data?.id
                    }`,
                  )
                  .attr('r', childRad < 20 ? 5 : 7)
                  .attr('cx', () => {
                    return (
                      childX -
                      rad +
                      childRad -
                      (childRad < 20 ? 2 : 6)
                    );
                  })
                  .attr('cy', () => {
                    return childY - rad - childRad / 2;
                  })
                  .style('cursor', 'pointer')
                  .style('fill', 'rgba(129, 198, 153, .2)')
                  .style('stroke', '#81C69D')
                  .style('stroke-width', '1px')
                  .on('mouseover', function (e) {
                    d3.select(this)
                      .transition()
                      .duration(300)
                      .style('fill', 'rgba(129, 198, 153, .5)');
                  })
                  .on('mouseout', function (e) {
                    d3.select(this)
                      .transition()
                      .duration(300)
                      .style('fill', 'rgba(129, 198, 153, .2)');
                  })
                  .on('click', (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    showConnectionsBox(
                      linkedNodes,
                      linkedDescriptions,
                    );

                    // Append the backdrop to handle the second click and restore the view
                    const leftBackdrop =
                      document.createElement('div');
                    leftBackdrop.style.width = 'calc(98vw - 400px)';
                    leftBackdrop.style.height = '100vh';
                    leftBackdrop.style.display = 'block';
                    leftBackdrop.style.position = 'absolute';
                    leftBackdrop.style.top = 0;
                    leftBackdrop.style.right = '400px';
                    leftBackdrop.style.backgroundColor =
                      'transparent';
                    leftBackdrop.style.zIndex = 1000;
                    leftBackdrop.id = 'connections-left-backdrop';

                    const topBackdrop = document.createElement('div');
                    topBackdrop.style.width = 'calc(98vw)';
                    topBackdrop.style.height = '120px';
                    topBackdrop.style.display = 'block';
                    topBackdrop.style.position = 'absolute';
                    topBackdrop.style.top = 0;
                    topBackdrop.style.right = 0;
                    topBackdrop.style.backgroundColor = 'transparent';
                    topBackdrop.style.zIndex = 1000;
                    topBackdrop.id = 'connections-top-backdrop';

                    const bottomBackdrop =
                      document.createElement('div');
                    bottomBackdrop.style.width = 'calc(98vw)';
                    bottomBackdrop.style.height = '245px';
                    bottomBackdrop.style.display = 'block';
                    bottomBackdrop.style.position = 'absolute';
                    bottomBackdrop.style.top = '820px';
                    bottomBackdrop.style.right = 0;
                    bottomBackdrop.style.backgroundColor =
                      'transparent';
                    bottomBackdrop.style.zIndex = 1000;
                    bottomBackdrop.id = 'connections-bottom-backdrop';

                    const sidebar =
                      document.querySelector('.side-right');
                    sidebar.appendChild(leftBackdrop);
                    sidebar.appendChild(topBackdrop);
                    sidebar.appendChild(bottomBackdrop);

                    const handleSecondClick = (e) => {
                      if (
                        e.target.id === 'connections-left-backdrop' ||
                        e.target.id === 'connections-top-backdrop' ||
                        e.target.id === 'connections-bottom-backdrop'
                      ) {
                        e.preventDefault();
                        e.stopPropagation();
                        removeConnectionsTable();
                        d3.select('.panel-connections').style(
                          'visibility',
                          'hidden',
                        );
                        svg
                          .selectAll('text')
                          .filter((el) => {
                            return (
                              el.id !==
                              (element.id
                                ? element.id
                                : element.data.id)
                            );
                          })
                          .attr('opacity', 1);
                        zoom(focus, nodeElement, searchMode);
                        mouseleave(nodeElement);
                        const backdropTopRemove =
                          sidebar.querySelector(
                            '#connections-top-backdrop',
                          );
                        const backdropLeftRemove =
                          sidebar.querySelector(
                            '#connections-left-backdrop',
                          );
                        const backdropBottomRemove =
                          sidebar.querySelector(
                            '#connections-bottom-backdrop',
                          );
                        sidebar.removeChild(backdropTopRemove);
                        sidebar.removeChild(backdropLeftRemove);
                        sidebar.removeChild(backdropBottomRemove);
                        document.removeEventListener(
                          'click',
                          handleSecondClick,
                        );
                      }
                    };

                    document.addEventListener(
                      'click',
                      handleSecondClick,
                    );

                    const CCTInfoBoxWrapper =
                      document.querySelector('.panel-box-CCT');
                    CCTInfoBoxWrapper.style.visibility = 'hidden';
                    d3.select('.panel').style('visibility', 'hidden');
                    d3.select('.child-pointer').style(
                      'visibility',
                      'hidden',
                    );

                    g.transition()
                      .duration(750)
                      .attr('transform', function () {
                        return `translate(${width / 7},110)scale(1)`;
                      });

                    svg.selectAll('ellipse').attr('opacity', 0.1);

                    svg.selectAll('image').attr('opacity', 0);

                    svg
                      .selectAll('text.zoom-outer-oval')
                      .style('visibility', 'hidden');

                    svg
                      .selectAll('text')
                      .filter((el) => {
                        return el.id
                          ? el.id !==
                              (element.id
                                ? element.id
                                : element.data.id)
                          : el.data.id !==
                              (element.id
                                ? element.id
                                : element.data.id);
                      })
                      .attr('opacity', 0);

                    svg
                      .selectAll('text.outer-labels')
                      .filter((el) => {
                        return el.id
                          ? el.id !==
                              (element.id
                                ? element.id
                                : element.data.id)
                          : el.data.id !==
                              (element.id
                                ? element.id
                                : element.data.id);
                      })
                      .attr('opacity', 0.1);

                    svg
                      .selectAll('circle')
                      .filter((el) => {
                        return el.id
                          ? el.id !==
                              (element.id
                                ? element.id
                                : element.data.id)
                          : el.data.id !==
                              (element.id
                                ? element.id
                                : element.data.id);
                      })
                      .attr('opacity', 0.1);

                    svg.selectAll('polygon').attr('opacity', 0.1);

                    showCirclesConnected(
                      element.id ? element : element.data,
                      linkListUpdated,
                      true,
                    );
                  });
              }
            });

            if (
              focus.children[0].data &&
              focus.children[0].data.children &&
              focus.children[0].data.children[0].data
            ) {
              //levels.push(focus.children[0].data.children[0].data.id)
              // if(focus.children[0].data.children[0].data.children){
              //   levels.push(focus.children[0].data.children[0].data.children[0].data.id)
              // }
            } else {
              if (focus.children[0].children) {
                // if(focus.children[0].children[0].data){
                //   levels.push(focus.children[0].children[0].data.id)
                // } else {
                //   levels.push(focus.children[0].children[0].id)
                // }
                //if(focus.children[0].children[0].children){
                //levels.push(focus.children[0].children[0].children[0].id)
                //}
              }
            }
          }

          searchMode = false;

          let crumbs = d3
            .selectAll('.breadcrumb')
            .selectAll('li')
            .data(levels.filter((d) => d))
            .enter()
            .append('li')
            .style('cursor', 'pointer');

          crumbs
            .append('span')
            .attr('class', (d) => {
              return d;
            })
            .html((d) => d.split('-')[0])
            .on('click', function (event, d) {
              let selection = svg.selectAll('circle').filter('#' + d);
              let selection_data = selection.data()[0];
              event.stopPropagation();
              return zoom(selection_data, selection);
            })
            .style('color', (d, i) =>
              i === levels.length - 1 ? 'black' : 'lightgrey',
            );

          let k = focus.data
            ? width / (focus.r * 5)
            : width / (radiusAccessor(focus) * 5);
          let group = focus.data
            ? data['nodes'].find(
                (d) =>
                  `${d.types.name}_${d.id}` === focus.data.groupId,
              )
            : { x: 0, y: 0 };
          let radius = focus.data ? radiusAccessor(focus) : 0;

          if (
            focus.types?.name === homeType ||
            focus.data?.types.name === homeType
          ) {
            focus.x = outer_dim / 2;
            focus.y = outer_dim / 2;
          }

          const arr = nodeElement.attr('transform')
            ? getTranslation(nodeElement.attr('transform'))
            : [];
          const x = nodeElement.attr('transform')
            ? arr[0]
            : focus.x - radius;
          const y = nodeElement.attr('transform')
            ? arr[1]
            : focus.y - radius;

          // create an illusion that clicked node is being zoomed into by shifting container position and zooming into the container (Note: the circle itself is not being transformed/scaled)
          g.transition()
            .duration(750)
            .attr('transform', function () {
              return `translate(${-(
                (group.x + x) * k -
                width / 2
              )},${-((group.y + y) * k - height / 2)})scale(${k})`;
            });

          // svg.selectAll("text")
          //   .transition().duration(250).delay(150)
          //   .attr('opacity', 1)

          svg
            .selectAll('text')
            .filter((d) => {
              // if(d.type === undefined )
              return d;
            })
            .transition()
            .duration(250)
            .delay(150)
            // .attr('opacity', 1)
            .style('visibility', 'hidden');

          // top round label out of circle
          svg
            .selectAll('text.arc-labels')
            .selectAll('textPath')
            .attr('font-size', '0.4em')
            .text((d) => d.data.name)
            .style('visibility', (d) => {
              return d.data.level < currentLevel + 1
                ? 'visible'
                : 'hidden';
            });

          // hide visibility of 'Center of Competence' node labels
          svg
            .selectAll('text.horizontal-labels')
            .transition()
            .duration(250)
            .delay(150)
            // .attr('opacity', 0)
            // .style('visibility', 'visible')
            .style('visibility', (d) => {
              return currentLevel == d.data.level
                ? 'hidden'
                : 'hidden';
            });

          svg
            .selectAll('text.zoom-3-horizontal-labels')
            .transition()
            .duration(250)
            .delay(150)
            // .style("visibility", "hidden")
            .style('visibility', (d) => {
              return currentLevel === 2 ||
                (currentLevel === 3 && !d.data.children)
                ? 'visible'
                : 'hidden';
            });

          svg
            .selectAll('text.zoom-4-horizontal-labels')
            .transition()
            .duration(250)
            .delay(150)
            .style('visibility', (d) => {
              return currentLevel > 2 ? 'visible' : 'hidden';
            });

          svg
            .selectAll('text.small-horizontal-labels')
            .transition()
            .duration(250)
            .delay(150)
            .attr('opacity', 1)
            .style('visibility', (d) => {
              return currentLevel === 1 ||
                (currentLevel === 2 && !d.data.children)
                ? 'visible'
                : 'hidden';
            })
            .attr('font-size', (d) => {
              if (currentLevel === 1) {
                if (d.r < 25) {
                  return '0.30em';
                } else if (d.r < 20) {
                  return '0.25em';
                } else if (d.r < 15) {
                  return '0.20em';
                } else {
                  // console.log(d.data.name)
                  // return '0.4em';
                  return d.r * 0.015 + 'em';
                }
              } else if (d.data.level < 3) {
                return d.r * 0.015 + 'em';
              } else {
                return '0.5em';
              }
            });

          svg
            .selectAll('.zoom-2-horizontal-labels')
            .filter((d) => {
              // if(d.type === 'circle'){
              return d;
              // }
            })
            .transition()
            .duration(250)
            .delay(150)
            // .attr('opacity', 1)
            .style('visibility', 'hidden');

          svg
            .selectAll('text.header-labels')
            .transition()
            .duration(250)
            .delay(150)
            // .attr('opacity', 0)
            //for search zoom action of main outer circle, added level condition here
            .style('visibility', (d) =>
              currentLevel === 0 ? 'visible' : 'hidden',
            );

          svg
            .selectAll('text.outer-labels')
            .transition()
            .duration(250)
            .delay(150)
            // .attr('opacity', 0)
            .style('visibility', 'visible');

          // show full text label of 'Competence Team' nodes
          svg
            .selectAll('text.zoom-outer-labels')
            .style('font-size', '.8em')
            .style(
              'fill',
              (d) => determineColorContrast(prefData, d),

              // if (
              //   d.types.name === circleType ||
              //   d.types.name === homeType
              // ) {
              //   return 'white';
              // }
            )
            .transition()
            .duration(250)
            .delay(250)
            //  .attr('opacity', d => (d.id !== 'corporate_governance' | d.id !== 'communications') ? 1 : 0)
            .style('visibility', (d) => 'hidden');
        } else {
          input.value = '';
          SearchMode = false;
          currentLevel = 0;
          stickyBoxFlag = false;
          stickyBoxCctFlag = false;
          currentSelected = null;

          const CCTInfoBoxWrapper =
            document.querySelector('.panel-box-CCT');
          CCTInfoBoxWrapper.style.visibility = 'hidden';
          // d3.select('.panel-box-CCT').style('visibility', 'hidden');
          d3.select('.panel').style('visibility', 'hidden');
          d3.select('.child-pointer').style('visibility', 'hidden');

          svg
            .selectAll("*[class*='connectionTop_circle_image']")
            .style('opacity', 1);

          svg
            .selectAll("*[class*='connectionTop_circle_']")
            .style('opacity', 1);

          svg
            .selectAll('circle')
            .filter((d) => {
              return (
                (d.data && d.data.level <= 1) ||
                d.types?.name === homeType ||
                d.data?.types.name === homeType ||
                d.level ||
                d.data.types.name === fixedCircleType
              );
            })
            // .attr("opacity", 1)
            .style('visibility', 'visible');
          d3.selectAll(`.breadcrumb`).selectAll('li').remove();
          d3.selectAll("*[class*='border-circle_']").style(
            'visibility',
            'hidden',
          );

          // reset to original position
          g.transition()
            .duration(750)
            .attr('transform', function () {
              return `translate(${width / 7},110)scale(1)`;
            });

          // reset to original label visibility
          svg
            .selectAll('text.outer-labels')
            // .attr("opacity", 1)
            .style('visibility', 'visible');

          svg
            .selectAll('text.arc-labels')
            .filter((d) => {
              return d.data.level === 1;
            })
            .attr('opacity', 1)
            .style('visibility', 'visible');

          // hide all sub level's horizontal labels
          svg
            .selectAll('text.horizontal-labels')
            // .attr("opacity", 1)
            .style('visibility', 'hidden');

          // display main outer and inner circle's label
          svg
            .selectAll('text.header-labels')
            .style('visibility', 'visible');

          svg
            .selectAll('.donutText')
            // .attr('opacity', 1)
            .style('visibility', 'visible');
          svg
            .selectAll('text.arc-labels textPath')
            .attr('font-size', '1em')
            .text((d) => d.data.truncated_name)
            .style('visibility', (d) => {
              return d.data.level > 1 ? 'hidden' : 'visible';
            });

          svg
            .selectAll('text.arc-labels textPath')
            .each(function (d, i) {
              d3.select(this).call(crop, d.r);
            });

          svg
            .selectAll('text.small-horizontal-labels')
            .transition()
            .duration(250)
            .delay(150)
            .style('visibility', (d) => {
              return d.data.types.name === fixedCircleType
                ? 'visible'
                : 'hidden';
            })
            .attr('font-size', (d) => {
              if (currentLevel === 0) {
                return '0.6em';
              }
            });
        }
      }
      // hide all CCT by default
      toggleCCT({ id: 'cct_none' });

      return showSearchResult();
    }

    function createCctInfobox(d) {
      let panelWrapperCCT = d3.select('.panel-box-CCT');
      //d3.select('.btn-legend').style('opacity', clicked ? 1 : 0)

      let speakersLst = findPersonByRole(d.id, 'speaker');

      let executiveBoardId;
      data.nodes.forEach((node) => {
        if (node.types.name === homeType) {
          executiveBoardId = node.id;
        }
      });

      let speaker_cct =
        speakersLst && speakersLst.length ? '' : 'N.N.';
      speakersLst &&
        speakersLst.map((person, i) => {
          if (i + 1 < speakersLst.length)
            speaker_cct += person.name + ', ';
          else speaker_cct += person.name;
        });

      let executiveList = findPersonByRole(executiveBoardId, 'lead');

      let executives_cct =
        executiveList && executiveList.length ? '' : 'N.N.';
      executiveList &&
        executiveList.map((person, i) => {
          if (i + 1 < executiveList.length)
            executives_cct += person.name + ', ';
          else executives_cct += person.name;
        });

      if (
        (d.types.name && d.types.name === circleType) ||
        (d.types.name && d.types.name === ovalType)
      ) {
        if (
          speakersLst &&
          speakersLst.length &&
          speakersLst.length > 1 &&
          speaker_cct !== 'N.N.'
        ) {
          panelWrapperCCT
            .select('.panel-header2-title')
            .text('Speakers: ');
        } else {
          panelWrapperCCT
            .select('.panel-header2-title')
            .text('Speaker: ');
        }
      } else {
        panelWrapperCCT.select('.panel-header2-title').text('');
      }

      panelWrapperCCT.select('.panel-header span').text(d.name);
      panelWrapperCCT.select('.panel-header2 span').text(speaker_cct);
      panelWrapperCCT
        .select('.panel-bio-value')
        .html(d.description ? d.description : '');
      panelWrapperCCT
        .select('.parent-node')
        .text(`Executive Board: ${executives_cct}`)
        .style('display', () => {
          return d.types.name === homeType ? 'none' : 'block';
        });
      panelWrapperCCT
        .select('.self-node')
        .text(d.name + ' (' + speaker_cct + ')');
      const members = getPeopleRoleForNodeV2(json_data.people, d.id);

      // Construct the people table
      createTable(
        members,
        ['people_name', 'role', 'location'],
        ['Name', 'Role', 'Country'],
        'people-table-div',
      );

      d3.select('.panel-box-CCT').style('visibility', 'visible');
    }

    function mouseover1(d) {
      svg.selectAll('line.exec-source').attr('stroke-opacity', 1);
    }

    function showLines(d) {
      svg
        .selectAll(`*[class*='${d.types.name}-${d.id}_']`)
        .attr('stroke-opacity', 1);

      // fix line with no display oval
      svg
        .selectAll(`*[class*='${d.types.name}-${d.id}_|oval']`)
        .attr('stroke-opacity', 0);
    }

    function createShadows() {
      var defs = svg.append('defs');

      var filter = defs
        .append('filter')
        .attr('id', 'dropshadow')
        .attr('height', '200%')
        .attr('width', '200%');

      filter
        .append('feGaussianBlur')
        .attr('in', 'SourceAlpha')
        .attr('stdDeviation', 3)
        .attr('result', 'blur');
      filter
        .append('feOffset')
        .attr('in', 'blur')
        .attr('dx', 3)
        .attr('dy', 3)
        .attr('result', 'offsetBlur');
      filter
        .append('feFlood')
        .attr('in', 'offsetBlur')
        .attr('flood-color', '#5EB990')
        .attr('flood-opacity', '1')
        .attr('result', 'offsetColor');
      filter
        .append('feComposite')
        .attr('in', 'offsetColor')
        .attr('in2', 'offsetBlur')
        .attr('operator', 'in')
        .attr('result', 'offsetBlur');

      var feMerge = filter.append('feMerge');

      feMerge.append('feMergeNode').attr('in', 'offsetBlur');
      feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
    }

    function showCirclesConnected(d, linkList, connectionsLink) {
      const conn = [];
      linkList.forEach((link) => {
        if (
          link.source.id === d.id &&
          link.types.name !== linkCocTeamType
        ) {
          conn.push({
            ...link.target,
            cocTeamConn: false,
          });
        } else if (
          link.source.id === d.id &&
          link.types.name === linkCocTeamType
        ) {
          conn.push({
            ...link.target,
            cocTeamConn: true,
          });
        }
      });

      createShadows();

      conn.forEach((ele) => {
        svg
          .selectAll(`circle#${ele.types.name}_${ele.id}`)
          .attr(
            'opacity',
            ele.cocTeamConn
              ? connectionsLink
                ? 1
                : ele.level === 1
                ? 0.1
                : 0
              : connectionsLink
              ? ele.level === 1
                ? 0.1
                : 0
              : 1,
          )
          .attr('filter', ele.cocTeamConn ? 'url(#dropshadow)' : '')
          .attr('stroke-width', 3)
          .attr('stroke', ele.cocTeamConn ? '#5EB990' : '');
        /*           .style('visibility', (el) => {
            if (!connectionsLink) {
              if (checkParent(el, d.id)) {
                return 'hidden';
              } else {
                return 'visible';
              }
            } else {
              return 'visible';
            }
          }) */
        svg
          .selectAll(`oval#${ele.types.name}_${ele.id}`)
          .attr('opacity', 1)
          .style('visibility', 'visible');
        svg
          .selectAll(`text.outer-labels#${ele.types.name}_${ele.id}`)
          .attr(
            'opacity',
            ele.cocTeamConn
              ? connectionsLink
                ? 1
                : 0
              : connectionsLink
              ? 0
              : 1,
          )
          .style('visibility', (d) => {
            if (connectionsLink) {
              return 'visible';
            } else {
              return currentLevel > 0 ? 'hidden' : 'visible';
            }
          });
        svg
          .selectAll(`text.header-labels#${ele.types.name}_${ele.id}`)
          .attr(
            'opacity',
            ele.cocTeamConn
              ? connectionsLink
                ? 1
                : 0
              : connectionsLink
              ? 0
              : 1,
          )
          .style('visibility', 'visible');
        svg
          .selectAll(
            `text.horizontal-labels#${ele.types.name}_${ele.id}`,
          )
          .attr(
            'opacity',
            ele.cocTeamConn
              ? connectionsLink
                ? 1
                : 0
              : connectionsLink
              ? 0
              : 1,
          )
          .style('visibility', 'visible');
        svg
          .selectAll(
            `text.small-horizontal-labels#${ele.types.name}_${ele.id}`,
          )
          .attr(
            'opacity',
            ele.cocTeamConn
              ? connectionsLink
                ? 1
                : 0
              : connectionsLink
              ? 0
              : 1,
          )
          .style('visibility', 'visible');
      });

      if (!connectionsLink) {
        svg
          .selectAll('image')
          .filter((el) => el.id !== d.id)
          .attr('opacity', 0.1);
      } else {
      }
    }

    function mouseenter() {
      stickyCctTimeVar = setTimeout(function () {
        stickyBoxCctFlag = true;
      }, 300 /* miliseconds */);
    }

    async function mouseover(event, ele) {
      tooltip.style('visibility', 'visible').text(ele.name);

      if (!isFirstInfobox) {
        d3.select('.panel').style('visibility', 'hidden');
        clickCCT(event, ele);
        isFirstInfobox = true;
      } else {
        infoBoxTimeVar = await setTimeout(
          function () {
            //Start the timer
            d3.select('.panel').style('visibility', 'hidden');
            clickCCT(event, ele);
          }.bind(this),
          200,
        );
      }

      // find circles connected to oval
      let conn = ele.connections && ele.connections.map((d) => d);
      let connWithTypes = conn.map((connection) => {
        connection.types = findTypes(
          data.allNodes,
          connection.nodeConnection,
        );
        return connection;
      });

      // diminish the opacity of ovals not hovered upon
      svg
        .selectAll('ellipse')
        .filter((d) => {
          return d.id !== ele.id;
        })
        .attr('opacity', 1);

      svg
        .selectAll('text.zoom-outer-oval')
        .filter((d) => d.id !== ele.id)
        // .attr("opacity", 0.1);
        .style('visibility', 'hidden');

      svg
        .selectAll('text.outer-labels')
        .filter((d) => d.id !== ele.id)
        .attr('opacity', (d) =>
          d.types.name === ovalType && d.id !== ele.id ? 1 : 0.1,
        );
      // .style('visibility', 'hidden')

      // diminish the opacity of non-connected circles (including nested circles)
      svg
        .selectAll('circle')
        .filter((d) => {
          return (
            (d.data && d.data.level <= currentLevel + 1) ||
            d.types?.name === homeType ||
            d.data?.types.name === homeType ||
            d.level ||
            d.data.types.name === fixedCircleType
          );
        })
        .attr('opacity', 0.1);
      // .style('visibility', 'hidden')

      svg
        .selectAll('text.arc-labels')
        .attr('opacity', 0)
        .style('visibility', 'hidden');
      svg
        .selectAll('text.horizontal-labels')
        // .attr("opacity", 0)
        .style('visibility', 'hidden');
      svg
        .selectAll('text.header-labels')
        // .attr("opacity", 0)
        .style('visibility', 'hidden');
      svg
        .selectAll('text.small-horizontal-labels')
        // .attr("opacity", 0)
        .style('visibility', 'hidden');

      connWithTypes.forEach((ele) => {
        svg
          .selectAll(`circle#${ele.types}_${ele.nodeConnection}`)
          .attr('opacity', 1)
          .style('visibility', (d) => {
            return 'visible';
          });
        svg
          .selectAll(`oval#${ele.types}_${ele.nodeConnection}`)
          .attr('opacity', 1)
          .style('visibility', 'visible');
        svg
          .selectAll(
            `text.outer-labels#${ele.types}_${ele.nodeConnection}`,
          )
          .attr('opacity', 1)
          .style('visibility', (d) => {
            return currentLevel > 0 ? 'hidden' : 'visible';
          });
        svg
          .selectAll(
            `text.header-labels#${ele.types}_${ele.nodeConnection}`,
          )
          .attr('opacity', 1)
          .style('visibility', 'visible');
        svg
          .selectAll(
            `text.horizontal-labels#${ele.types}_${ele.nodeConnection}`,
          )
          .attr('opacity', 1)
          .style('visibility', 'visible');
        svg
          .selectAll(
            `text.small-horizontal-labels#${ele.types}_${ele.nodeConnection}`,
          )
          .attr('opacity', 1)
          .style('visibility', 'visible');
      });

      /*let CoC_conn = [];
      let CT_conn = [];
      conn &&
        conn.forEach((d) => {
          if (d.split("-")[0] === "CoC") {
            CoC_conn.push(d.split("-")[1]);
          } else if (d.split("-")[0] === "CT") {
            CT_conn.push(d.split("-")[1]);
          }
        });
      let contains = containsAny(CoC_conn, CT_conn);

      if (contains.bool) {
        contains.item.forEach((el) => {
          svg
            .selectAll("text.arc-labels#" + `CoC-${el}`)
            // .attr("opacity",1)
            .style("visibility", "visible");
          svg
            .selectAll("text.header-labels#" + `CoC-${el}`)
            // .attr("opacity", 0)
            .style("visibility", "hidden");
        });
      }*/

      // diminish opacity of all connector lines
      //svg.selectAll("line").attr("stroke-opacity", 0);
      //.attr("stroke-opacity", d => (d.type === 'cct-coc' | d.type === 'cct-ct') ? 0 : 0.1)

      svg.selectAll('polygon').attr('opacity', 0.1);

      function containsAny(source, target) {
        var result = source.filter(function (item) {
          return target.indexOf(item) > -1;
        });
        return { bool: result.length > 0, item: result };
      }
    }

    function mouseleave(obj = null) {
      tooltip.text('');
      tooltip.style('visibility', 'hidden');
      if (!stickyBoxCctFlag) {
        setTimeout(function () {
          d3.select('.panel-box-CCT').style('visibility', 'hidden');
        }, 3000 /* miliseconds */);
      }
      clearTimeout(infoBoxTimeVar);
      clearTimeout(stickyCctTimeVar);

      svg
        .selectAll('ellipse')
        .attr('opacity', 1)
        .style('visibility', 'visible');

      svg
        .selectAll('text.zoom-outer-oval')
        // .attr("opacity", 0.1);
        .style('visibility', () =>
          currentLevel > 0 ? 'visible' : 'hidden',
        );

      svg
        .selectAll('circle')
        .attr('opacity', 1)
        .attr('filter', (d) => {})
        .attr('stroke-width', 0)
        .style('visibility', (d) => {
          return (d?.data && d?.data.level < currentLevel + 2) ||
            d?.types?.name === homeType ||
            d?.data?.types.name === homeType ||
            d?.level ||
            d.data.types.name === fixedCircleType
            ? 'visible'
            : 'hidden';
        });
      d3.selectAll("*[class*='border-circle_']").style(
        'visibility',
        'hidden',
      );
      if (currentSelected !== null) {
        d3.select('.border-circle_' + currentSelected).style(
          'visibility',
          'visible',
        );
      }

      svg
        .selectAll(`text.small-horizontal-labels`)
        .attr('opacity', 1)
        .style('visibility', (d) => {
          if (
            ((currentLevel === d.depth || !d.children) &&
              currentLevel !== 0) ||
            (d.data.types.name === fixedCircleType &&
              currentLevel <= d.depth)
          ) {
            return 'visible';
          }
          {
            return 'hidden';
          }
        });

      svg
        .selectAll('text.outer-labels')
        .attr('opacity', 1)
        .style('visibility', (d) => {
          if (currentLevel === 0 || currentLevel === d.level) {
            return 'visible';
          }
          {
            return 'hidden';
          }
        });

      svg
        .selectAll('text.header-labels')
        // .attr("opacity", 0)
        // .style('visibility', 'hidden');
        .style('visibility', (d) => {
          return currentLevel > 0 ? 'hidden' : 'visible';
        });

      svg
        .selectAll('text.arc-labels')
        .filter((d) => d.data.level === 1)
        .attr('opacity', 1)
        .style('visibility', 'visible');

      svg
        .selectAll('text.horizontal-labels')
        .attr('opacity', 1)
        .style('visibility', 'hidden');

      svg.selectAll('line').attr('stroke-opacity', 0);
      //.attr("stroke-opacity", d => (d.type === 'cct-coc' | d.type === 'cct-ct') ? 0 : 1)

      svg
        .selectAll('polygon')
        .attr('opacity', 1)
        .style('visibility', 'visible');

      svg
        .selectAll('text.header-labels')
        .selectAll('[id^=CoC]')
        .attr('opacity', 0)
        .style('visibility', 'visible');

      svg
        .selectAll('text.arc-labels')
        .selectAll('[id^=CoC]')
        .attr('opacity', 1)
        .style('visibility', 'visible');

      svg
        .selectAll('text.horizontal-labels')
        .selectAll('[id^=CoC]')
        .attr('opacity', 0)
        .style('visibility', 'hidden');

      svg.selectAll('image').attr('opacity', 1);
    }

    function drawHexagons(nodeElement, data, options) {
      const width = options.width;
      const height = options.height;
      const pack = (data) =>
        d3.pack().size([width, height]).padding(3)(
          d3
            .hierarchy(data)
            .sum((d) => d.value)
            .sort((a, b) => b.value - a.value),
        );

      const root = pack(data);
      let focus = root;

      //   const nodeG = g
      //   .append("g")
      //   .attr("class", "nodesWrapper")
      //   .selectAll("g")
      //   .data(nodes)
      //   .join("g")
      //   .attr("class", (d) => d.type);

      // nodeG
      //   .filter((d) => d.type === "oval")
      //   .call(drag(simulation, link))
      //   .append("ellipse")
      //   .attr("id", (d) => d.id) // tag each entity by its unique ID
      //   .attr("class", (d) => d.groupId) //
      //   .attr("rx", (d) => radiusAccessor(d) * 1.25)
      //   .attr("ry", (d) => radiusAccessor(d) * 0.75)
      //   .attr("fill", "white")
      //   .attr("stroke", color(0))
      //   .attr("stroke-width", "2px");
      const node = nodeElement
        .append('g')
        //   .attr("class", "nodesWrapper")
        .selectAll('g')
        .data(root.descendants().slice(1))
        .join('g');

      node
        .append('circle')
        .attr(
          'class',
          (d) => `border-circle_${d.data.types.name}_${d.data.id}`,
        )
        .attr('stroke', (d) => color(d.depth))
        .attr('stroke-width', (d) => {
          return d.depth < 2 ? '2px' : '1px';
        })
        .attr('fill', 'transparent')
        .attr(
          'transform',
          (d) => `translate(${d.x - root.x},${d.y - root.y})`,
        )
        .attr('r', (d) => {
          return d.depth < 2 ? d.r + 3 : d.r + 1;
        })
        .style('visibility', 'hidden');

      node
        .append('circle')
        .attr('id', (d) => `${d.data.types.name}_${d.data.id}`)
        .attr('class', (d) => d.data.groupId)
        .attr(
          'fill',
          (d) => {
            if (d.data.types.name === sustainabilityType) {
              return `url(#radial-gradient3)`;
            } else if (d.data.types.name === strategyType) {
              return `url(#radial-gradient2)`;
            } else {
              return `url(#radial-gradient${d.depth})`;
            }
          },
          // (d.data.groupId === "corporate_governance") |
          // (d.data.groupId === "communications")
          //   ? `url(#radial-gradient${d.depth + 1})`
          //   : d.data.groupId === "CoC-global_people" && d.data.id === "EG-3-Expert" ?
          //   `url(#radial-gradient${d.depth + 1})` : `url(#radial-gradient${d.depth})`
        )
        .attr(
          'transform',
          (d) => `translate(${d.x - root.x},${d.y - root.y})`,
        )
        .attr('r', (d) => {
          if (
            d.parent.children.length === 1 &&
            findFixedNode(d.parent) &&
            d.depth === 1
          ) {
            return 60;
          } else {
            return d.r;
          }
        })
        .style('visibility', (d) => {
          return d.depth > 0
            ? d.data.types.name === fixedCircleType
              ? 'visible'
              : 'hidden'
            : 'visible';
        });

      function getSize(d) {
        if (d.depth === 0) {
          return 100;
        } else if (d.depth === 1) {
          return 20;
        } else if (d.depth === 2) {
          return 10;
        } else if (d.depth === 3) {
          return 5;
        } else {
          return 5;
        }
      }

      // parent label texts along paths
      let P = nodeElement
        .append('g')
        .attr('pointer-events', 'none') // ensure text labels are not blocking circles from being hovered upon
        .selectAll('text')
        .data(
          root
            .descendants()
            .filter((d) => d.children && d.children.length > 1),
        )
        .join('text')
        .attr('class', 'arc-labels')
        .attr('id', (d) => `${d.data.types.name}_${d.data.id}`)
        .attr('font-weight', 'bold')
        .attr('font-family', 'Roboto')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .style('visibility', (d) =>
          d.data.level === 1 ? 'visible' : 'hidden',
        )
        .append('textPath')
        .attr('font-size', (d) => {
          if (d.data.level === 1) {
            return '1em';
          } else if (d.data.level === 2) {
            return '0.4em';
          } else {
            return '0.2em';
          }
        })
        .attr('startOffset', '50%')
        .attr('xlink:href', function (d, i) {
          return `#arc${d.data.types.name}_${d.data.id}`;
        })
        .text((d) => d.data.name);

      P.each(function (d, i) {
        d3.select(this).call(crop, d.r);
      });

      // these are the FULL/TRUNCATED horizontal texts that appear initially for the 2nd-level bubbles
      nodeElement
        .append('g')
        .attr('pointer-events', 'none') // ensure text labels are not blocking circles from being hovered upon
        .selectAll('text')
        .data(root.descendants().filter((d) => d.data.level > 2))
        .join('text')
        .attr('id', (d) => {
          return `${d.data.types.name}_${d.data.id}`;
        })
        .attr('class', 'horizontal-labels')
        .attr(
          'transform',
          (d) => `translate(${d.x - root.x},${d.y - root.y})`,
        )
        .attr('font-size', (d) =>
          d.r < 20 && d.data.truncated_name.length > 15
            ? '0.4em'
            : '0.6em',
        )
        .attr('font-weight', 'normal')
        .attr('fill', (d) =>
          determineColorContrast(prefData, d.data, d.depth),
        )
        .attr('dy', (d) => {
          if (d.data.name.length > 25) {
            return -5;
          } else if (d.data.name.length > 15) {
            return -2;
          } else {
            return -1;
          }
        })
        .style('visibility', 'hidden') //d => { return d.depth > 1 ? 'hidden' : 'visible'}) // hakuna
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text((d) =>
          d.r < 10
            ? ''
            : d.r < 20
            ? d.data.truncated_name
            : d.data.name,
        )
        .attr('y', function (d) {
          if (this.getComputedTextLength() > 26) {
            return -10;
          } else {
            return 0;
          }
        })
        .call(wrap, 26);

      // these are the FULL/TRUNCATED horizontal texts that appear for bubbles deeper than the 2nd-level
      nodeElement
        .append('g')
        .attr('pointer-events', 'none') // ensure text labels are not blocking circles from being hovered upon
        .selectAll('text')
        // .data(root.descendants().filter(d => (d.data.level > 2 && !d.children)))
        .data(root.descendants().filter((d) => d.data.level > 2))
        .join('text')
        .attr('id', (d) => `${d.data.types.name}_${d.data.id}`)
        .attr(
          'class',
          (d) => 'zoom-' + d.data.level + '-horizontal-labels',
        ) //REVISAR
        .attr(
          'transform',
          (d) => `translate(${d.x - root.x},${d.y - root.y})`,
        )
        .attr('font-size', (d) => {
          if (d.data.level < 3) {
            return '0.4em';
          } else {
            return d.r >= 10 ? '0.25em' : '0.2em';
          }
        })
        .attr('font-weight', 'normal')
        .attr('fill', (d) =>
          determineColorContrast(prefData, d.data, d.depth),
        )
        .attr('dy', (d) => {
          if (d.data.name.length > 25) {
            if (d.data.truncated_name.length > 19) {
              return -0.6;
            } else {
              if (d.data.truncated_name.indexOf(' ') !== -1) {
                return -0.5;
              }
              return 0;
            }
          } else if (d.data.name.length > 19) {
            return -0.6;
          } else {
            if (d.data.name.indexOf(' ') !== -1) {
              return 0;
            }
            return 0;
          }
        })
        .attr('y', 0)
        // .attr("opacity", 0)
        .style('visibility', 'hidden') // hakuna
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text((d) =>
          //test lenght in 3 level
          d.data.name.length > 15
            ? d.data.truncated_name + '...'
            : d.data.name,
        )
        .call(wrap, 17);

      // these are the FULL horizontal texts that appear on zoom
      nodeElement
        .append('g')
        .attr('pointer-events', 'none') // ensure text labels are not blocking circles from being hovered upon
        .selectAll('text')
        // .data(root.descendants().filter(d => (d.data.level === 2 && !d.children)))
        .data(root.descendants().filter((d) => d.data.level === 2))
        .join('text')
        .attr('id', (d) => `${d.data.types.name}_${d.data.id}`)
        .attr('class', (d) => 'small-horizontal-labels')
        .attr(
          'transform',
          // try to fix the text in center of the circle with y = 0
          (d) =>
            `translate(${d.x - root.x},${
              d.y - root.y === 0 ? 0 : d.y - root.y - '3'
            })`,
        )
        .attr('font-size', (d) => {
          // if (currentLevel === 2) {
          //   return "0.6em"
          // } else if (d.data.level < 3) {
          //   return "0.6em"
          // } else {
          return '0.5em';
        })
        .attr('font-weight', 'normal')
        .attr('fill', (d) =>
          determineColorContrast(prefData, d.data, d.depth),
        )
        .attr('dy', (d) => {
          const string = getLabel(d);
          if (getLabel(d).length < 7) {
            return 0;
          } else if (getLabel(d).length < 14) {
            if (string.indexOf(' ') !== -1) {
              // It has only whitespace
              return -1;
            }
            return -0.5;
          } else if (getLabel(d).length < 25) {
            return -1.3;
          } else if (getLabel(d).length < 30) {
            return -1.7;
          } else if (getLabel(d).length < 35) {
            return -2;
          } else {
            return -3;
          }
        })
        .attr('y', 0)
        .attr('opacity', (d) =>
          d.data.types.name === fixedCircleType ? 1 : 0,
        )
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text((d) => {
          return getLabel(d);
        })
        .call(wrap, 50);
    }

    function toggleClass(elem, className) {
      if (elem.className.indexOf(className) !== -1) {
        elem.className = elem.className.replace(className, '');
      } else {
        elem.className =
          elem.className.replace(/\s+/g, ' ') + ' ' + className;
      }

      return elem;
    }

    function toggleDisplay(elem) {
      const curDisplayStyle = elem.style.display;

      if (curDisplayStyle === 'none' || curDisplayStyle === '') {
        elem.style.display = 'block';
      } else {
        elem.style.display = 'none';
      }
    }

    function toggleMenuDisplay(e) {
      const dropdown = e.currentTarget.parentNode;
      const menu = dropdown.querySelector('.menu');

      toggleClass(menu, 'hide');
    }

    function handleOptionSelected(e, titleElem) {
      toggleClass(e.target.parentNode, 'hide');

      const id = e.target.id;
      const newValue = e.target.textContent + ' ';

      titleElem.textContent = newValue;

      //trigger custom event
      titleElem.dispatchEvent(new Event('change'));
    }

    function polarToCartesian(
      centerX,
      centerY,
      radius,
      angleInDegrees,
    ) {
      var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
      };
    }

    function getTranslation(transform) {
      // Create a dummy g for calculation purposes only. This will never
      // be appended to the DOM and will be discarded once this function
      // returns.
      var g = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'g',
      );

      // Set the transform attribute to the provided string value.
      g.setAttributeNS(null, 'transform', transform);

      // consolidate the SVGTransformList containing all transformations
      // to a single SVGTransform of type SVG_TRANSFORM_MATRIX and get
      // its SVGMatrix.
      var matrix = g.transform.baseVal.consolidate().matrix;

      // As per definition values e and f are the ones for the translation.
      return [matrix.e, matrix.f, matrix.a, matrix.d];
    }

    function describeArc(x, y, radius, startAngle, endAngle) {
      var start = polarToCartesian(x, y, radius, endAngle);
      var end = polarToCartesian(x, y, radius, startAngle);
      var arcSweep = endAngle - startAngle <= 180 ? '0' : '1';
      var d = [
        'M',
        start.x,
        start.y,
        'A',
        radius,
        radius,
        0,
        1,
        1,
        end.x,
        end.y,
      ].join(' ');
      return d;
    }

    function wrap(text, width, hasFixedCircle) {
      text.each(function (data) {
        var text = d3.select(this),
          words = text.text().split(' '),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr('y'),
          dx = parseFloat(text.attr('dx')),
          dy = parseFloat(text.attr('dy')),
          tspan = text
            .text(null)
            .append('tspan')
            .attr('x', 0)
            .attr('y', y)
            .attr('dy', () => 0);

        words.forEach((word, ind, arr) => {
          line.push(word);
          tspan.text(line.join(' '));
          if (
            tspan.node().getComputedTextLength() > width &&
            arr.length > 1
          ) {
            if (
              text.data()[0].level &&
              text.data()[0].level === 1 &&
              text.data()[0].types &&
              text.data()[0].types.name !== homeType
            ) {
              const fixedNode = findFixedNode(text.data()[0]);
              // where fix rhe text in center of nodes
              if (fixedNode) {
                tspan.attr('y', 0);
              } else {
                tspan.attr('y', 0);
              }
            }

            // console.log(word);
            lineNumber = lineNumber + 1;
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            tspan = text
              .append('tspan')
              .attr('x', () => {
                if (
                  text.data()[0].level &&
                  text.data()[0].level === 1 &&
                  text.data()[0].types &&
                  text.data()[0].types.name !== homeType
                ) {
                  const fixedNode = findFixedNode(text.data()[0]);
                  if (fixedNode) {
                    return dx;
                  } else {
                    return 0;
                  }
                } else {
                  return 0;
                }
              })
              .attr('y', (d) => {
                if (
                  text.data()[0].level &&
                  text.data()[0].level === 1 &&
                  text.data()[0].types &&
                  text.data()[0].types.name !== homeType
                ) {
                  return 20;
                } else if (d.r > 25) {
                  return 15;
                } else {
                  // console.log(d.r);
                  return 15;
                }
              })
              // .attr(
              //   'dy',
              //   lineNumber >= 1
              //     ? (lineNumber - 1) * lineHeight + 'em'
              //     : lineNumber * lineHeight + 'em',
              // )
              .text(word);
          }
        });
      });
    }

    function crop(text, circleRadius) {
      text.each(function () {
        var text = d3.select(this);
        while (
          text.node().getComputedTextLength() >
          circleRadius * 3.5
        ) {
          text.text(text.text().slice(0, -4) + '...');
        }
      });
    }

    function searchInfoBox(d, keyword, type) {
      d3.select('.panel').style('visibility', 'hidden');
      d3.select('.panel-search').style('visibility', 'visible');
      d3.selectAll('.children-node div').remove();
      d3.selectAll('.child-pointer').style('visibility', 'hidden');

      let executiveBoardId = '';

      data.nodes.forEach((node) => {
        if (node.types.name === homeType) {
          executiveBoardId = node.id;
        }
      });

      let name = d.name;
      const objectId = d.id || d.data.id;

      if (name === prevName || prevName === undefined) {
        clicked = true;
      }

      if (type === 'node') {
        let panelWrapper = d3.select('.panel-search');
        // Look for the lead person
        const personData = findPersonByRole(objectId, 'lead');
        let headName = '';
        if (personData && personData.length > 0) {
          personData.map((person, i) => {
            // console.log(person.name)
            if (i + 1 < personData.length) {
              headName += person.name + ', ';
            } else {
              headName += person.name;
            }
          });
        } else {
          headName = 'N.N.';
        }

        // Look for assistants
        const assistantsData = findPersonByRole(
          objectId,
          'assistant',
        );
        let assistants = '';
        if (assistantsData && assistantsData.length > 0) {
          assistantsData.forEach((assistant, i) => {
            if (i + 1 < assistantsData.length) {
              assistants += assistant.name + ', ';
            } else {
              assistants += assistant.name;
            }
          });
        }

        const purpose = d.data
          ? d.data.description
            ? d.data.description
            : 'N.N'
          : d.description
          ? d.description
          : 'N.N.';
        let parentNodeId = d.parent
          ? d.parent.data.id
          : executiveBoardId;
        const parentName = d.parent
          ? d.parent.data.name
          : 'Executive Board';

        const roleToCheck = 'lead';
        const parentPersonData = findPersonByRole(
          parentNodeId,
          roleToCheck,
        );

        let parentHeadName = '';
        if (parentPersonData && parentPersonData.length > 0) {
          parentPersonData.map((person, i) => {
            // console.log(person.name)
            if (i + 1 < parentPersonData.length)
              parentHeadName += person.name + ', ';
            else parentHeadName += person.name;
          });
        } else {
          // console.log('has no persondata', typeof personData)
          parentHeadName = 'N.N.';
        }

        panelWrapper.select('.panel-header').text(keyword);
        if (
          personData &&
          personData.length &&
          personData.length > 1 &&
          headName !== 'N.N.'
        ) {
          panelWrapper
            .select('.panel-header2-title')
            .text(`${d.parent ? 'Leads: ' : 'Heads: '}`);
        } else {
          panelWrapper
            .select('.panel-header2-title')
            .text(`${d.parent ? 'Lead: ' : 'Head: '}`);
        }
        panelWrapper.select('.panel-header2').text(headName);
        if (!assistants) {
          panelWrapper
            .select('.assistants-container')
            .style('display', 'none');
        } else {
          panelWrapper
            .select('.assistants-container')
            .style('display', 'block');
          panelWrapper
            .select('.panel-header3-title')
            .text('Assistant: ');
          panelWrapper.select('.panel-header3').text(assistants);
        }

        panelWrapper
          .select('.node-search-data')
          .style('display', 'block');
        panelWrapper.select('.panel-purpose-value').html(purpose);
        panelWrapper
          .select('.person-search-data')
          .style('display', 'none');
        panelWrapper
          .select('.parent-node')
          .text(parentName + ' (' + parentHeadName + ')')
          .style('display', () => {
            return d.types?.name === homeType ||
              d.data?.types.name === homeType
              ? 'none'
              : 'block';
          });
        panelWrapper
          .select('.self-node')
          .text(keyword + ' (' + headName + ')');
        const teams = getPeopleTeamForNode(
          json_data.people,
          objectId,
        );
        if (teams && teams.length) {
          createTable(
            teams,
            ['people_name', 'role', 'location', 'capacity'],
            ['Name', 'Role', 'Country', 'Capacity'],
            'team-table-div',
          );
        } else {
          removeTeamsTable();
        }
      } else {
        let panelWrapper = d3.select('.panel-search');
        let name = d.name;
        panelWrapper.select('.panel-header').text(name);
        panelWrapper
          .select('.node-search-data')
          .style('display', 'none');
        panelWrapper
          .select('.person-search-data')
          .style('display', 'block');

        if (d.roles && d.roles.length > 0) {
          panelWrapper
            .select('.panel-roles-value')
            .text('')
            .selectAll('div')
            .data(d.roles)
            .enter()
            .append('div')
            .attr('class', 'test')
            .text(function (role) {
              let detail = role.role;
              var matched_node = findCircleByPersonRoleId(
                role.nodeId,
              );
              let suffix = role.ad_interim === 'true' ? ' a.i.' : '';
              return (
                matched_node.name +
                ': ' +
                capitalize(role.role || '') +
                suffix
              );
            });
        } else {
          panelWrapper.select('.panel-roles-value').text('');
        }

        if (d.teams && d.teams.length > 0) {
          panelWrapper
            .select('.panel-teams-value')
            .text('')
            .selectAll('div')
            .data(d.teams)
            .enter()
            .append('div')
            .attr('class', 'test')
            .text(function (team) {
              let detail = team.role;
              let capacity = team.capacity;
              let suffix = team.ad_interim === 'true' ? ' a.i.' : '';
              return (
                team.node.name +
                ': ' +
                capitalize(detail || '') +
                suffix +
                ', capacity: ' +
                capacity
              );
            });
        } else {
          panelWrapper.select('.panel-teams-value').text(' ');
        }

        panelWrapper.select('.panel-location-value').text(d.location);
      }
    }

    function showConnectionsBox(nodes, descriptions) {
      d3.select('.panel').style('visibility', 'hidden');
      d3.select('.panel-search').style('visibility', 'hidden');
      d3.select('.panel-connections').style('visibility', 'visible');
      d3.selectAll('.children-node div').remove();
      d3.selectAll('.child-pointer').style('visibility', 'hidden');

      let panelWrapper = d3.select('.panel-connections');

      panelWrapper
        .select('.panel-header')
        .text(`Connections for ${nodes[0].name}`);

      const tableColumns = [
        {
          title: 'Connected Team',
          field: 'name',
        },
        {
          title: 'Parent Team',
          field: 'parent_name',
        },
        {
          title: 'Description',
          field: 'description',
        },
      ];
      const tableData = [];
      nodes.forEach((node, i) => {
        if (i > 0) {
          let newRow = {};
          newRow.name = node.name;
          newRow.description = descriptions[i - 1]
            ? descriptions[i - 1]
            : '-';
          if (node.father_id) {
            data.allNodes.forEach((el) => {
              if (el.id === node.father_id) {
                newRow.parent_name = el.name;
              }
            });
          } else {
            newRow.parent_name = '-';
          }
          tableData.push(newRow);
        }
      });

      setConnectionsTableColumns(tableColumns);
      setConnectionsTableData(tableData);
    }

    function findPersonByRole(node_id, roleToCheck) {
      return json_data.people.filter((person) => {
        if (person.roles) {
          const filteredRoles = person.roles.find(
            (role) =>
              role.nodeId === node_id &&
              role.role &&
              role.role.toLowerCase() === roleToCheck,
          );

          if (filteredRoles && filteredRoles.nodeId) {
            return person;
          } else {
            return null;
          }
        } else return null;
      });
    }

    function findPersonByName(node_name) {
      return json_data.people.find(
        (person) => person.name === node_name,
      );
    }

    function findPersonById(node_id) {
      return json_data.people.find((person) => person.id === node_id);
    }

    function findCircleByPersonRoleId(node_id) {
      return data_flat.find((item) => item.id === node_id);
    }

    function capitalize(s) {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    }

    function getObjectType(node_id) {
      // if(node_id === 'exec')
      return node_id.split('-')[0];
    }

    function clickCCT(event, d) {
      d3.select('.panel-search').style('visibility', 'hidden');
      d3.select('.panel').style('visibility', 'hidden');

      let executiveBoardId = '';

      data.nodes.forEach((node) => {
        if (node.types.name === homeType) {
          executiveBoardId = node.id;
        }
      });

      let name = d.name ? d.name : d.data.name;
      const objectId = d.id ? d.id : d.data.id;
      if (name === prevName || prevName === undefined) {
        clicked = true;
      }

      // Look for the lead person
      const personData = findPersonByRole(objectId, 'lead');
      let headName = '';
      if (personData && personData.length > 0) {
        personData.map((person, i) => {
          // console.log(person.name)
          if (i + 1 < personData.length) {
            headName += person.name + ', ';
          } else {
            headName += person.name;
          }
        });
      } else {
        headName = 'N.N.';
      }

      // Look for assistants
      const assistantsData = findPersonByRole(objectId, 'assistant');
      let assistants = '';
      if (assistantsData && assistantsData.length > 0) {
        assistantsData.forEach((assistant, i) => {
          if (i + 1 < assistantsData.length) {
            assistants += assistant.name + ', ';
          } else {
            assistants += assistant.name;
          }
        });
      }

      const purpose = d.data
        ? d.data.description
          ? d.data.description
          : 'N.N'
        : d.description
        ? d.description
        : 'N.N.';
      let parentNodeId = d.parent
        ? d.parent.data.id
        : executiveBoardId;
      const parentName = d.parent
        ? d.parent.data.name
        : 'Executive Board';

      // Get parent head name
      const roleToCheck = 'lead';
      const parentPersonData = findPersonByRole(
        parentNodeId,
        roleToCheck,
      );

      let parentHeadName = '';
      if (parentPersonData && parentPersonData.length > 0) {
        parentPersonData.map((person, i) => {
          // console.log(person.name)
          if (i + 1 < parentPersonData.length)
            parentHeadName += person.name + ', ';
          else parentHeadName += person.name;
        });
      } else {
        // console.log('has no persondata', typeof personData)
        parentHeadName = 'N.N.';
      }

      let panelWrapper = d3.select('.panel');
      d3.selectAll('.children-node div').remove();
      d3.selectAll('.child-pointer').style('visibility', 'hidden');

      if (
        (d.types &&
          (d.types.name === circleType ||
            d.types.name === innerCircleType ||
            d.types.name === homeType ||
            d.types.name === sustainabilityType ||
            d.types.name === strategyType)) ||
        d.parent
      ) {
        if (
          personData &&
          personData.length &&
          personData.length > 1 &&
          headName !== 'N.N.'
        ) {
          panelWrapper
            .select('.panel-header2-title')
            .text(`${d.parent ? 'Leads: ' : 'Heads: '}`);
        } else {
          panelWrapper
            .select('.panel-header2-title')
            .text(`${d.parent ? 'Lead: ' : 'Head: '}`);
        }

        panelWrapper.select('.panel-header2').text(headName);
        panelWrapper.select('.panel-purpose-value').html(purpose);

        if (!assistants) {
          panelWrapper
            .select('.assistants-container')
            .style('display', 'none');
        } else {
          panelWrapper
            .select('.assistants-container')
            .style('display', 'block');
          panelWrapper
            .select('.panel-header3-title')
            .text('Assistant: ');
          panelWrapper.select('.panel-header3').text(assistants);
        }

        panelWrapper
          .select('.parent-node')
          .text(parentName + ' (' + parentHeadName + ')')
          .style('display', () => {
            return d.types?.name === 4 || d.data?.types.name === 4
              ? 'none'
              : 'block';
          });
        panelWrapper
          .select('.self-node')
          .text(name + ' (' + headName + ')');
        // document.querySelector('#speaker-wrapper').style.display = 'flex';
        if (!d.data) {
          panelWrapper.select('.panel-header').text(name);
          if (d.children) {
            panelWrapper
              .select('.child-pointer')
              .style('visibility', 'visible');
            panelWrapper
              .select('.children-node')
              .selectAll('div')
              .data(d.children)
              .enter()
              .append('div')
              .attr('class', 'test')
              .text(function (d) {
                const personArr = findPersonByRole(d.id, 'lead');
                let personNames = [];
                if (personArr && personArr.length) {
                  personArr.forEach((person) => {
                    personNames.push(person.name);
                  });
                  personNames = personNames.join(', ');
                } else {
                  personNames = 'N.N';
                }
                return personNames
                  ? '|_' + d.name + ` (${personNames})`
                  : '|_' + d.name + ' (N.N)';
              });

            const teams = getPeopleTeamForNode(
              json_data.people,
              d.id,
            );
            if (teams && teams.length) {
              // Construct the team table
              createTable(
                teams,
                ['people_name', 'role', 'location', 'capacity'],
                ['Name', 'Role', 'Country', 'Capacity'],
                'team-table-div',
              );
            } else {
              removeTeamsTable();
            }
          } else {
            panelWrapper
              .select('.child-pointer')
              .style('visibility', 'visible');
            const teams = getPeopleTeamForNode(
              json_data.people,
              d.id,
            );
            if (teams && teams.length) {
              // Construct the team table
              createTable(
                teams,
                ['people_name', 'role', 'location', 'capacity'],
                ['Name', 'Role', 'Country', 'Capacity'],
                'team-table-div',
              );
            } else {
              removeTeamsTable();
            }
          }
        } else {
          panelWrapper.select('.panel-header').text(name);
          if (d.data.children) {
            panelWrapper
              .select('.child-pointer')
              .style('visibility', 'visible');
            panelWrapper
              .select('.children-node')
              .selectAll('div')
              .data(d.data.children)
              .enter()
              .append('div')
              .attr('class', 'test')
              .text(function (child) {
                const personArr = findPersonByRole(child.id, 'lead');
                let each_person = 'N.N';
                let adInterimSuffix = '';
                if (personArr && personArr.length > 0) {
                  each_person = personArr.map((person) => {
                    return person.name;
                  });
                  each_person = each_person.join(', ');
                  if (
                    personArr[0].roles &&
                    personArr[0].roles.length
                  ) {
                    personArr[0].roles.forEach((item) => {
                      if (item.ad_interim === 'true') {
                        adInterimSuffix = ' a.i.';
                      }
                    });
                  }
                }
                return each_person
                  ? '|_' +
                      child.name +
                      ` (${each_person + adInterimSuffix})`
                  : '|_' +
                      child.name +
                      ' (N.N.' +
                      adInterimSuffix +
                      ')';
              });
          } else {
            panelWrapper
              .select('.child-pointer')
              .style('visibility', 'hidden');
          }
          // d3.select('.panel').style('visibility', 'hidden')
        }

        if ((clicked === false) & (prevId === d.id)) {
          panelWrapper.select('#table table').remove();
          d3.select('.panel').style('visibility', 'hidden');
        } else {
          d3.select('.panel').style('visibility', 'visible');
          d3.select('.panel').style('background-color', 'white');
        }
      } else {
        // document.querySelector('.supervisor').innerHTML = "";
        // panelWrapper.select('.report-list ul').remove();
        panelWrapper.select('.panel-purpose-value').innerHTML = '';
        panelWrapper.select('.panel-header2-title').text('');
      }

      if (d.types && d.types.name === ovalType) {
        createCctInfobox(d);

        prevId = d.id;
        event.stopPropagation();
      }
      const nodeData = d.data || d || {};
      const teams = getPeopleTeamForNode(
        json_data.people,
        nodeData.id,
      );
      if (teams && teams.length) {
        // Construct the team table
        createTable(
          teams,
          ['people_name', 'role', 'location', 'capacity'],
          ['Name', 'Role', 'Country', 'Capacity'],
          'team-table-div',
        );
      } else {
        removeTeamsTable();
      }
      prevName = name;
    }

    // // // // // //   Autocomplete // // // // //
    var keywords = [];
    data_flat.map((node) => keywords.push(node.name));
    json_data.people.map((person) => keywords.push(person.name));

    var items = keywords.map(function (n) {
      return { label: n };
    });
    var allowedChars = new RegExp(/^[a-zA-Z\s]+$/);

    function charsAllowed(value) {
      return allowedChars.test(value);
    }

    autocomplete({
      input: document.querySelector('.search-form'),
      minLength: 1,
      onSelect: function (item, inputfield) {
        inputfield.value = item.label;
        try {
          // Sort data to avoid nodes reorder
          /*data.nodes.sort((a, b) => {
          if(a.id > b.id){
            return 1;
          }
          if(a.id < b.id){
            return -1;
          }
            return 0;
          })*/
          //updateGraph(data).showSearchResult(item.label)
        } catch (error) {}
      },
      fetch: function (text, callback) {
        var match = text.toLowerCase();
        callback(
          items.filter(function (n) {
            return n.label.toLowerCase().indexOf(match) !== -1;
          }),
        );
      },
      render: function (item, value) {
        var itemElement = document.createElement('div');
        if (charsAllowed(value)) {
          var regex = new RegExp(value, 'gi');
          var inner = item.label.replace(regex, function (match) {
            return '<strong>' + match + '</strong>';
          });
          itemElement.innerHTML = inner;
        } else {
          itemElement.textContent = item.label;
        }
        return itemElement;
      },
      emptyMsg: 'No matched found',
      customize: function (input, inputRect, container, maxHeight) {
        if (maxHeight < 100) {
          container.style.top = '';
          container.style.bottom =
            window.innerHeight -
            inputRect.bottom +
            input.offsetHeight +
            'px';
          container.style.maxHeight = '140px';
        }
      },
    });

    document.querySelector('.search-form').focus();
  };

  const classes = useStyles();

  const createTable = (objectArray, fields, fieldTitles, divId) => {
    let columns = [];
    let data = [];

    objectArray.forEach((object) => {
      let newRow = {};
      fields.forEach((field) => {
        newRow[field] = object[field];
      });
      data.push(newRow);
    });

    fieldTitles.forEach((title, i) => {
      let newField = {
        title: fieldTitles[i],
        field: fields[i],
      };
      columns.push(newField);
    });

    if (divId === 'people-table-div') {
      setPeopleTableColumns(columns);
      setPeopleTableData(data);
    }

    if (divId === 'team-table-div') {
      setTeamsTableColumns(columns);
      setTeamsTableData(data);
    }

    if ((divId = 'connections-table-div')) {
      setConnectionsTableColumns(columns);
      setConnectionsTableData(data);
    }
  };

  const handleShowCCT = () => {
    setShowCCT(!showCCT);

    showCCT
      ? toggleCCT({ id: 'cct_none' })
      : toggleCCT({ id: 'cct_all' });
  };

  const removePeopleTable = (divId) => {
    setPeopleTableData([]);
    setPeopleTableColumns([]);
  };

  const removeTeamsTable = (divId) => {
    setTeamsTableData([]);
    setTeamsTableColumns([]);
  };

  const removeConnectionsTable = (divId) => {
    setConnectionsTableColumns([]);
    setConnectionsTableData([]);
  };

  const findFixedNode = (node) => {
    let fixedNode;

    if (
      node.types?.name === fixedCircleType ||
      node.data?.types.name === fixedCircleType
    ) {
      fixedNode = node;
    } else if (node.children && node.children.length > 0) {
      node.children.forEach((childrenNode) => {
        const result = findFixedNode(childrenNode);
        if (result) {
          fixedNode = result;
        }
      });
    }

    return fixedNode;
  };

  return (
    <div className="wrapper">
      <div className="sidebar"></div>
      <div className={classes.chartContainer}>
        <div
          className="chart"
          style={{
            position: 'relative',
            marginBottom: '40px',
          }}
        />
      </div>
      <div className="legend"></div>
      <div className="error-btn" style={{zIndex : 2}}></div>

      <div className="side-right" style={{zIndex : 3}}>
        <input
          type="text"
          className="search-form box-shadow border-2 border-transparent focus:border-black focus:outline-none"
          placeholder="&#x1F50E;&#xFE0E;  Search for Person/Teams"
        ></input>
        <div className="search-list-container" />
        <div className="button-panel" onClick={() => handleShowCCT()}>
          <span className="btn-legend">
            {' '}
            {showCCT
              ? 'HIDE CROSS-COMPETENCE TEAMS'
              : 'SHOW CROSS-COMPETENCE TEAMS'}
          </span>
        </div>

        {/* Bubble info box panel */}
        <div className="panel">
          <h4 className="panel-header"></h4>
          <h4>
            <span className="panel-header2-title panel-bio"></span>
            <span className="panel-header2 bold dark-grey"></span>
          </h4>
          <h4 className="assistants-container">
            <span className="panel-header3-title panel-bio"></span>
            <span className="panel-header3 dark-grey"></span>
          </h4>
          <h4 className="panel-bio">
            <span className="panel-purpose-value"></span>
          </h4>

          <div className="Rtable-cell-2">
            <span className="parent-node"></span>
          </div>
          <div style={{ paddingLeft: '15px' }}> | </div>
          <div className="Rtable-cell-2">
            <span className="self-node"></span>
          </div>
          <div
            className="child-pointer"
            style={{ paddingLeft: '15px', visibility: 'hidden' }}
          >
            {' '}
            |{' '}
          </div>
          <div
            className="Rtable-cell-2"
            style={{ paddingBottom: '10px' }}
          >
            <span className="children-node"></span>
          </div>
          {teamstableColumns?.length > 0 &&
            teamsTableData?.length > 0 && (
              <div
                className="Material-Table"
                style={{ maxWidth: '100%' }}
              >
                <MaterialTable
                  columns={teamstableColumns}
                  data={teamsTableData}
                  options={getInfoboxTableOptions}
                />
              </div>
            )}
        </div>

        {/* CCT info box panel */}
        <div className="panel-box-CCT border-radius-32">
          <h4 className="panel-header">
            <span></span>
          </h4>
          <h4>
            <span className="panel-header2-title panel-bio"></span>
            <span className="panel-header2 bold dark-grey">
              {' '}
              <span></span>
            </span>
          </h4>
          {/* <h4 className="panel-header2 bold">
            Speaker: <span></span>
          </h4> */}

          <h4 className="panel-bio">
            <span className="panel-bio-value"></span>
          </h4>

          <div className="Rtable-cell-2">
            <span className="parent-node"></span>
          </div>
          <div style={{ paddingLeft: '15px' }}> | </div>
          <div className="Rtable-cell-2">
            <span className="self-node"></span>
          </div>
          <div
            className="child-pointer"
            style={{ paddingLeft: '15px', visibility: 'hidden' }}
          >
            {' '}
            |{' '}
          </div>
          <div
            className="Rtable-cell-2"
            style={{ paddingBottom: '10px' }}
          >
            <span className="children-node"></span>
          </div>
          {teamstableColumns?.length > 0 &&
            teamsTableData?.length > 0 && (
              <div
                className="Material-Table"
                style={{ maxWidth: '100%' }}
              >
                <MaterialTable
                  columns={teamstableColumns}
                  data={teamsTableData}
                  options={getInfoboxTableOptions}
                />
              </div>
            )}
        </div>

        {/* Search info box panel */}
        <div className="panel-search border-radius-32">
          <h4 className="panel-header"></h4>
          <div className="node-search-data">
            <h4>
              <span className="panel-header2-title panel-bio"></span>
              <span className="panel-header2 bold dark-grey"></span>
            </h4>
            <h4 className="assistants-container">
              <span className="panel-header3-title panel-bio"></span>
              <span className="panel-header3 dark-grey"></span>
            </h4>
            <h4 className="purpose-panel-bio">
              <span className="panel-purpose-value"></span>
            </h4>
            <div className="Rtable-cell-2">
              <span className="parent-node"></span>
            </div>
            <div style={{ paddingLeft: '15px' }}> | </div>
            <div className="Rtable-cell-2">
              <span className="self-node"></span>
            </div>
            <div
              className="child-pointer"
              style={{ paddingLeft: '15px', visibility: 'hidden' }}
            >
              {' '}
              |{' '}
            </div>
            <div
              className="Rtable-cell-2"
              style={{ paddingBottom: '10px' }}
            >
              <span className="children-node"></span>
            </div>
            {teamstableColumns?.length > 0 &&
              teamsTableData?.length > 0 && (
                <div
                  className="Material-Table"
                  style={{ maxWidth: '100%' }}
                >
                  <MaterialTable
                    columns={teamstableColumns}
                    data={teamsTableData}
                    options={getInfoboxTableOptions}
                  />
                </div>
              )}
          </div>
          <div className="person-search-data">
            <h5 className="panel-roles">
              Teams:&ensp;
              <span className="panel-roles-value"></span>
            </h5>
            <h5 className="panel-teams">
              <span className="panel-teams-value"></span>
            </h5>
            <h5 className="panel-location">
              Location:&ensp;
              <span className="panel-location-value"></span>
            </h5>
          </div>
        </div>

        {/* Connections info box panel */}
        <div className="panel-connections border-radius-32">
          <h4 className="panel-header"></h4>
          <div className="node-connections-data">
            <h4>
              <span className="panel-header2-title panel-bio"></span>
              <span className="panel-header2 bold dark-grey"></span>
            </h4>
            <h4 className="assistants-container">
              <span className="panel-header3-title panel-bio"></span>
              <span className="panel-header3 dark-grey"></span>
            </h4>
            <h4 className="connections-panel">
              <div className="panel-connections-list">
                {connectionsTableColumns?.length > 0 &&
                  connectionsTableData?.length > 0 && (
                    <div
                      className="Material-Table"
                      style={{ maxWidth: '100%' }}
                    >
                      <MaterialTable
                        columns={connectionsTableColumns}
                        data={connectionsTableData}
                        options={getInfoboxTableOptions}
                      />
                    </div>
                  )}
              </div>
            </h4>
          </div>
        </div>
        <div
          className="panel-wrapperCCT border-radius-32"
          style={{ display: 'none' }}
        >
          CCT Overview
          <p>
            <label htmlFor="cct_all">
              <input type="radio" name="cct_radio" id="cct_all" />
              <span>Show All</span>
            </label>
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}
