import Swal from 'sweetalert2';
import { setUserData } from './UserActions';
import history from 'history.js';
import authService from 'services/AuthService';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_LOADING = 'FORGOT_PASSWORD_LOADING';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_LOADING = 'SIGNUP_LOADING';
export const SET_USER_ROLE_IDS = 'SET_USER_ROLE_IDS';

export function setLoading(action) {
  return (dispatch) => {
    dispatch({
      type: action,
    });
  };
}

export function signInUser({ email, password }) {
  console.log(email);
  return (dispatch) => {
    authService
      .signIn(email, password)
      .then((data) => {
        const { user } = data;
        if (user) {
          // dispatch(
          //   setUserData({
          //     ...user,
          //   }),
          // );
          // history.push({
          //   pathname: '/homepage',
          // });
          let domain = new URL(window.location.href);
          window.location.replace(domain.origin);
          // return dispatch({
          //   type: LOGIN_SUCCESS,
          //   payload: user,
          // });
        } else {
          return dispatch({
            type: LOGIN_ERROR,
            payload: 'Login failed, please check your credentials.',
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: LOGIN_ERROR,
          payload: 'Login failed, please check your credentials.',
        });
      });
  };
}

export function signUpUser(data) {
  return (dispatch) => {
    authService
      .signUp(data)
      .then(async (data) => {
        const user = data;
        if (user) {
          history.push({
            pathname: '/signin',
          });

          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });

          Toast.fire({
            icon: 'success',
            title: 'User successfully created.',
          });

          return dispatch({
            type: SIGNUP_SUCCESS,
          });
        } else {
          return dispatch({
            type: SIGNUP_ERROR,
            payload: 'Signup Failed',
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: SIGNUP_ERROR,
          payload: error,
        });
      });
  };
}

export function setUserRoleIds() {
  return (dispatch) => {
    authService
      .getUserRoleTypes()
      .then((data) => {
        return dispatch({
          type: SET_USER_ROLE_IDS,
          payload: data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: SET_USER_ROLE_IDS,
          payload: null,
        });
      });
  };
}

export function refreshToken() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      authService
        .refreshToken()
        .then((data) => {
          if (data) {
            dispatch(
              setUserData({
                ...data,
              }),
            );
            dispatch({
              type: LOGIN_SUCCESS,
              payload: data,
            });
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(false);
        });
    });
  };
}
