import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { formatLinkData } from '../../helpers/LinkDataTable';
import {
  addConnectionToNode,
  formatNodeData,
  searchNodesByKeyword,
  orderNodes,
  updateNodeConnection,
  replaceNode,
  addChildrenToNode,
  replaceAllEditedChildNode,
} from '../../helpers/NodeDataTable';
import {
  formatPeopleData,
  formatRoleToSendInPeopleTable,
  formatTeamToSendInPeopleTable,
} from '../../helpers/PeopleDataTable';
import {
  NodesDatatableComponent,
  LinksDatatableComponent,
  PeopleDatatableComponent,
  FaqDatatableComponent,
} from '.';
import { formatFaqData } from 'helpers/FaqDataTable';
import nodesService from 'services/NodesService';
import linksService from 'services/LinksService';
import peopleService from 'services/PeopleService';
import faqService from 'services/FaqService';
import rolesService from 'services/RolesService';
import teamsService from 'services/TeamsService';
import { replaceEditedPerson } from '../../helpers/PeopleDataTable';
import connectionsService from 'services/ConnectionsService';
import { setLoading } from 'redux/actions/LoginActions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    marginBottom: '0px',
    marginTop: '0px',
  },
  header: {
    alignItems: 'end',
    marginTop: '24px',
    paddingLeft: '48px',
    paddingRight: '48px',
  },
  buttonContainer: {
    paddingTop: '16px',
  },
  button: {
    float: 'right',
  },
  discardButton: {
    float: 'right',
    marginLeft: '10px',
    backgroundColor: theme.palette.error.main,
  },
}));

function TableContainer(props) {
  const { handleMenuChange, activeTab } = props;
  // const srcNodes = JSON.parse(
  //   JSON.stringify((SourceJson && SourceJson.nodes) || [])
  // );
  // const srcLinks = JSON.parse(
  //   JSON.stringify((SourceJson && SourceJson.links) || [])
  // );

  const classes = useStyles();
  // const [state, setState] = React.useState({
  //   value: 0,
  //   links: [],
  //   nodes: [],
  //   allNodes: [],
  //   people: [],
  //   allPeople: [],
  //   faq: [],
  //   nodeTypes: [],
  //   linksTypes: [],
  //   isLoading: false,
  //   isLinkChanged: false,
  //   isNodeChanged: false,
  //   isPeopleChanged: false,
  //   isFaqChanged: false,
  //   rowsToBeDeleted: [],
  //   table: null,
  //   searchTerm: null,
  //   filteredNodes: [],
  // });

  // const [value, setValue] = useState(0);
  const [links, setLinks] = useState([]);

  const [allNodes, setAllNodes] = useState([]);
  const [people, setPeople] = useState([]);
  const [allPeople, setAllPeople] = useState([]);
  const [faq, setFaq] = useState([]);
  const [nodeTypes, setNodeTypes] = useState([]);
  const [linksTypes, setLinkTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [triggerUpdate, setTriggerUpdate] = useState(1);
  const [isLinkChanged, setIsLinkChanged] = useState(false);
  const [isNodeChanged, setIsNodeChanged] = useState(false);
  const [isPeopleChanged, setIsPeopleChanged] = useState(false);
  const [isFaqChanged, setIsFaqChanged] = useState(false);
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState([]);
  const [table, setTable] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [filteredNodes, setFilteredNodes] = useState([]);
  // const [nodesBack, setNodesBack] = useState([])
  const [nodes, setNodes] = useState([]);

  // useEffect(() => {

  //   console.log(state)
  // },[state])

  // const {
  //   value,
  //   links,
  //   // nodes,
  //   people,
  //   allPeople,
  //   allNodes,
  //   nodeTypes,
  //   linksTypes,
  //   faq,
  //   isLoading,
  //   isLinkChanged,
  //   isNodeChanged,
  //   isPeopleChanged,
  //   isFaqChanged,
  //   rowsToBeDeleted,
  //   table,
  //   filteredNodes,
  //   searchTerm,
  // } = state;

  const getAllNodeDetails = () => {
    let nodes = [];
    let links = [];
    let people = [];
    let allPeople = [];
    let allNodes = [];
    let faq = [];
    let nodeTypes = [];
    let linksTypes = [];
    // Getting nodes from backend
    nodesService.getAll().then((items) => {
      // if (mounted) {
      nodes = orderNodes(items);
      allNodes = nodes;
      setNodes(nodes);
      // setNodesBack(nodes)
      setAllNodes(allNodes);
      // setState({
      //   ...state,
      //   nodes: nodes,
      //   links: links,
      //   people: people,
      //   allPeople: allPeople,
      //   allNodes: allNodes,
      //   linksTypes: linksTypes,
      //   nodeTypes: nodeTypes,
      // });
      // }
    });

    // Getting nodes types from backend
    nodesService.getTypes().then((items) => {
      // if (mounted) {
      nodeTypes = items;
      setNodeTypes(nodeTypes);
      // setState({
      //   ...state,
      //   nodeTypes: nodeTypes,
      //   linksTypes: linksTypes,
      //   nodes: nodes,
      //   links: links,
      //   people: people,
      //   allPeople: allPeople,
      //   allNodes: allNodes,
      // });
      // }
    });

    // Getting links from backend
    linksService.getAll().then((items) => {
      // if (mounted) {
      links = items;
      setLinks(links);
      // setState({
      //   ...state,
      //   nodes: nodes,
      //   links: links,
      //   people: people,
      //   faq: faq,
      //   allPeople: allPeople,
      //   allNodes: allNodes,
      //   linksTypes: linksTypes,
      //   nodeTypes: nodeTypes,
      // });
      // }
    });

    // Getting links from backend
    linksService.getTypes().then((items) => {
      // if (mounted) {
      linksTypes = items;
      setLinkTypes(linksTypes);
      // setState({
      //   ...state,
      //   nodes: nodes,
      //   links: links,
      //   people: people,
      //   faq: faq,
      //   allPeople: allPeople,
      //   allNodes: allNodes,
      //   linksTypes: linksTypes,
      //   nodeTypes: nodeTypes,
      // });
      // }
    });

    // Getting people from backend
    peopleService.getAll().then((items) => {
      // if (mounted) {
      people = items;
      setPeople(people);
      // setState({
      //   ...state,
      //   nodes: nodes,
      //   links: links,
      //   people: people,
      //   faq: faq,
      //   allPeople: allPeople,
      //   allNodes: allNodes,
      //   linksTypes: linksTypes,
      //   nodeTypes: nodeTypes,
      // });
      // }
    });

    // Getting faq from backend
    faqService.getAll().then((items) => {
      // if (mounted) {
      faq = items;
      setFaq(faq);
      // setState({
      //   ...state,
      //   nodes: nodes,
      //   links: links,
      //   people: people,
      //   faq: faq,
      //   allPeople: allPeople,
      //   allNodes: allNodes,
      //   linksTypes: linksTypes,
      //   nodeTypes: nodeTypes,
      // });
      // }
    });
  };

  useEffect(() => {
    // let mounted = true;

    getAllNodeDetails();

    // return () => (mounted = false);
  }, []);

  const handleLinksChange = (value, id, operation) => {
    if (isLoading) return;
    let isLinkChanged = true;

    setIsLoading(true);

    // setState({...state, isLoading: true});
    switch (operation) {
      case 'create':
        const links = formatLinkData(value);
        linksService
          .create(links)
          .then((res) => {
            const linksCopy = links;

            linksCopy.pop();
            linksCopy.push(res);
            isLinkChanged = false;
            // setState({
            //   ...state,
            //   isLoading: false,
            //   isLinkChanged: isLinkChanged,
            //   links: links,
            //   table: "links",
            // });

            setIsLoading(false);

            setTable('links');

            setIsLinkChanged(isLinkChanged);

            setLinks(linksCopy);

            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });

            Toast.fire({
              icon: 'success',
              title: 'Connections has been saved!',
            });
          })
          .catch((err) => {
            if (err) {
              setIsLoading(false);
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err,
              });
            }
          });
        break;
      case 'delete':
        linksService
          .delete(id)
          .then((res) => {
            _.remove(value, (item) => item.id === id);
            // setState({
            //   ...state,
            //   links: value,
            //   isLoading: false,
            // });

            setLinks(value);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'edit':
        const linkEdited = value.filter((link) => link.id === id);
        linksService
          .update(...linkEdited)
          .then((res) => {
            setLinks(value);
            setIsLoading(false);
            // setState({
            //   ...state,
            //   links: value,
            //   isLoading: false,
            // });
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'edit_all':
        linksService
          .updateAll(value)
          .then((res) => {
            setLinks(value);
            setIsLoading(false);
            // setState({
            //   ...state,
            //   links: value,
            //   isLoading: false,
            // });
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      default:
        break;
    }
  };

  const handleNodesChange = (value, id, operation, childData) => {
    if (isLoading) return;

    switch (operation) {
      case 'create':
        let updatedNodes = formatNodeData(value);
        nodesService
          .create(updatedNodes)
          .then((res) => {
            updatedNodes.pop();
            updatedNodes.push(res);
            setNodes(updatedNodes);

            getAllNodeDetails();

            setIsLoading(false);

            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });

            Toast.fire({
              icon: 'success',
              title: 'Nodes data has been saved!',
            });
          })
          .catch((err) => {
            if (err) {
              setIsLoading(false);
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: JSON.stringify(err),
              });
            }
          });
        break;
      case 'delete':
        nodesService
          .delete(id, value)
          .then((res) => {
            const removeNode = value?.filter(
              (item) => item.id !== id,
            );
            const removeLinks = links?.filter(
              (item) => item.source !== id || item.target !== id,
            );

            // const updateNodes = (arr, selectedKey) => {
            //   return arr.filter(item => item.id !== selectedKey).map(item => {
            //     item = Object.assign({}, item)
            //     if (item.children) {
            //       item.children = updateNodes(item.children, selectedKey)
            //     }
            //     return item
            //   })
            // }

            const deleteChildNode = (arr, id) => {
              return arr
                .filter((el) => el.id !== id)
                .map((el) => {
                  if (!el.children || !el.children.length) return el;
                  el.children = deleteChildNode(el.children, id);
                  return el;
                });
            };

            setLinks(removeLinks?.length > 0 ? removeLinks : []);
            setNodes(
              !childData ? removeNode : deleteChildNode(value, id),
            );
            getAllNodeDetails();

            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'edit':
        let nodeEdited;
        if (!childData) {
          nodeEdited = value.filter((node) => node.id === id);
        } else {
          nodeEdited = [childData];
        }
        nodesService
          .update(...nodeEdited)
          .then((res) => {
            const updatedNodes = value.map((node) =>
              node.id === id ? nodeEdited[0] : node,
            );

            setNodes(!childData ? updatedNodes : value);

            getAllNodeDetails();

            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'edit_all':
        let nodesEdited;
        if (!childData) {
          nodesEdited = value;
        } else {
          nodesEdited = childData.children;
        }
        nodesService
          .updateAll(nodesEdited)
          .then((res) => {
            let currentNodes = value;

            setNodes(
              !childData
                ? nodesEdited
                : replaceAllEditedChildNode(
                    currentNodes,
                    id,
                    nodesEdited,
                  ),
            );

            getAllNodeDetails();

            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'add_child':
        nodesService
          .addChild(childData)
          .then((res) => {
            for (const key in value) {
              if (value[key].id === id) {
                value[key].children = res;
              }
            }
            setNodes(addChildrenToNode(value, childData.id, res));
            getAllNodeDetails();

            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      default:
        break;
    }
  };

  const handlePeopleChange = (value, id, operation, childData) => {
    if (isLoading) return;
    let isPeopleChanged = true;
    // setState({...state, isLoading: true});
    setIsLoading(true);
    switch (operation) {
      case 'create':
        const people = formatPeopleData(value);
        peopleService
          .create(people)
          .then((res) => {
            const peopleCopy = people;
            peopleCopy.pop();
            peopleCopy.push(res);
            isPeopleChanged = false;

            setPeople(peopleCopy);
            setIsPeopleChanged(isPeopleChanged);
            setTable('table');
            setIsLoading(false);
            // setState({
            //   ...state,
            //   isLoading: false,
            //   isPeopleChanged: isPeopleChanged,
            //   people: people,
            //   table: "people",
            // });

            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });

            Toast.fire({
              icon: 'success',
              title: 'People data has been saved!',
            });
          })
          .catch((err) => {
            if (err) {
              setIsLoading(false);
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err,
              });
            }
          });
        break;
      case 'delete':
        peopleService
          .delete(id)
          .then((res) => {
            _.remove(value, (item) => item.id === id);
            setPeople(value);
            setIsLoading(false);
            // setState({
            //   ...state,
            //   people: value,
            //   isLoading: false,
            // });
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'edit':
        const personEdited = value.filter(
          (person) => person.id === id,
        );
        peopleService
          .update(...personEdited)
          .then((res) => {
            setPeople(value);
            setIsLoading(false);
            // setState({
            //   ...state,
            //   people: value,
            //   isLoading: false,
            // });
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'edit_all':
        peopleService
          .updateAll(value)
          .then((res) => {
            setPeople(value);
            setIsLoading(false);
            // setState({
            //   ...state,
            //   people: value,
            //   isLoading: false,
            // });
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'add_child':
        peopleService
          .updateAll(value)
          .then((res) => {
            setPeople(value);
            setIsLoading(false);
            // setState({
            //   ...state,
            //   people: value,
            //   isLoading: false,
            // });
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      default:
        break;
    }
  };

  const handleFaqChange = (value, id, operation) => {
    if (isLoading) return;
    let isFaqChanged = true;
    setIsLoading(true);
    switch (operation) {
      case 'create':
        const faq = formatFaqData(value);
        faqService
          .create(faq)
          .then((res) => {
            const faqCopy = faq;
            faqCopy.pop();
            faqCopy.push(res);
            isFaqChanged = false;
            setFaq(faqCopy);
            setIsLoading(false);
            setIsFaqChanged(isFaqChanged);
            setTable('faq');
            // setState({
            //   ...state,
            //   isLoading: false,
            //   isFaqChanged: isFaqChanged,
            //   faq: faq,
            //   table: "faq",
            // });
            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
            Toast.fire({
              icon: 'success',
              title: 'Help data has been saved!',
            });
          })
          .catch((err) => {
            if (err) {
              setIsLoading(false);
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err,
              });
            }
          });
        break;
      case 'delete':
        faqService
          .delete(id)
          .then((res) => {
            _.remove(value, (item) => item.id === id);
            setFaq(value);
            setIsLoading(false);
            // setState({
            //   ...state,
            //   faq: value,
            //   isLoading: false,
            // });
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'edit':
        const faqEdited = value.filter((faq) => faq.id === id);
        faqService
          .update(...faqEdited)
          .then((res) => {
            setFaq(value);
            setIsLoading(false);
            // setState({
            //   ...state,
            //   faq: value,
            //   isLoading: false,
            // });
          })

          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'edit_all':
        faqService
          .updateAll(value)
          .then((res) => {
            setFaq(value);
            setIsLoading(false);
            // setState({
            //   ...state,
            //   faq: value,
            //   isLoading: false,
            // });
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      default:
        break;
    }
  };

  const handleRoleChange = (updatedRoles, id, operation) => {
    switch (operation) {
      case 'add_child':
        let rolesToSend;
        if (updatedRoles.type) {
          updatedRoles.children.map((child, i) => {
            const person = people.filter(
              (ele) =>
                ele.id === parseInt(child.person_id) ||
                ele.id === parseInt(child.person_id.value),
            );
            const name = person[0].name;
            updatedRoles.children[i].people_name = name;
            updatedRoles.children[i].person_id =
              updatedRoles.children[i].person_id.value ||
              updatedRoles.children[i].person_id;
            rolesToSend = updatedRoles;
          });
        } else {
          rolesToSend = formatRoleToSendInPeopleTable(updatedRoles);
        }

        rolesService
          .create(rolesToSend)
          .then((res) => {
            setPeople(replaceEditedPerson(people, id, res));

            getAllNodeDetails();

            setIsLoading(false);

            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });

            Toast.fire({
              icon: 'success',
              title: 'People changes are saved successfully!',
            });
          })
          .catch((err) => {
            if (err) {
              setIsLoading(false);
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err,
              });
            }
          });
        break;
      case 'delete':
        rolesService
          .delete(id)
          .then((res) => {
            setPeople(replaceEditedPerson(people, id, res));

            getAllNodeDetails();

            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'edit':
        rolesService
          .update(id, updatedRoles)
          .then((res) => {
            setPeople(replaceEditedPerson(people, id, res));

            getAllNodeDetails();

            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'edit_all':
        rolesService
          .updateAll(updatedRoles.children)
          .then((res) => {
            setPeople(replaceEditedPerson(people, id, res));

            getAllNodeDetails();

            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      default:
        break;
    }
  };

  const handleTeamChange = (updatedTeams, id, operation) => {
    switch (operation) {
      case 'add_child':
        let teamToSend;
        if (updatedTeams.type) {
          updatedTeams.children.map((child, i) => {
            const person = people.filter(
              (ele) =>
                ele.id === parseInt(child.person_id) ||
                ele.id === parseInt(child.person_id.value),
            );
            const name = person[0].name;
            updatedTeams.children[i].people_name = name;
            updatedTeams.children[i].person_id =
              updatedTeams.children[i].person_id.value ||
              updatedTeams.children[i].person_id;
            teamToSend = updatedTeams;
          });
        } else {
          teamToSend = formatTeamToSendInPeopleTable(updatedTeams);
        }
        teamsService
          .create(teamToSend)
          .then((res) => {
            setPeople(replaceEditedPerson(people, id, res));

            getAllNodeDetails();

            setIsLoading(false);

            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });

            Toast.fire({
              icon: 'success',
              title: 'People changes are saved successfully!',
            });
          })
          .catch((err) => {
            if (err) {
              setIsLoading(false);
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err,
              });
            }
          });
        break;
      case 'delete':
        teamsService
          .delete(id)
          .then((res) => {
            setPeople(replaceEditedPerson(people, id, res));

            getAllNodeDetails();

            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'edit':
        if (!updatedTeams.person_id) {
          updatedTeams.person_id = updatedTeams.peopleId;
        }
        teamsService
          .update(id, updatedTeams)
          .then((res) => {
            setPeople(replaceEditedPerson(people, id, res));

            getAllNodeDetails();

            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'edit_all':
        updatedTeams.children.map((child) => {
          if (!child.person_id) {
            child.person_id = child.peopleId;
          }
        });
        teamsService
          .updateAll(updatedTeams.children)
          .then((res) => {
            setPeople(replaceEditedPerson(people, id, res));

            getAllNodeDetails();

            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      default:
        break;
    }
  };

  const handleConnectionsChange = (
    updatedConnection,
    id,
    operation,
  ) => {
    switch (operation) {
      case 'add_child':
        connectionsService
          .create(updatedConnection)
          .then((res) => {
            // setNodes(addConnectionToNode(nodes, id, res))

            getAllNodeDetails();

            setIsLoading(false);

            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });

            Toast.fire({
              icon: 'success',
              title: 'People changes are saved successfully!',
            });
          })
          .catch((err) => {
            if (err) {
              setIsLoading(false);
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err,
              });
            }
          });
        break;
      case 'delete':
        connectionsService
          .delete(id)
          .then((res) => {
            // const replacedNodes = replaceNode(nodes, res.id, res)

            // setNodes(replacedNodes)

            getAllNodeDetails();

            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'edit':
        connectionsService
          .update(id, updatedConnection)
          .then((res) => {
            // setNodes(updateNodeConnection(nodes, updatedConnection, operation))

            getAllNodeDetails();

            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      case 'edit_all':
        connectionsService
          .updateAll(updatedConnection)
          .then((res) => {
            // setNodes(updateNodeConnection(nodes, updatedConnection, operation))

            getAllNodeDetails();

            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err,
            });
          });
        break;
      default:
        break;
    }
  };

  const handleNodeRefresh = () => {
    setIsLoading(false);

    setTimeout(() => {
      setIsLoading(false);
      setIsNodeChanged(false);
    }, 1000);
  };

  const handleLinkRefresh = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setIsLinkChanged(false);
    }, 1000);
  };

  const handlePeopleRefresh = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setIsPeopleChanged(false);
    }, 1000);
  };

  const handleFaqRefresh = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setIsFaqChanged(false);
    }, 1000);
  };

  const handleNodeSearch = (searchTerm) => {
    const filteredNodes = searchNodesByKeyword(nodes, searchTerm);

    setFilteredNodes(filteredNodes);
    setSearchTerm(searchTerm);

    // setState({
    //   ...state,
    //   filteredNodes: filteredNodes,
    //   searchTerm: searchTerm,
    // });
  };

  return (
    <div className={classes.root}>
      <TabPanel value={activeTab} index={0}>
        <NodesDatatableComponent
          // triggerUpdate={triggerUpdate}
          data={formatNodeData(nodes)}
          people={formatPeopleData(people)}
          allNodes={allNodes}
          isLoading={isLoading}
          nodeTypes={nodeTypes}
          handleNodesChange={handleNodesChange}
          handleNodeRefresh={handleNodeRefresh}
          handleRoleChange={handleRoleChange}
          handleTeamChangeForNodes={handleTeamChange}
          handleNodeSearch={handleNodeSearch}
          handleConnectionsChange={handleConnectionsChange}
        />
      </TabPanel>
      {/* <TabPanel value={activeTab} index={2}>
        <LinksDatatableComponent
          data={formatLinkData(links)}
          nodes={formatNodeData(nodes)}
          linksTypes={linksTypes}
          isLoading={isLoading}
          handleLinksChange={handleLinksChange}
          handleLinkRefresh={handleLinkRefresh}
        />
      </TabPanel> */}
      <TabPanel value={activeTab} index={1}>
        <PeopleDatatableComponent
          data={formatPeopleData(people)}
          nodes={formatNodeData(nodes)}
          isLoading={isLoading}
          handlePeopleChange={handlePeopleChange}
          handleRoleChange={handleRoleChange}
          handleTeamChange={handleTeamChange}
          handlePeopleRefresh={handlePeopleRefresh}
        />
      </TabPanel>
      {props.userRole === 1 && (
        <TabPanel value={activeTab} index={2}>
          <FaqDatatableComponent
            data={formatFaqData(faq)}
            isLoading={isLoading}
            handleFaqChange={handleFaqChange}
            handleFaqRefresh={handleFaqRefresh}
          />
        </TabPanel>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  activeTab: state.navigations.activeSubItem || 0,
  userRole: state.user.role,
});

export default withRouter(connect(mapStateToProps)(TableContainer));
