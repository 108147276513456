import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {D3ChartComponent} from "../views/charts/d3/index";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export default function Homepage(props) {
  const {activeItem} = props;
  const classes = useStyles();

  return (
    <main className={classes.content} id="homepage">
      <Container className={classes.container}>
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={8} lg={12}>
            <D3ChartComponent activeItem={activeItem} />
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}
