import {navigations} from "../../navigations";
import {
  SET_USER_NAVIGATION,
  SET_ACTIVE_NAVIGATION_ITEM,
  SET_ACTIVE_NAVIGATION_SUB_ITEM,
} from "../actions/NavigationAction";

const initialState = {
  navigationItems: [...navigations],
  activeItem: "/homepage",
  activeSubItem: 0,
};

const NavigationReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_NAVIGATION: {
      return {
        activeItem: state.activeItem,
        activeSubtItem: state.activeSubtItem,
        navigationItems: [...action.payload],
      };
    }
    case SET_ACTIVE_NAVIGATION_ITEM: {
      return {
        navigationItems: state.navigationItems,
        activeItem: action.payload,
        activeSubtItem: state.activeSubtItem,
      };
    }
    case SET_ACTIVE_NAVIGATION_SUB_ITEM: {
      return {
        navigationItems: state.navigationItems,
        activeItem: state.activeItem,
        activeSubItem: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default NavigationReducer;
