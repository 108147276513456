import axios from "./AxiosInterceptors";
import storageService from "./StorageService";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const LS_KEY_ACCESS_TOKEN = "access_token";

export class TeamsService {

    formatTeam (node) {
        return ({
            ...node,
            teams: node.children
        })
    }

    create(node) {
        const url = `${API_BASE_URL}/api/people-team`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }
        

        const teamToSend = this.formatTeam(node)

        return new Promise((resolve, reject) => {
            axios
                .post(url, teamToSend, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response.data.error);
                    }
                });
        });
    };

    update(id, team) {
        const url = `${API_BASE_URL}/api/people-team/${id}`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        team.id = team.nodeId

        return new Promise((resolve, reject) => {
            axios
                .put(url, team, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    updateAll(team) {
        const url = `${API_BASE_URL}/api/people-team`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }
        
        return new Promise((resolve, reject) => {
            axios
                .put(url, team, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };

    delete(id) {
        const url = `${API_BASE_URL}/api/people-team/${id}`;
        const currentToken = storageService.get(LS_KEY_ACCESS_TOKEN);
        
        const headers = {
          'Authorization': `jwt ${currentToken}`
        }

        return new Promise((resolve, reject) => {
            axios
                .delete(url, {
                    headers: headers
                })
                .then((res) => {
                    if (200 === res.status) {
                        resolve(res.data);
                    } else {
                        throw new Error()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    }
                });
        });
    };
};

const teamsService = new TeamsService();
export default teamsService;